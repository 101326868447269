import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { NavigateFunction, useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import React, { Component } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import SummarizeIcon from "@mui/icons-material/Summarize";
import PermissionsGate from "../../util/perminssion-gate";
import { SCOPES } from "../../util/permission-map";
import { getAllEmployees, getAllGuardians, getAllowanceTypes, getDeductionTypes, getServiceProviders } from "../../util/api-calls";
import { Page, Document, Image, StyleSheet, PDFViewer, PDFDownloadLink, View, Text } from "@react-pdf/renderer";
import { Employee } from "../contactlist/newcontactlist";
import { Allowance } from "../../util/data-models";
import { CSVLink, CSVDownload } from "react-csv";
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import logo from "../../assets/img/images/ebenezerlogo.png"
import DownloadIcon from "@mui/icons-material/Download";

import tri8logo from "../../assets/img/images/tri8logo.png";

function Payroll(username: string, employeesList, school, serviceProviders, deductionTypes, allowanceTypes) {
  const [openPayroll, setOpenPayrollModal] = useState(false);
  const [openExtract, setOpenExtractModal] = useState(false);
  const [openExtractnapsa, setOpenExtractModalnapsa] = useState(false);
  const [openExtractnhima, setOpenExtractModalnhima] = useState(false);
  const [openExtractpaye, setOpenExtractModalpaye] = useState(false);
  const [openMockExtract, setOpenMockExtractModal] = useState(false);
  const [openMockPayroll, setOpenMockPayrollModal] = useState(false);
  const [employees, setemployees] = useState([] as Employee[]);
  const navigate = useNavigate();
  const now = new Date();
  const [selectedMonth, setSelectedMonth] = useState(
    `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, "0")}`
  );
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [updatedEmployees, setUpdatedEmployees] = useState(0);
  const [currentEmployeeName, setCurrentEmployeeName] = useState("");
  const [defaultMonth, setDefaultMonth] = useState("Select Month Period");

  const [openLoadDialog, setOpenLoadDialog] = React.useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = React.useState(false);
  const closeLoadDialog = () => {
    setOpenLoadDialog(false);
  };
  const handleOpenLoadDialog = () => {
    setOpenLoadDialog(true);
  };
  const [isSelectingMonth, setIsSelectingMonth] = React.useState(true);
  const [selectedPayrollMonth, setSelectedPayrollMonth] = useState("");
  const [payrollMonths, setPayrollMonths] = useState([]);


  const [isSelectingMonthExtract, setIsSelectingMonthExtract] = React.useState(true);
  const [isSelectingMonthExtractnapsa, setIsSelectingMonthExtractnapsa] = React.useState(true);
  const [isSelectingMonthExtractnhima, setIsSelectingMonthExtractnhima] = React.useState(true);
  const [isSelectingMonthExtractpaye, setIsSelectingMonthExtractpaye] = React.useState(true);
  const [selectedPayrollMonthExtract, setSelectedPayrollMonthExtract] = useState("");
  const [selectedPayrollMonthExtractnapsa, setSelectedPayrollMonthExtractnapsa] = useState("");
  const [selectedPayrollMonthExtractnhima, setSelectedPayrollMonthExtractnhima] = useState("");
  const [selectedPayrollMonthExtractpaye, setSelectedPayrollMonthExtractpaye] = useState("");



  useEffect(() => {
    const months = monthsSinceJanuary2024();
    if (months?.length > 0) {
      const defaultMonth = months[0]; // or some other logic to determine the default month
      setSelectedMonth(
        `${defaultMonth.year}-${defaultMonth.month.toString().padStart(2, "0")}`
      );
    }
  }, [employeesList]);

  useEffect(() => {
    if (employeesList && employeesList.length > 0) {
      const extractPayrollMonths = () => {
        const uniqueMonths = new Set(); // Use a Set to ensure uniqueness

        employeesList.forEach(employee => {
          employee.payslips.forEach(payslip => {
            if (payslip.status === "2") {
              uniqueMonths.add(payslip.payslipperiod); // Add to Set if status is "2"
            }
          });
        });

        return Array.from(uniqueMonths); // Convert Set back to Array
      };

      const payrollMonths = extractPayrollMonths();
      setPayrollMonths(payrollMonths); // Update state
    }
  }, [employeesList]); // Rerun effect if employeesList changes

  useEffect(() => {
    handleOpenLoadDialog();
    // getAllEmployees().then((employees) => {
    if (employeesList.length > 0) {

      setemployees(employeesList);
      closeLoadDialog();
    }
    // });
  }, [employeesList]);

  const handlePayrollMonthChange = (event) => {
    setSelectedPayrollMonth(event.target.value);
    setIsSelectingMonth(false); // Hide month selection and show the PDF
  };

  const handlePayrollMonthChangeExtract = (event) => {
    setSelectedPayrollMonthExtract(event.target.value);
    setIsSelectingMonthExtract(false); // Hide month selection and show the PDF
  };

  const handlePayrollMonthChangeExtractnapsa = (event) => {
    setSelectedPayrollMonthExtractnapsa(event.target.value);
    setIsSelectingMonthExtractnapsa(false); // Hide month selection and show the PDF
  };

  const handlePayrollMonthChangeExtractnhima = (event) => {
    setSelectedPayrollMonthExtractnhima(event.target.value);
    setIsSelectingMonthExtractnhima(false); // Hide month selection and show the PDF
  };

  const handlePayrollMonthChangeExtractpaye = (event) => {
    setSelectedPayrollMonthExtractpaye(event.target.value);
    setIsSelectingMonthExtractpaye(false); // Hide month selection and show the PDF
  };

  function GenerateMockPayrollExtractButton() {
    return (
      <>
        {/* <CSVLink
          data={mockCsvData()}
          filename={"SMSPayrollExtract-" + getTimeStamp()}
        > */}
        <Button
          style={{ width: 300, marginLeft: 20 }}
          variant="contained"
          size="large"
          onClick={() => runPayroll(0)}
          startIcon={<SummarizeIcon />}
        >
          Run Mock Payroll
        </Button>
        {/* </CSVLink> */}
      </>
    );
  }

  function setopenPayroll() {
    if (openPayroll === true) {
      setOpenLoadDialog(true)
      setOpenPayrollModal(false);
      setOpenLoadDialog(false)
    } else {
      setIsSelectingMonth(true)
      setOpenPayrollModal(true);
      setSelectedPayrollMonth("");
    }
  }

  function setopenExtract() {
    if (openExtract === true) {
      setOpenLoadDialog(true)
      setOpenExtractModal(false);
      setOpenLoadDialog(false)
    } else {
      setIsSelectingMonthExtract(true)
      setOpenExtractModal(true);
      setSelectedPayrollMonthExtract("");
    }
  }

  function setopenExtractnapsa() {
    if (openExtract === true) {
      setOpenLoadDialog(true)
      setOpenExtractModalnapsa(false);
      setOpenLoadDialog(false)
    } else {
      setIsSelectingMonthExtractnapsa(true)
      setOpenExtractModalnapsa(true);
      setSelectedPayrollMonthExtractnapsa("");
    }
  }

  function setopenExtractnhima() {
    if (openExtract === true) {
      setOpenLoadDialog(true)
      setOpenExtractModalnhima(false);
      setOpenLoadDialog(false)
    } else {
      setIsSelectingMonthExtractnhima(true)
      setOpenExtractModalnhima(true);
      setSelectedPayrollMonthExtractnhima("");
    }
  }

  function setopenExtractpaye() {
    if (openExtract === true) {
      setOpenLoadDialog(true)
      setOpenExtractModalpaye(false);
      setOpenLoadDialog(false)
    } else {
      setIsSelectingMonthExtractpaye(true)
      setOpenExtractModalpaye(true);
      setSelectedPayrollMonthExtractpaye("");
    }
  }


  function today() {
    let date = new Date();
    return (
      date.getFullYear() +
      "-" +
      ("0" + (new Date().getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + (new Date().getDate() + 1)).slice(-2)
    ).toString();
  }

  function calculateGratuity(employee: Employee) {
    let gratuity = parseInt(employee.gratuity) / 100;
    let salary = parseInt(employee.salary) * 12;
    let agAmount = gratuity * salary;
    let mgAmount = agAmount / 12;

    let today = new Date();
    let hireDate = new Date(employee.datehire);
    let months;
    months = (today.getFullYear() - hireDate.getFullYear()) * 12;
    months -= hireDate.getMonth();
    months += today.getMonth();
    months = months <= 0 ? 0 : months;

    let AccuredGratuity = mgAmount * months;
    return AccuredGratuity;
  }
  const handleGenerateMockData = async () => {
    await runPayroll(); // Prepares the data
  };

  const handleGenerateLivekData = async () => {
    await runPayroll(2); // Prepares the data
  };
  function monthsSinceJanuary2024() {
    if (employeesList && employeesList.length > 0) {
      const monthCountsMap = new Map();
      const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];
      const startDate = new Date(2024, 0, 1);
      const today = new Date();
      let months = [];

      // Initialize counts for each month based on employees eligible before that month
      for (
        let year = startDate.getFullYear();
        year <= today.getFullYear();
        year++
      ) {
        for (let month = (year === startDate.getFullYear() ? startDate.getMonth() : 0); month <= (year === today.getFullYear() ? today.getMonth() : 11); month++) {
          const monthStr = `${year}-${(month + 1).toString().padStart(2, "0")}`;
          monthCountsMap.set(monthStr, {
            approvedCount: 0,
            mockCount: 0,
            liveCount: 0,
            noPayslipCount: 0
          });

          // Calculate noPayslipCount based on status and status date
          employeesList.forEach((employee) => {
            const recentStatusInfo = getMostRecentStatus(employee?.status);
            if (recentStatusInfo &&
              (recentStatusInfo.status === "Active" || recentStatusInfo.status === "Probation")) {
              const statusDate = new Date(recentStatusInfo.statusdate);
              const monthDate = new Date(year, month, 1);
              if (statusDate <= monthDate) {
                const counts = monthCountsMap.get(monthStr);
                counts.noPayslipCount++;
              }
            }
          });
        }
      }

      // Process each employee and update the corresponding month
      employeesList.forEach((employee) => {
        employee.payslips.forEach((payslip) => {
          const monthStr = payslip.payslipperiod;
          if (monthCountsMap.has(monthStr)) {
            const counts = monthCountsMap.get(monthStr);
            // Update only if the employee was eligible before the payslip month
            const recentStatusInfo = getMostRecentStatus(employee?.status);
            if (recentStatusInfo) {
              const statusDate = new Date(recentStatusInfo.statusdate);
              const [payslipYear, payslipMonth] = monthStr.split('-');
              const payslipDate = new Date(payslipYear, parseInt(payslipMonth, 10) - 1, 1);
              // console.log(payslip.status === "0")
              // console.log(payslip.status)
              if (statusDate <= payslipDate) {
                if (payslip.status === "1") {
                  counts.approvedCount++;
                  counts.noPayslipCount--;
                } else if (payslip.status === "0") {
                  counts.mockCount++;
                  counts.noPayslipCount--;
                } else if (payslip.status === "2") {
                  counts.liveCount++;
                  counts.noPayslipCount--;
                }
              }
            }
          }
        });
      });

      // Build the months array
      monthCountsMap.forEach((counts, monthStr) => {
        const [year, month] = monthStr.split('-');
        if (counts.noPayslipCount > 0 || counts.approvedCount > 0 || counts.mockCount > 0) {
          months.push({
            year: parseInt(year, 10),
            month: parseInt(month, 10),
            monthName: monthNames[parseInt(month, 10) - 1],
            approvedCount: counts.approvedCount,
            mockCount: counts.mockCount,
            liveCount: counts.liveCount,
            noPayslipCount: counts.noPayslipCount,
          });
        }
      });

      return months;
    }
    return [];
  }







  const getMostRecentStatus = (statuses) => {
    return statuses?.sort((a, b) => new Date(b.statusdate).getTime() - new Date(a.statusdate).getTime())[0];
  }

  async function runPayroll(status = 1) {
    try {
      setOpenUpdateDialog(true);
      const allEmployees = await getAllEmployees(); // Fetch all employee data
      let updatedCount = 0; // Local variable to track updated employees count

      const selectedMonthDate = new Date(selectedMonth + "-01"); // Assuming selectedMonth is in 'YYYY-MM' format

      const eligibleEmployees = allEmployees.filter(employee => {
        const recentStatusInfo = getMostRecentStatus(employee?.status);
        const recentStatus = recentStatusInfo?.status;
        const statusDate = new Date(recentStatusInfo?.statusdate); // Use status date
        const isEligibleStatus = recentStatus === "Active" || recentStatus === "Probation";
        return isEligibleStatus && statusDate <= selectedMonthDate;
      });

      setTotalEmployees(eligibleEmployees.length); // Set total employees for progress tracking
      setUpdatedEmployees(0); // Reset updated employees count for new run

      const BATCH_SIZE = 1; // Processing employees in batches for better performance

      for (let i = 0; i < eligibleEmployees.length; i += BATCH_SIZE) {
        const batch = eligibleEmployees.slice(i, i + BATCH_SIZE);

        await Promise.all(
          batch.map(async (employee) => {
            setCurrentEmployeeName(`${employee.firstname} ${employee.lastname}`);

            const existingPayslipIndex = employee.payslips.findIndex(
              payslip => payslip.payslipperiod === selectedMonth
            );

            if (existingPayslipIndex !== -1) {
              const payslipToUpdate = employee.payslips[existingPayslipIndex];
              if ((status === 1 || status === 2) && parseInt(payslipToUpdate.status) === status) {
                updatedCount++;
                setUpdatedEmployees(updatedCount);
                return;
              }

              if (status === 0) {
                const newPayslip = createEmployeePayslip(employee, selectedMonth, status);
                employee.payslips[existingPayslipIndex] = newPayslip;
              } else {
                payslipToUpdate.status = status.toString();
                employee.payslips[existingPayslipIndex] = payslipToUpdate;
              }
            } else if (status === 0) {
              const newPayslip = createEmployeePayslip(employee, selectedMonth, status);
              employee.payslips.push(newPayslip);
            }

            const updatedEmployee = {
              ...employee,
              payslips: employee.payslips,
              updatedAt: new Date().toISOString(),
            };

            const requestOptions = {
              method: "PATCH",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(updatedEmployee),
            };

            await fetch(`https://school.kozosms.com/api/api/employees/${employee.id}`, requestOptions);
            updatedCount++;
            setUpdatedEmployees(updatedCount); // Update state to reflect progress
          })
        );
      }

      setOpenUpdateDialog(false);
      window.location.reload(); // Optionally, replace this with a state update for re-rendering
    } catch (error) {
      console.error("Error running payroll:", error);
      setOpenUpdateDialog(false);
    }
  }



  function calculateAllowance(employee: Employee) {
    let monthAllowance = 0;
    if (employee.allowances.length > 0) {
      let allowanceSum = 0;
      for (let j = 0; j < employee.allowances.length; j++) {
        allowanceSum += parseInt(employee.allowances[j].allowanceamount);
      }
      return allowanceSum.toString();
    } else {
      return "";
    }
    return "";
  }

  function calculateMonthlyGross(employee: Employee) {
    let monthAllowance = 0;
    if (employee.allowances.length > 0) {
      let allowanceSum = 0;
      for (let j = 0; j < employee.allowances.length; j++) {
        allowanceSum += parseInt(employee.allowances[j].allowanceamount);
      }
      monthAllowance = allowanceSum;
    } else {
    }

    let monthGrossPay = monthAllowance + parseInt(employee.salary);
    return monthGrossPay;
  }

  function calculatePaye(employee: Employee, monthGrossPay) {
    const taxBands = [
      { upperBound: 5100, rate: 0 },
      { upperBound: 7100, rate: 0.2 },
      { upperBound: 9200, rate: 0.3 },
      { upperBound: Infinity, rate: 0.37 },
    ];

    let remainingSalary = monthGrossPay;
    let taxDue = 0;
    let previousUpperBound = 0;

    for (const band of taxBands) {
      if (remainingSalary <= band.upperBound) {
        taxDue += (remainingSalary - previousUpperBound) * band.rate;
        break; // Exit loop since we've reached the relevant band
      } else {
        taxDue += (band.upperBound - previousUpperBound) * band.rate;
      }
      previousUpperBound = band.upperBound;
    }

    return taxDue.toString();
  }

  function calculateNapsa(monthGrossPay: Number) {
    const employeeRate = 0.05; // 5%
    const employeeContribution = monthGrossPay * employeeRate;
    const totalContribution = employeeContribution;
    if (totalContribution > 1490.80) {
      return 1490.80
    }
    return totalContribution;
  }
  function calculateContractEndDate(dateHire, contractLengthMonths) {
    let hireDate = new Date(dateHire);
    hireDate.setMonth(hireDate.getMonth() + parseInt(contractLengthMonths));
    return hireDate;
  }

  function calculateProjectPayout(employee, startPeriod, endPeriod) {
    // Assuming monthly gross pay includes salary and allowances but excludes deductions.
    let monthlyGrossPay = parseInt(employee.salary) + employee.allowances.reduce((acc, allowance) => acc + parseInt(allowance.allowanceamount), 0);
    // Calculate total months
    let startDate = new Date(startPeriod);
    let endDate = new Date(endPeriod);
    let totalMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12 + endDate.getMonth() - startDate.getMonth();

    return monthlyGrossPay * totalMonths;
  }

  function createEmployeePayslip(
    employee: Employee,
    selectedMonth: string,
    status: any
  ) {

    let monthGrossPay = parseInt(employee.salary);

    let monthNhima = monthGrossPay * 0.01;

    let totalMonthlyAllowances = 0;

    function monthDiff(start: Date, end: Date): number {
      let months;
      months = (end.getFullYear() - start.getFullYear()) * 12;
      months -= start.getMonth();
      months += end.getMonth();
      return months <= 0 ? 0 : months;
    }

    const payslipmonthcustomallowance = employee.allowances
      .filter(allowance => {
        const isOneTimeAllowance = allowance.allowancestartmonth === allowance.allowanceendmonth && allowance.allowancestartmonth === selectedMonth;
        const isRecurringAllowance = allowance.allowancestartmonth <= selectedMonth && allowance.allowanceendmonth >= selectedMonth;
        return isOneTimeAllowance || isRecurringAllowance;
      })
      .map(allowance => {
        let monthlyAllowanceAmount = parseFloat(allowance.allowanceamount);
        if (allowance.allowancestartmonth !== allowance.allowanceendmonth) { // If it's a recurring allowance
          const start = new Date(allowance.allowancestartmonth + '-01');
          const end = new Date(allowance.allowanceendmonth + '-01');
          const months = monthDiff(start, end) + 1; // Calculate total months of the allowance period
          monthlyAllowanceAmount /= months; // Divide total amount by months to get monthly amount
        }

        // Accumulate the monthly allowance amounts
        totalMonthlyAllowances += monthlyAllowanceAmount;

        return {
          id: allowance.id,
          allowanceType: allowance.allowancetype,
          allowanceServiceProvider: allowance.allowanceserviceprovider,
          allowanceAmount: monthlyAllowanceAmount.toFixed(2),
          allowanceStartMonth: allowance.allowancestartmonth,
          allowanceEndMonth: allowance.allowanceendmonth,
        };
      });

    totalMonthlyAllowances = parseFloat((totalMonthlyAllowances).toFixed(2));

    monthGrossPay = (monthGrossPay + totalMonthlyAllowances);
    let monthPaye = parseFloat(calculatePaye(employee, monthGrossPay)).toFixed(2);
    let monthNapsa = parseFloat(calculateNapsa(monthGrossPay).toFixed(2));

    let totalMonthDeductions = parseFloat(monthPaye) + monthNapsa + monthNhima;
    const payslipmonthcustomdeduction = employee.deduction
      .filter(deduction => {
        const isOneTimeDeduction = deduction.deductionstartmonth === deduction.deductionendmonth && deduction.deductionstartmonth === selectedMonth;
        const isRecurringDeduction = deduction.deductionstartmonth <= selectedMonth && deduction.deductionendmonth >= selectedMonth;
        return isOneTimeDeduction || isRecurringDeduction;
      })
      .map(deduction => {
        let monthlyDeductionAmount = parseFloat(deduction.deductionamount);
        if (deduction.deductionstartmonth !== deduction.deductionendmonth) { // Check for recurring deduction
          const start = new Date(deduction.deductionstartmonth + '-01');
          const end = new Date(deduction.deductionendmonth + '-01');
          const months = monthDiff(start, end) + 1; // Calculate total months of the deduction period
          monthlyDeductionAmount = monthlyDeductionAmount / months; // Divide total amount by months to get monthly amount
        }
        totalMonthDeductions += monthlyDeductionAmount;

        return {
          id: deduction.id,
          deductionType: deduction.deductiontype,
          deductionServiceProvider: deduction.deductionserviceprovider,
          deductionAmount: monthlyDeductionAmount.toFixed(2), // Use monthly deduction amount
          deductionStartMonth: deduction.deductionstartmonth,
          deductionEndMonth: deduction.deductionendmonth,
        };
      });
    let netMonthPay = (monthGrossPay - totalMonthDeductions).toFixed(2);


    // Function to retrieve employee's payslips for the current year up to selected month
    let ytdPayslips = getEmployeePayslipsForCurrentYear(
      employee,
      selectedMonth
    );

    let ytdValues = ytdPayslips.reduce(
      (acc, payslip) => {
        acc.ytdSalary += Number(payslip.payslipmonthsalary || 0);
        acc.ytdAllowances += Number(payslip.payslipmonthallowances || 0);
        acc.ytdOther += Number(payslip.payslipmonthother || 0);
        acc.ytdGrossPay += Number(payslip.payslipmonthgrosspay || 0);
        acc.ytdPaye += Number(payslip.payslipmonthpaye || 0);
        acc.ytdNapsa += Number(payslip.payslipmonthnapsa || 0);
        acc.ytdNhima += Number(payslip.payslipmonthnhima || 0);
        acc.ytdTotalDeductions += Number(
          payslip.payslipmonthtotaldeductions || 0
        );
        acc.ytdNetPay += Number(payslip.payslipmonthnetpay || 0);
        acc.ytdaccruedgratuity += Number(payslip.payslipgratuity || 0);
        return acc;
      },
      {
        ytdSalary: 0,
        ytdAllowances: 0,
        ytdOther: 0,
        ytdGrossPay: 0,
        ytdPaye: 0,
        ytdNapsa: 0,
        ytdNhima: 0,
        ytdTotalDeductions: 0,
        ytdNetPay: 0,
        ytdaccruedgratuity: 0,
      }
    );

    ytdValues.ytdSalary += Number(employee.salary) || 0;
    ytdValues.ytdAllowances += totalMonthlyAllowances || 0;
    ytdValues.ytdGrossPay += monthGrossPay || 0;
    ytdValues.ytdPaye += Number(monthPaye) || 0;
    ytdValues.ytdNapsa += monthNapsa || 0;
    ytdValues.ytdNhima += monthNhima || 0;
    ytdValues.ytdTotalDeductions += totalMonthDeductions || 0;
    ytdValues.ytdNetPay = (parseFloat(ytdValues.ytdNetPay) + parseFloat(netMonthPay)).toFixed(2) || 0;
    ytdValues.ytdAccruedGratuity += Number(employee.gratuity) || 0;

    let contractEndDate = calculateContractEndDate(employee.datehire, employee.contractlength);
    let contractEndDateString = "N/A"; // Default value
    if (contractEndDate instanceof Date && !isNaN(contractEndDate.getTime())) {
      contractEndDateString = contractEndDate.toISOString().split("T")[0]; // Format YYYY-MM-DD
    }

    let projectedPayout = "0"; // Default to "0"
    if (contractEndDate instanceof Date && !isNaN(contractEndDate.getTime())) {
      let endPeriod = contractEndDate.toISOString().split('T')[0]; // YYYY-MM-DD format
      let payout = calculateProjectPayout(employee, employee.datehire, endPeriod);
      projectedPayout = isNaN(payout) ? "0" : payout.toString(); // Ensure NaN values are replaced with "0"
    }

    return {
      payslipperiod: selectedMonth.toString(),
      payslipfirstname: employee.firstname.toString(),
      paysliplastname: employee.lastname.toString(),
      payslipid: employee.employeeid.toString(),
      paysliptitle: employee.title.toString(),
      payslipnrc: employee.nrc.toString(),
      paysliptpin: employee.zratpin.toString(),
      payslipssn: employee.pensionno.toString(),
      payslipmonthsalary: employee.salary.toString(),

      payslipmonthcustomallowances: payslipmonthcustomallowance,
      payslipmonthallowances: totalMonthlyAllowances.toString(),
      payslipmonthother: "0",
      payslipmonthgrosspay: monthGrossPay.toFixed(2).toString(),
      payslipmonthpaye: monthPaye.toString(),
      payslipmonthnapsa: monthNapsa.toString(),
      payslipmonthnhima: monthNhima.toString(),
      payslipmonthcustomdeductions: payslipmonthcustomdeduction,
      payslipmonthtotaldeductions: totalMonthDeductions.toFixed(2).toString(),
      payslipmonthnetpay: netMonthPay.toString(),

      // YTD values from accumulated totals
      payslipytdsalary: ytdValues.ytdSalary.toFixed(2).toString(),
      payslipytdallowances: ytdValues.ytdAllowances.toFixed(2).toString(),
      payslipytdother: ytdValues.ytdOther.toFixed(2).toString(),
      payslipytdgrosspay: ytdValues.ytdGrossPay.toFixed(2).toString(),
      payslipytdpaye: ytdValues.ytdPaye.toFixed(2).toString(),
      payslipytdnapsa: ytdValues.ytdNapsa.toFixed(2).toString(),
      payslipytdnhima: ytdValues.ytdNhima.toFixed(2).toString(),
      payslipytdtotaldeductions: ytdValues.ytdTotalDeductions.toFixed(2).toString(),
      payslipytdnetpay: ytdValues.ytdNetPay.toString(),

      payslipgratuity: employee.gratuity.toString(),
      payslipytdaccruedgratuity: ytdValues.ytdaccruedgratuity.toString(),
      payslipcontractend: contractEndDateString.toString(),
      payslipprojectedpayout: projectedPayout.toString(),

      payslipbank: employee.bankname.toString(),
      payslipaccount: employee.bankaccount.toString(),
      paysliplocation: employee.bankbranch.toString(),
      status: status.toString(),
      updatedBy: "system",
    };

  }

  const findAllowanceTypeNameById = (id) => {
    const type = allowanceTypes?.find(type => type.id === id);
    return type ? type.allowancetype : 'Unknown Type';
  };

  const findServiceProviderNameById = (id) => {
    const provider = serviceProviders?.find(provider => provider.id === id);
    return provider ? provider.serviceprovider : 'Unknown Provider';
  };

  // Example function to find deduction type name by ID
  const findDeductionTypeNameById = (id) => {
    const type = deductionTypes?.find(type => type.id === id);
    return type ? type.deductiontype : 'Unknown Type';
  };

  function getEmployeePayslipsForCurrentYear(employee, selectedMonth) {
    const selectedYear = selectedMonth.split("-")[0];

    const filteredPayslips = employee.payslips.filter((payslip) => {
      const [payslipYear, payslipMonth] = payslip.payslipperiod.split("-");
      const condition = payslipYear === selectedYear &&
        parseInt(payslipMonth) <= parseInt(selectedMonth.split("-")[1]) &&
        payslip.status === "2";

      return condition;
    });
    return filteredPayslips;
  }

  const borderColor = "#000000";

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: '#f9f9f9',
      padding: 5,
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    title: {
      fontSize: 24,
      textAlign: 'center',
      marginBottom: 20,
    },
    headerContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 10,
    },
    header: {
      fontSize: 18,
      marginBottom: 5,
    },
    subtitle: {
      fontSize: 15,
      marginBottom: 3,
    },
    contentContainer: {
      flexDirection: 'row',
    },
    leftColumn: {
      flexDirection: 'column',
      flexGrow: 9,
    },
    rightColumn: {
      flexDirection: 'column',
      flexGrow: 5,
      marginLeft: 15,
    },
    sectionHeader: {
      fontSize: 13,
      fontWeight: 'bold',
      marginBottom: 3,
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    box: {
      marginBottom: 5,
    },
    footer: {
      textAlign: 'center',
      marginTop: 25,
      fontSize: 12,
    },
    payslipHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 10, // Reduced margin
    },
    payslipColumn: {
      flexDirection: 'column',
    },
    subTitle: {
      fontSize: 12,
      marginBottom: 3, // Reduced margin
    },
    bodyText: {
      fontSize: 10,
      margin: 2, // Margin kept small
    },
    salarySection: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: 2, // Added to reduce spacing
    },
    totalSection: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderTop: 1,
      paddingTop: 5,
      marginTop: 5,
    },
    boldText: {
      fontWeight: 'bold',
    },
    payslipHeaderColumn: {
      flexDirection: 'column',
    },
    payslipBody: {
      flexDirection: 'row',
      borderTop: 1,
      borderColor: '#000',
      paddingTop: 5,
    },
    earningsSection: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginVertical: 2,
    },
    sum: {
      fontWeight: 'bold',
    },
    row2: {
      flexDirection: "row",
      borderBottomColor: "#000000",
      borderBottomWidth: 1,
      borderTopColor: "#000000",
      borderTopWidth: 1,
      alignItems: "center",
      height: 50,
      fontStyle: "bold",
    },
    row3: {
      flexDirection: "row",
      borderBottomColor: "#000000",
      borderBottomWidth: 1,
      borderTopColor: "#000000",
      borderTopWidth: 1,
      alignItems: "center",
      height: 60,
      fontStyle: "bold",
    },
    row4: {
      flexDirection: "row",
      borderBottomColor: "#000000",
      borderBottomWidth: 1,
      borderTopColor: "#000000",
      borderTopWidth: 1,
      alignItems: "center",
      height: 75,
      fontStyle: "bold",
    },
    container: {
      flexDirection: "row",
      borderBottomColor: "#000000",
      borderBottomWidth: 1,
      alignItems: "center",
      height: 24,
      textAlign: "center",
      fontStyle: "bold",
      flexGrow: 1,
    },
    description: {
      width: "6.25%",
      borderRightColor: borderColor,
      borderLeftColor: borderColor,
      borderRightWidth: 1,
      borderLeftWidth: 1,
      height: 24,
      paddingLeft: 2,
      paddingTop: 5,
    },
    description2: {
      width: "6.25%",
      borderRightColor: borderColor,
      borderLeftColor: borderColor,
      borderRightWidth: 1,
      borderLeftWidth: 1,
      height: 50,
      paddingLeft: 2,
      paddingTop: 5,
    },
    description3: {
      width: "6.25%",
      borderRightColor: borderColor,
      borderLeftColor: borderColor,
      borderRightWidth: 1,
      borderLeftWidth: 1,
      height: 60,
      paddingLeft: 2,
      paddingTop: 5,
    },
    description4: {
      width: "6.25%",
      borderRightColor: borderColor,
      borderLeftColor: borderColor,
      borderRightWidth: 1,
      borderLeftWidth: 1,
      height: 75,
      paddingLeft: 2,
      paddingTop: 5,
    },
    allowance: {
      width: "6.25%",
      borderRightColor: borderColor,
      borderLeftColor: borderColor,
      borderRightWidth: 1,
      borderLeftWidth: 1,
      height: 24,
      paddingLeft: 2,
      paddingTop: 5,
    },
    billTo: {
      paddingBottom: 5,
      fontFamily: "Helvetica-Oblique",
    },
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getNextMonthPayroll(months) {
    if (months?.length > 0) {
      return (
        <>
          <Typography variant="h4">{`${months[0].year}-${months[0].month
            .toString()
            .padStart(2, "0")}`}</Typography>
          <Typography variant="h6">{`Employees with no mock payslip: ${months[0].noPayslipCount}`}</Typography>
          <Typography variant="h6">{`Employees with mock payslip for this period: ${months[0].mockCount}`}</Typography>
          <Typography variant="h6">{`Employees with approved payslip for this period: ${months[0].approvedCount}`}</Typography>
          <Typography variant="h6">{`Employees with live payslip for this period: ${months[0].liveCount}`}</Typography>
        </>
      );
    } else {
      return "A new payroll period is not available yet";
    }
    return "A new payroll period is not available yet";
  }

  function formatDate(dateStr: string): string {
    const date = new Date(dateStr);
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    let formattedDate = date.toLocaleDateString('en-US', options);

    // Function to add ordinal suffix
    function getOrdinal(n: number): string {
      return n + (["st", "nd", "rd"][((n + 90) % 100 - 10) % 10 - 1] || "th");
    }

    // Replace the day number with its ordinal counterpart
    const day = date.getDate();
    formattedDate = formattedDate.replace(day.toString(), getOrdinal(day));

    return formattedDate;
  }

  const PayrollDocument = ({ employeesList, selectedMonth }) => (
    <Document>
      {employeesList.reduce((acc, employee, index, array) => {
        // Group payslips into pairs
        if (index % 2 === 0) {
          const pair = [employee];

          // Check if there's a next item and add it to the pair
          if (index + 1 < array.length) {
            pair.push(array[index + 1]);
          }

          acc.push(pair);
        }
        return acc;
      }, []).map((pair, pairIndex) => (
        <Page key={pairIndex} size="A4" style={styles.page}>
          {pair.map((employee, empIndex) => {
            const payslipForMonth = employee.payslips.find(payslip => payslip?.payslipperiod?.toString() === selectedMonth?.toString());

            if (!payslipForMonth) {
              return null; // Skip rendering if no payslip for the selected month
            }

            return (

              <View >
                {payslipForMonth.status === "0" && (
                  <Text style={{ color: 'red', fontSize: 12, textAlign: 'center', margin: 0 }}>
                    MOCK - VALIDATION PAYSLIP
                  </Text>
                )}
                <View style={{
                  // fontFamily: "'Arial', sans-serif",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  padding: "10px",
                  margin: "5px",
                  backgroundColor: "#fff"
                }}>
                  <View style={{
                    borderBottomWidth: 2,
                    borderColor: "#000",
                    paddingBottom: 5,
                    marginBottom: 10,
                  }}>
                    <View style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}>
                      {/* Left Column */}
                      <View style={{ flex: 1 }}>
                        <Text style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 4 }}>
                          {school?.clientname}
                        </Text>
                        <Text style={{ fontSize: 10 }}>
                          {`Employee Payslip - ${formatDate(payslipForMonth.createdAt)}`}
                        </Text>
                      </View>

                      {/* Right Column */}
                      <View style={{ flex: 1, alignItems: 'flex-end' }}>
                        <View style={{
                          flexDirection: 'row',
                          justifyContent: 'flex-end', alignItems: 'flex-end', paddingBottom: 4,
                        }}>
                          <Text style={{ fontWeight: 'bold', fontSize: 14, textAlign: 'right' }}>
                            {payslipForMonth?.payslipfirstname + " " + payslipForMonth?.paysliplastname}
                          </Text>
                          <Text style={{ fontWeight: 100, fontSize: 12, textAlign: 'right' }}>
                            {" - ID: " + payslipForMonth?.payslipid}
                          </Text>
                        </View>

                        <Text style={{ fontSize: 10, textAlign: 'right' }}>
                          {payslipForMonth?.paysliptitle}
                        </Text>
                        <Text style={{ fontSize: 10, textAlign: 'right' }}>
                          {`NRC # ${payslipForMonth?.payslipnrc}, TPIN # ${payslipForMonth?.paysliptpin}, SSN # ${payslipForMonth?.payslipssn}`}
                        </Text>
                      </View>
                    </View>
                  </View>

                  <View style={{
                    flexDirection: 'row',
                  }}>
                    <View style={{
                      width: '65%',
                    }}>
                      <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 0,
                      }}>
                        <View style={{
                          width: '55%', // Adjust the width as necessary
                          justifyContent: 'center',
                        }}>
                          <Text style={{
                            fontSize: 12, // Adjust font size as necessary
                            marginBottom: 5, // Adjust spacing as necessary
                            fontWeight: 'bold',
                          }}>
                            EARNINGS
                          </Text>
                        </View>
                        <View style={{
                          width: '30%', // Adjust the width as necessary
                          justifyContent: 'flex-end',
                        }}>
                          <Text style={{
                            fontSize: 12, // Adjust font size as necessary
                            marginBottom: 5, // Adjust spacing as necessary
                            fontWeight: 'bold',
                          }}>
                            THIS MONTH
                          </Text>
                        </View>
                        <View style={{
                          width: '20%', // Adjust the width as necessary
                          justifyContent: 'flex-end',
                        }}>
                          <Text style={{
                            fontSize: 12, // Adjust font size as necessary
                            marginBottom: 5, // Adjust spacing as necessary
                            fontWeight: 'bold',
                          }}>
                            YTD
                          </Text>
                        </View>
                      </View>
                      <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 5,
                      }}>
                        <Text style={{
                          width: '60%', // Adjust the width as necessary
                          justifyContent: 'center',
                          fontSize: 10,
                          paddingLeft: 10
                        }}>
                          Salary
                        </Text>
                        <Text style={{
                          width: '20%', // Adjust the width as necessary
                          justifyContent: 'flex-end',
                          fontSize: 10,
                        }}>
                          K{payslipForMonth?.payslipmonthsalary}
                        </Text>
                        <Text style={{
                          width: '20%', // Adjust the width as necessary
                          justifyContent: 'flex-end',
                          fontSize: 10,
                        }}>
                          K{payslipForMonth?.payslipytdsalary}
                        </Text>
                      </View>

                      <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 5,
                      }}>
                        <Text style={{
                          width: '60%', // Adjust the width as necessary
                          justifyContent: 'center',
                          fontSize: 10,
                          paddingLeft: 10
                        }}>
                          Other:
                        </Text>

                      </View>

                      {payslipForMonth.payslipmonthcustomallowances && payslipForMonth.payslipmonthcustomallowances.map((allowance, index) => (


                        <View style={{
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginBottom: 5,
                        }}>
                          <Text style={{
                            width: '60%', // Adjust the width as necessary
                            justifyContent: 'center',
                            fontSize: 10,
                            paddingLeft: 20
                          }}>
                            {"• "}{findAllowanceTypeNameById(allowance.allowanceType)}
                          </Text>
                          <Text style={{
                            width: '20%', // Adjust the width as necessary
                            justifyContent: 'center',
                            fontSize: 10,
                          }}>
                            K{allowance.allowanceAmount}
                          </Text>
                          <Text style={{
                            width: '20%', // Adjust the width as necessary
                            justifyContent: 'center',
                            fontSize: 10,
                          }}>

                          </Text>
                        </View>

                        // <View key={index} style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
                        //   <Text style={{ width: '66%', fontSize: 10, textAlign: 'left', paddingLeft: 20 }}>
                        //     •{findDeductionTypeNameById(deduction.deductionType)} - {findServiceProviderNameById(deduction.deductionServiceProvider)}: K{deduction.deductionAmount}
                        //   </Text>
                        // </View>
                      ))}



                      <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 5,
                      }}>
                        <Text style={{
                          width: '60%', // Adjust the width as necessary
                          justifyContent: 'center',
                          fontSize: 10,
                          paddingLeft: 10
                        }}>
                          Total Allowances:
                        </Text>
                        <Text style={{
                          width: '20%', // Adjust the width as necessary
                          justifyContent: 'flex-end',
                          fontSize: 10,
                        }}>
                          K{payslipForMonth?.payslipmonthallowances ? payslipForMonth?.payslipmonthallowances : "0"}
                        </Text>
                        <Text style={{
                          width: '20%', // Adjust the width as necessary
                          justifyContent: 'flex-end',
                          fontSize: 10,
                        }}>
                          K{payslipForMonth?.payslipytdallowances}
                        </Text>
                      </View>
                      <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 5,
                      }}>
                        <View style={{
                          width: '60%', // Adjust the width as necessary
                          justifyContent: 'center',
                          fontSize: 12
                        }}>
                          <Text
                            style={{
                              width: '100%',
                              backgroundColor: '#ccc',
                              fontSize: 12,
                              paddingLeft: 10
                            }}
                          >
                            GROSS PAY
                          </Text>
                        </View>
                        <View style={{
                          width: '20%', // Adjust the width as necessary
                          justifyContent: 'center',
                          fontSize: 12
                        }}>
                          <Text
                            style={{
                              width: '100%',
                              backgroundColor: '#ccc',
                            }}
                          >
                            K{payslipForMonth?.payslipmonthgrosspay}
                          </Text>
                        </View>
                        <View style={{
                          width: '20%', // Adjust the width as necessary
                          justifyContent: 'center',
                          fontSize: 12
                        }}>
                          <Text style={{
                            fontSize: 12,
                          }}>
                            K{payslipForMonth?.payslipytdgrosspay}
                          </Text>
                        </View>
                      </View>

                      <Text
                        style={{
                          fontSize: 12,
                        }}>
                        Deductions
                      </Text>
                      <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 5,
                      }}>
                        <View style={{
                          width: '60%', // Adjust the width as necessary
                          justifyContent: 'center',
                          fontSize: 10,
                          paddingLeft: 10
                        }}>
                          <Text style={{ fontSize: 10 }}>
                            Statutory
                          </Text>
                        </View>
                      </View>
                      <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 5,
                      }}>
                        <Text style={{
                          width: '60%', // Adjust the width as necessary
                          justifyContent: 'center',
                          fontSize: 10,
                          paddingLeft: 20
                        }}>PAYE</Text>
                        <Text style={{
                          width: '20%', // Adjust the width as necessary
                          justifyContent: 'center',
                          fontSize: 10,
                        }}>
                          K{payslipForMonth?.payslipmonthpaye}
                        </Text>
                        <Text style={{
                          width: '20%', // Adjust the width as necessary
                          justifyContent: 'center',
                          fontSize: 10,
                        }}>
                          K{payslipForMonth?.payslipytdpaye}
                        </Text>
                      </View>
                      <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 5,
                      }}>
                        <Text style={{
                          width: '60%', // Adjust the width as necessary
                          justifyContent: 'center',
                          fontSize: 10,
                          paddingLeft: 20
                        }}>NAPSA</Text>
                        <Text style={{
                          width: '20%', // Adjust the width as necessary
                          justifyContent: 'center',
                          fontSize: 10,
                        }}>
                          K{payslipForMonth?.payslipmonthnapsa}
                        </Text>
                        <Text style={{
                          width: '20%', // Adjust the width as necessary
                          justifyContent: 'center',
                          fontSize: 10,
                        }}>
                          K{payslipForMonth?.payslipytdnapsa}
                        </Text>
                      </View>
                      <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 5,
                      }}>
                        <Text style={{
                          width: '60%', // Adjust the width as necessary
                          justifyContent: 'center',
                          fontSize: 10,
                          paddingLeft: 20
                        }}>
                          NHIMA
                        </Text>
                        <Text style={{
                          width: '20%', // Adjust the width as necessary
                          justifyContent: 'center',
                          fontSize: 10,
                        }}>
                          K{payslipForMonth?.payslipmonthnhima}
                        </Text>
                        <Text style={{
                          width: '20%', // Adjust the width as necessary
                          justifyContent: 'center',
                          fontSize: 10,
                        }}>
                          K{payslipForMonth?.payslipytdnhima}
                        </Text>
                      </View>
                      <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 5,
                      }}>
                        <Text style={{
                          width: '33%', // Adjust the width as necessary
                          justifyContent: 'center',
                          fontSize: 10,
                          paddingLeft: 10
                        }}>
                          Other:
                        </Text>

                      </View>

                      {payslipForMonth.payslipmonthcustomdeductions && payslipForMonth.payslipmonthcustomdeductions.map((deduction, index) => (


                        <View style={{
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginBottom: 5,
                        }}>
                          <Text style={{
                            width: '60%', // Adjust the width as necessary
                            justifyContent: 'center',
                            fontSize: 10,
                            paddingLeft: 20
                          }}>
                            {"• "}{findDeductionTypeNameById(deduction.deductionType)} - {findServiceProviderNameById(deduction.deductionServiceProvider)}
                          </Text>
                          <Text style={{
                            width: '20%', // Adjust the width as necessary
                            justifyContent: 'center',
                            fontSize: 10,
                          }}>
                            K{deduction.deductionAmount}
                          </Text>
                          <Text style={{
                            width: '20%', // Adjust the width as necessary
                            justifyContent: 'center',
                            fontSize: 10,
                          }}>

                          </Text>
                        </View>

                        // <View key={index} style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
                        //   <Text style={{ width: '66%', fontSize: 10, textAlign: 'left', paddingLeft: 20 }}>
                        //     •{findDeductionTypeNameById(deduction.deductionType)} - {findServiceProviderNameById(deduction.deductionServiceProvider)}: K{deduction.deductionAmount}
                        //   </Text>
                        // </View>
                      ))}
                      <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 5,
                      }}>
                        <View style={{
                          width: '60%', // Adjust the width as necessary
                          justifyContent: 'center',
                          fontSize: 10,
                          paddingLeft: 10
                        }}>
                          <Text style={{ fontSize: 10 }}>
                            Total Deductions
                          </Text>
                        </View>
                        <View style={{
                          width: '20%', // Adjust the width as necessary
                          justifyContent: 'center',
                          fontSize: 10,
                        }}>
                          <Text style={{ fontSize: 12 }}>
                            K{payslipForMonth?.payslipmonthtotaldeductions}
                          </Text>
                        </View>
                        <View style={{
                          width: '20%', // Adjust the width as necessary
                          justifyContent: 'center',
                          fontSize: 10,
                        }}>
                          <Text style={{ fontSize: 12 }}>
                            K{payslipForMonth?.payslipytdtotaldeductions}
                          </Text>
                        </View>
                      </View>

                      <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 5,
                      }}>
                        <View style={{
                          width: '60%', // Adjust the width as necessary
                          justifyContent: 'center',
                          fontSize: 12
                        }}>
                          <Text
                            style={{
                              width: '100%',
                              backgroundColor: '#ccc',
                              paddingLeft: 10
                            }}
                          >
                            NET PAY
                          </Text>
                        </View>
                        <View style={{
                          width: '20%', // Adjust the width as necessary
                          justifyContent: 'center',
                          fontSize: 12
                        }}>
                          <Text
                            style={{
                              width: '100%',
                              backgroundColor: '#ccc',
                            }}
                          >
                            K{payslipForMonth?.payslipmonthnetpay}
                          </Text>
                        </View>
                        <View style={{
                          width: '20%', // Adjust the width as necessary
                          justifyContent: 'center',
                          fontSize: 12
                        }}>
                          <Text style={{
                            fontSize: 12,
                          }}>
                            K{payslipForMonth?.payslipytdnetpay}
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View style={{
                      width: '35%',
                    }}>
                      <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: 0,
                      }}>
                        <Text style={{
                          fontSize: 12, // This represents 'subtitle1'. Adjust the size as needed.
                          marginBottom: 4, // For spacing
                        }}>
                          OTHER INFORMATION
                        </Text>
                      </View>

                      {/* Repeated structure for each item, with inline styles */}
                      <View style={{ alignSelf: 'stretch', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                        <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                          Gratuity % Rate
                        </Text>
                        <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                          {`${payslipForMonth.payslipgratuity}%`}
                        </Text>
                      </View>

                      <View style={{ alignSelf: 'stretch', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                        <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                          YTD Accrued Gratuity*
                        </Text>
                        <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                          TBD{/* {`K${payslipForMonth.payslipytdaccruedgratuity}`} */}
                        </Text>
                      </View>

                      <View style={{ alignSelf: 'stretch', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                        <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                          Contract End Date
                        </Text>
                        <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                          {payslipForMonth.payslipcontractend}
                        </Text>
                      </View>

                      <View style={{ alignSelf: 'stretch', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10, marginBottom: 10 }}>
                        <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                          Projected Payout at Maturity*
                        </Text>
                        <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                          TBD{/* {`K${payslipForMonth.payslipprojectedpayout}`} */}
                        </Text>
                      </View>

                      <View style={{ alignSelf: 'stretch', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
                        <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                          * Estimated amounts only
                        </Text>
                      </View>

                      {/* For sections that require a distinct background or layout */}
                      <View style={{
                        backgroundColor: '#f9f9f9', // Example background color
                        padding: 10, // Inner spacing
                        borderRadius: 5, // Rounded corners
                        marginBottom: 5,
                        border: "1px solid #ccc", // Space between this and the next section
                      }}>
                        <Text style={{
                          fontSize: 12,
                          fontWeight: 'bold',
                          marginBottom: 4,
                        }}>
                          Salary Deposit Details
                        </Text>
                        <Text style={{ fontSize: 10 }}>
                          {"Employee Bank: "}
                          <Text style={{ fontWeight: 'normal' }}>
                            {payslipForMonth.payslipbank}
                          </Text>
                        </Text>

                        <Text style={{ fontSize: 10 }}>
                          {"Account Number: "}
                          <Text style={{ fontWeight: 'normal' }}>
                            {payslipForMonth.payslipaccount}
                          </Text>
                        </Text>

                        <Text style={{ fontSize: 10 }}>
                          {"Branch Location: "}
                          <Text style={{ fontWeight: 'normal' }}>
                            {payslipForMonth.paysliplocation}
                          </Text>
                        </Text>
                      </View>
                      <View style={{
                        flexDirection: 'row', // Aligns children (Text and Image) in a row.
                        alignItems: 'center', // Centers items vertically in the container.
                        justifyContent: 'center', // Centers the container's content horizontally.
                        marginTop: 10,
                      }}>
                        <Text style={{
                          fontSize: 10,
                          marginRight: 3, // Adds some space between the text and the image.
                        }}>
                          KOZO Pay by
                        </Text>
                        <Image
                          style={{ width: 20, height: 20 }}
                          src={tri8logo}
                        />
                      </View>
                    </View>
                  </View>
                  <View style={{
                    textAlign: "center",
                    marginTop: "10px",
                    fontSize: 10
                  }}>
                    <Text >
                      If you notice an error on the payslip - please contact management
                    </Text>

                  </View>
                </View>
              </View>
            )
          })}
        </Page>
      ))}
    </Document>
  );

  // function csvData() {

  //   let headers = [
  //     { label: "First Name", key: "firstname" },
  //     { label: "Last Name", key: "lastname" },
  //     { label: "Email", key: "email" },
  //   ];

  //   let data = [
  //     ["Date: ", `${today()}`],
  //     ["School: ", "Ebenezer Schools"],
  //     ["EXECUTED BY: ", `${username}`],
  //     [
  //       "`ER NAPSA ACC. #",
  //       "Run Date",
  //       "SSN #",
  //       "NRC #",
  //       "BANK ACC. #",
  //       "BANK NAME",
  //       "BANK BRANCH",
  //       "SURNAME",
  //       "FIRST NAME",
  //       "DATE OF BIRTH",
  //       "TPIN",
  //       "CAMPUS",
  //       "BASIC PAY",
  //       "ALLOWANCES",
  //       "DEDUCTIONS",
  //       "ACCRUED GRATUITY",
  //     ],
  //   ];

  //   for (let i = 0; i < employees.length; i++) {
  //     let rowData = [];
  //     if (
  //       employees[i]?.status[employees[i].status.length - 1]?.status ===
  //         "Active" ||
  //       employees[i]?.status[employees[i].status.length - 1]?.status ===
  //         "Probation"
  //     ) {
  //       rowData.push(employees[i].napsa);
  //       rowData.push(today());
  //       rowData.push(employees[i].pensionno);
  //       rowData.push(employees[i].nrc);
  //       rowData.push(employees[i].bankaccount);
  //       rowData.push(employees[i].bankname);
  //       rowData.push(employees[i].bankbranch);
  //       rowData.push(employees[i].lastname);
  //       rowData.push(employees[i].firstname);
  //       rowData.push(employees[i].dob);
  //       rowData.push(employees[i].zratpin);
  //       rowData.push(employees[i].campus);
  //       rowData.push(employees[i].salary);

  //       if (employees[i].allowances.length > 0) {
  //         let allowanceSum = 0;
  //         for (let j = 0; j < employees[i].allowances.length; j++) {
  //           allowanceSum += parseInt(
  //             employees[i].allowances[j].allowanceamount
  //           );
  //         }
  //         rowData.push(allowanceSum.toString());
  //       } else {
  //         rowData.push("");
  //       }

  //       rowData.push("");
  //       rowData.push(calculateGratuity(employees[i]).toString());
  //       // count --;
  //       data.push(rowData);
  //     } else {
  //     }
  //   }
  //   return data;
  // }


  function CsvData(employees, selectedMonth) {
    // We're assuming that `employees` is an array of employee objects
    // and that each employee object contains a payslips array.
    const formattedData = employees.reduce((acc, employee) => {
      // Find the relevant payslip for the selected month
      const payslipForMonth = employee.payslips.find(payslip => payslip.payslipperiod === selectedMonth);

      if (payslipForMonth) {
        // Construct a flat object for each relevant employee's payslip information
        const rowData = {
          // first_name: employee.firstname,
          // last_name: employee.lastname,
          // email: employee.email, // assuming email is a direct property of employee
          // salary: payslipForMonth.payslipmonthsalary,
          // ytdSalary: payslipForMonth.payslipytdsalary,
          // napsa: payslipForMonth.payslipmonthnapsa, // Assuming there is NAPSA data
          // paye: payslipForMonth.payslipmonthpaye,
          // allowances: payslipForMonth.payslipmonthallowances || "0",
          // totalDeductions: payslipForMonth.payslipmonthtotaldeductions,
          // netPay: payslipForMonth.payslipmonthnetpay,


          period: payslipForMonth.payslipperiod,
          firstname: employee.firstname,
          lastname: employee.lastname,
          id: employee.employeeid,
          title: employee.title,
          nrc: employee.nrc,
          tpin: employee.zratpin,
          ssn: employee.pensionno,
          monthsalary: payslipForMonth.payslipmonthsalary,

          monthallowances: payslipForMonth.payslipmonthallowances,
          monthother: payslipForMonth.payslipmonthother,
          monthgrosspay: payslipForMonth.payslipmonthgrosspay,
          monthpaye: payslipForMonth.payslipmonthpaye,
          monthnapsa: payslipForMonth.payslipmonthnapsa,
          monthnhima: payslipForMonth.payslipmonthnhima,
          monthtotaldeductions: payslipForMonth.payslipmonthtotaldeductions,
          monthnetpay: payslipForMonth.payslipmonthnetpay,

          ytdsalary: payslipForMonth.payslipytdsalary,
          ytdallowances: payslipForMonth.payslipytdallowances,
          ytdother: payslipForMonth.payslipytdother,
          ytdgrosspay: payslipForMonth.payslipytdgrosspay,
          ytdpaye: payslipForMonth.payslipytdpaye,
          ytdnapsa: payslipForMonth.payslipytdnapsa,
          ytdnhima: payslipForMonth.payslipytdnhima,
          ytdtotaldeductions: payslipForMonth.payslipytdtotaldeductions,
          ytdnetpay: payslipForMonth.payslipytdnetpay,

          gratuity: payslipForMonth.payslipgratuity,
          // ytdaccruedgratuity: payslipForMonth.payslipytdaccruedgratuity,
          // contractend: payslipForMonth.payslipcontractend,
          // projectedpayout: payslipForMonth.payslipprojectedpayout,

          bank: payslipForMonth.payslipbank,
          account: payslipForMonth.payslipaccount,
          location: payslipForMonth.paysliplocation,

          // Add additional fields as needed
        };

        // Add this employee's payroll data to the accumulator
        acc.push(rowData);
      }

      return acc;
    }, []);

    return formattedData;
  }

  function CsvDatanapsa(employees, selectedMonth) {
    const formattedData = employees.reduce((acc, employee) => {
      const payslipForMonth = employee.payslips.find(payslip => payslip.payslipperiod === selectedMonth);

      if (payslipForMonth) {

        let [year, month] = payslipForMonth.payslipperiod.split('-');

        const rowData = {
          EmployeeNapsa: employee.napsa,
          Year: year,
          month: Number(month),
          SSN: employee.pensionno,
          NRC: employee.nrc,
          Surname: employee.lastname,
          FirstName: employee.firstname,
          OtherName: employee.maiden,
          DateOfBirth: employee.dob,
          GrossWage: payslipForMonth.payslipmonthgrosspay,
          EmployersShare: payslipForMonth.payslipmonthnapsa,
          EmployeeShare: payslipForMonth.payslipmonthnapsa,
          i: "I"
        };

        acc.push(rowData);
      }

      return acc;
    }, []);

    return formattedData;
  }

  function CsvDatanhima(employees, selectedMonth) {
    const formattedData = employees.reduce((acc, employee) => {
      const payslipForMonth = employee.payslips.find(payslip => payslip.payslipperiod === selectedMonth);

      if (payslipForMonth) {
        let [year, month] = payslipForMonth.payslipperiod.split('-');

        const rowData = {
          NHIS: "NHIS" + employee.nhisid,
          Year: year,
          Month: Number(month),
          NHA: "NHA" + employee.nhaid,
          surname: employee.firstname,
          lastname: employee.lastname,
          otherName: employee.maiden,
          DateOfBirth: employee.dob,
          GrossPay: payslipForMonth.payslipmonthgrosspay,
          employeeShare: employee.salary * 0.01,
          employerShare: employee.salary * 0.01,
        };
        acc.push(rowData);
      }

      return acc;
    }, []);

    return formattedData;
  }

  function CsvDatapaye(employees, selectedMonth) {
    const formattedData = employees.reduce((acc, employee) => {
      const payslipForMonth = employee.payslips.find(payslip => payslip.payslipperiod === selectedMonth);

      if (payslipForMonth) {
        let [year, month] = payslipForMonth.payslipperiod.split('-');

        const rowData = {
          TPIN: employee.zratpin,
          fullName: employee.firstname + " " + employee.lastname,
          EmployeeNature: "Permanent",
          GrossEmoluments: payslipForMonth.payslipmonthgrosspay,
          ChargeableEmoluments: payslipForMonth.payslipmonthgrosspay,
          TotalTaxCredit: 0,
          TaxDeducted: payslipForMonth.payslipmonthpaye,
          TaxAdjusted: 0,
        };
        acc.push(rowData);
      }

      return acc;
    }, []);

    return formattedData;
  }



  function GeneratePayrollMockExtractButton() {
    console.log("SELECTED", selectedPayrollMonthExtract)
    const payrollData = CsvData(
      employeesList.filter(employee =>
        employee.payslips.some(payslip =>
          payslip.payslipperiod === `${months[0]?.year}-${months[0]?.month.toString().padStart(2, "0")}` && payslip.status === "0"
        )
      ),
      `${months[0]?.year}-${months[0]?.month.toString().padStart(2, "0")}`
    );


    return (
      <CSVLink
        data={payrollData}
        filename={`SMSPayrollExtract-${new Date().toISOString()}.csv`}
        headers={payrollData.length > 0 ? Object.keys(payrollData[0]).map(key => ({ label: key, key })) : []}
      >
        <Button variant="contained" size="large" startIcon={<SummarizeIcon />}>
          Download Payroll Extract
        </Button>
      </CSVLink>
    );
  }

  function GeneratePayrollExtractButton() {
    console.log("SELECTED", selectedPayrollMonthExtract)
    const payrollData = CsvData(
      employeesList.filter(employee =>
        employee.payslips.some(payslip =>
          payslip.payslipperiod === selectedPayrollMonthExtract && payslip.status === "2"
        )
      ),
      selectedPayrollMonthExtract
    );


    return (
      <CSVLink
        data={payrollData}
        filename={`SMSPayrollExtract-${new Date().toISOString()}.csv`}
        headers={payrollData.length > 0 ? Object.keys(payrollData[0]).map(key => ({ label: key, key })) : []}
      >
        <Button style={{ width: 400 }} variant="contained" size="large" startIcon={<SummarizeIcon />}>
          Download Payroll Extract
        </Button>
      </CSVLink>
    );
  }

  function GeneratePayrollExtractButtonnapsa() {
    console.log("SELECTED", selectedPayrollMonthExtract)
    const payrollData = CsvDatanapsa(
      employeesList.filter(employee =>
        employee.payslips.some(payslip =>
          payslip.payslipperiod === selectedPayrollMonthExtract && payslip.status === "2"
        )
      ),
      selectedPayrollMonthExtract
    );


    return (
      <CSVLink
        data={payrollData}
        filename={`SMSPayrollExtractNAPSA-${new Date().toISOString()}.csv`}
        headers={payrollData.length > 0 ? Object.keys(payrollData[0]).map(key => ({ label: key, key })) : []}
      >
        <Button style={{ width: 400 }} variant="contained" size="large" startIcon={<SummarizeIcon />}>
          Download NAPSA Extract
        </Button>
      </CSVLink>
    );
  }

  function GeneratePayrollExtractButtonnhima() {
    console.log("SELECTED", selectedPayrollMonthExtract)
    const payrollData = CsvDatanhima(
      employeesList.filter(employee =>
        employee.payslips.some(payslip =>
          payslip.payslipperiod === selectedPayrollMonthExtract && payslip.status === "2"
        )
      ),
      selectedPayrollMonthExtract
    );


    return (
      <CSVLink
        data={payrollData}
        filename={`SMSPayrollExtractNHIMA-${new Date().toISOString()}.csv`}
        headers={payrollData.length > 0 ? Object.keys(payrollData[0]).map(key => ({ label: key, key })) : []}
      >
        <Button style={{ width: 400 }} variant="contained" size="large" startIcon={<SummarizeIcon />}>
          Download NHIMA Extract
        </Button>
      </CSVLink>
    );
  }

  function GeneratePayrollExtractButtonpaye() {
    console.log("SELECTED", selectedPayrollMonthExtract)
    const payrollData = CsvDatapaye(
      employeesList.filter(employee =>
        employee.payslips.some(payslip =>
          payslip.payslipperiod === selectedPayrollMonthExtract && payslip.status === "2"
        )
      ),
      selectedPayrollMonthExtract
    );


    return (
      <CSVLink
        data={payrollData}
        filename={`SMSPayrollExtractPAYE-${new Date().toISOString()}.csv`}
        headers={payrollData.length > 0 ? Object.keys(payrollData[0]).map(key => ({ label: key, key })) : []}
      >
        <Button style={{ width: 400 }} variant="contained" size="large" startIcon={<SummarizeIcon />}>
          Download Paye Extract
        </Button>
      </CSVLink>
    );
  }


  const generateFileName = () => {
    const date = new Date();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-based
    const year = date.getFullYear();
    const day = String(date.getDate()).padStart(2, '0');
    const randomNum = Math.floor(Math.random() * 10000); // Generate a random number
  
    return `${month}-${year}-${day}-${randomNum}.pdf`;
  };



  let months = monthsSinceJanuary2024();

  return (
    <PermissionsGate scopes={[SCOPES.canDelete]}>
      <Dialog open={openLoadDialog} keepMounted>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <Dialog open={openUpdateDialog} keepMounted>
        <DialogContent>
          <CircularProgress />
          <div>Updating payslips...</div>
          <div>Please, do not refresh the page.</div>
          <div>Current Employee: {currentEmployeeName}</div>
          <div>
            Progress: {updatedEmployees} / {totalEmployees}
          </div>
        </DialogContent>
      </Dialog>
      <React.Fragment>
        <section className="bodybackground ">
          <div>
            {
              <Dialog open={openMockPayroll} onClose={() => setOpenMockPayrollModal(false)}>
                {/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "90vh" }}> */}
                {Array.isArray(employeesList) && (
                  <>
                    <PDFViewer style={{ height: 700, maxHeight: "100vh", maxWidth: "100vw", width: 600 }}>
                      <PayrollDocument
                        employeesList={employeesList.filter(employee => {
                          // Get the most recent status information
                          const recentStatusInfo = getMostRecentStatus(employee.status);
                          const recentStatus = recentStatusInfo?.status;
                          const statusDate = new Date(recentStatusInfo?.statusdate);

                          // Check if the status date is before or on the last day of the selected month
                          const selectedMonthDate = new Date(months[0]?.year, months[0]?.month - 1 + 1, 0); // Last day of the selected month
                          const isEligibleStatus = recentStatus === "Active" || recentStatus === "Probation";
                          const isStatusDateEligible = statusDate <= selectedMonthDate;

                          // Check for payslip conditions
                          return isEligibleStatus && isStatusDateEligible && employee.payslips.some(payslip =>
                            payslip.payslipperiod === `${months[0]?.year}-${months[0]?.month.toString().padStart(2, "0")}` && payslip.status === "0"
                          );
                        })}
                        selectedMonth={`${months[0]?.year}-${months[0]?.month.toString().padStart(2, "0")}`}
                      />
                    </PDFViewer>
                    <PDFDownloadLink
                      document={
                        <PayrollDocument
                          employeesList={employeesList.filter(employee => {
                            // Get the most recent status information
                            const recentStatusInfo = getMostRecentStatus(employee.status);
                            const recentStatus = recentStatusInfo?.status;
                            const statusDate = new Date(recentStatusInfo?.statusdate);

                            // Check if the status date is before or on the last day of the selected month
                            const selectedMonthDate = new Date(months[0]?.year, months[0]?.month - 1 + 1, 0); // Last day of the selected month
                            const isEligibleStatus = recentStatus === "Active" || recentStatus === "Probation";
                            const isStatusDateEligible = statusDate <= selectedMonthDate;

                            // Check for payslip conditions
                            return isEligibleStatus && isStatusDateEligible && employee.payslips.some(payslip =>
                              payslip.payslipperiod === `${months[0]?.year}-${months[0]?.month.toString().padStart(2, "0")}` && payslip.status === "0"
                            );
                          })}
                          selectedMonth={`${months[0]?.year}-${months[0]?.month.toString().padStart(2, "0")}`}
                        />
                      }
                      fileName={generateFileName()}
                      style={{ maxWidth: 172, display: "flex", alignSelf: "center", marginTop: 20 }} // Added marginTop for spacing
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          "Loading document..."
                        ) : (
                          <Button
                            startIcon={<DownloadIcon />}
                            variant="contained"
                            color="success"
                            size="small"
                          >
                            Download Mock
                          </Button>

                        )
                      }
                    </PDFDownloadLink>
                  </>
                )}
                {/* </div> */}
              </Dialog>
            }
            <Dialog open={openPayroll} onClose={() => {
              setOpenLoadDialog(true)
              setOpenPayrollModal(false)
              setOpenLoadDialog(false)
            }}>
              {isSelectingMonth ? (
                <>
                  <DialogTitle>Select Payroll Period</DialogTitle>
                  <DialogContent>
                    <FormControl fullWidth>
                      <InputLabel id="payroll-month-select-label">Month</InputLabel>
                      <Select
                        labelId="payroll-month-select-label"
                        id="payroll-month-select"
                        value={selectedPayrollMonth}
                        label="Month"
                        onChange={handlePayrollMonthChange}
                      >
                        {[...payrollMonths].sort().map((month) => (
                          <MenuItem key={month} value={month}>
                            {month}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </DialogContent>
                </>
              ) : (
                <>
                  {/* <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "90vh", width: "100vw" }}> */}
                  <PDFViewer style={{ height: 700, maxHeight: "100vh", maxWidth: "100vw", width: 600 }}> {/* Adjusted height to leave space for button below */}
                    <PayrollDocument
                      employeesList={employeesList.filter(employee =>
                        employee.payslips.some(payslip => payslip.payslipperiod === selectedPayrollMonth && payslip.status === "2")
                      )}
                      selectedMonth={selectedPayrollMonth}
                    />
                  </PDFViewer>
                  <PDFDownloadLink
                    document={
                      <PayrollDocument
                        employeesList={employeesList.filter(employee =>
                          employee.payslips.some(payslip => payslip.payslipperiod === selectedPayrollMonth && payslip.status === "2")
                        )}
                        selectedMonth={selectedPayrollMonth}
                      />
                    }
                    fileName={generateFileName()}
                    style={{ maxWidth: 172, display: "flex", alignSelf: "center", marginTop: 20 }} // Added marginTop for spacing
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        "Loading document..."
                      ) : (
                        <Button
                          startIcon={<DownloadIcon />}
                          variant="contained"
                          color="success"
                          size="small"
                        >
                          Download Payroll
                        </Button>
                      )
                    }
                  </PDFDownloadLink>
                  {/* </div> */}

                </>
              )}
            </Dialog>
            <Dialog open={openMockExtract} onClose={() => {
              setOpenLoadDialog(true)
              setOpenMockExtractModal(false)
              setOpenLoadDialog(false)
            }}>
              <>
                {Array.isArray(employeesList) && (
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 300, width: 400 }}>
                    {GeneratePayrollMockExtractButton()}
                  </div>
                )}
              </>
            </Dialog>
            <Dialog open={openExtract} onClose={() => {
              setOpenLoadDialog(true)
              setOpenExtractModal(false)
              setOpenLoadDialog(false)
            }}>
              {isSelectingMonthExtract ? (
                <>
                  <DialogTitle>Select Payroll Period</DialogTitle>
                  <DialogContent>
                    <FormControl fullWidth>
                      <InputLabel id="payroll-month-select-label">Month</InputLabel>
                      <Select
                        labelId="payroll-month-select-label"
                        id="payroll-month-select"
                        value={selectedPayrollMonthExtract}
                        label="Month"
                        onChange={handlePayrollMonthChangeExtract}
                      >
                        {[...payrollMonths].sort().map((month) => (
                          <MenuItem key={month} value={month}>
                            {month}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </DialogContent>
                </>
              ) : (
                <>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 50, width: 500 }}>
                    {GeneratePayrollExtractButton()}
                  </div>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 50, width: 500 }}>
                    {GeneratePayrollExtractButtonnapsa()}
                  </div>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 50, width: 500 }}>
                    {GeneratePayrollExtractButtonnhima()}
                  </div>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: 50, width: 500 }}>
                    {GeneratePayrollExtractButtonpaye()}
                  </div>
                </>
              )}
            </Dialog>
            <Grid
              item
              container
              className="listbackground"
              direction="column"
              justifyContent="space-between"
              alignItems="stretch"
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid container item xs={6}>
                <PointOfSaleIcon fontSize="large" color="primary" />
                <Typography variant="h4">Payroll</Typography>
              </Grid>
              <Grid item xs={6} alignItems="start">
                <Typography variant="h6">
                  Next available month period for payroll:
                </Typography>
                {getNextMonthPayroll(months)}
              </Grid>
              {months && months.length > 0 && (
                <>
                  {

                    (months[0].noPayslipCount > 0 || (months[0].approvedCount === 0 && months[0].liveCount === 0)) && (
                      <>
                        <PermissionsGate scopes={[SCOPES.canrunmockpayroll]}>
                          <Grid container direction="column" alignItems="center" spacing={2}> {/* Adjusted for vertical stacking and center alignment */}

                            <Grid item> {/* Removed xs={6} to allow content-based sizing */}
                              {GenerateMockPayrollExtractButton()}
                            </Grid>

                            {
                              months && months.length > 0 && months[0].mockCount > 0 && (
                                <>
                                  <Grid item> {/* Removed xs={6} to allow content-based sizing */}
                                    <Button
                                      style={{ width: 300, marginLeft: 20 }}
                                      variant="contained"
                                      size="large"
                                      startIcon={<SummarizeIcon />}
                                      onClick={() => setOpenMockPayrollModal(true)}
                                    >
                                      View Mock Payroll
                                    </Button>
                                  </Grid>
                                  {Array.isArray(employeesList) && (
                                    <Grid item> {/* Removed xs={6} to allow content-based sizing */}
                                      <Button
                                        style={{ width: 300, marginLeft: 20 }}
                                        variant="contained"
                                        size="large"
                                        startIcon={<SummarizeIcon />}
                                        onClick={() => setOpenMockExtractModal(true)}
                                      >
                                        View Mock Extract
                                      </Button>
                                    </Grid>
                                  )}
                                </>
                              )
                            }
                          </Grid>
                        </PermissionsGate>
                        <PermissionsGate scopes={[SCOPES.canapprovepayroll]}>
                        <Grid container direction="column" alignItems="center" spacing={2}> {/* Adjusted for vertical stacking and center alignment */}

                          {
                            months && months.length > 0 && months[0].mockCount > 0 && (
                              <>
                                <Grid item> {/* Removed xs={6} to allow content-based sizing */}
                                  <Button
                                    style={{ width: 300, marginLeft: 20 }}
                                    variant="contained"
                                    size="large"
                                    startIcon={<SummarizeIcon />}
                                    onClick={() => setOpenMockPayrollModal(true)}
                                  >
                                    View Mock Payroll
                                  </Button>
                                </Grid>
                                {Array.isArray(employeesList) && (
                                  <Grid item> {/* Removed xs={6} to allow content-based sizing */}
                                    <Button
                                      style={{ width: 300, marginLeft: 20 }}
                                      variant="contained"
                                      size="large"
                                      startIcon={<SummarizeIcon />}
                                      onClick={() => setOpenMockExtractModal(true)}
                                    >
                                      View Mock Extract
                                    </Button>
                                  </Grid>
                                )}
                              </>
                            )
                          }
                        </Grid>
                      </PermissionsGate>
                      </>
                    )
                  }

                  {months[0].mockCount > 0 &&
                    months[0].noPayslipCount === 0 && (
                      <PermissionsGate scopes={[SCOPES.canapprovepayroll]}>
                        <Grid item xs={6} alignItems="start">
                          <Button
                            style={{ width: 300 }}
                            variant="contained"
                            size="large"
                            startIcon={<SummarizeIcon />}
                            onClick={handleGenerateMockData} // Adjust onClick handler as needed
                          >
                            Approve Mock Payroll
                          </Button>
                        </Grid>
                      </PermissionsGate>
                    )}
                  {months[0].mockCount === 0 && months[0].approvedCount > 0 && (
                    <PermissionsGate scopes={[SCOPES.canrunlivepayroll]}>
                      <Grid item xs={6} alignItems="start">
                        <Button
                          style={{ width: 300 }}
                          variant="contained"
                          size="large"
                          startIcon={<SummarizeIcon />}
                          onClick={handleGenerateLivekData} // Adjust onClick handler as needed
                        >
                          Run Live Payroll
                        </Button>
                      </Grid>
                    </PermissionsGate>
                  )}

                </>
              )}
              <Grid item xs={6} alignItems="start">
                <Button
                  style={{ width: 300 }}
                  variant="contained"
                  size="large"
                  startIcon={<SummarizeIcon />}
                  onClick={setopenPayroll} // Adjust onClick handler as needed
                >
                  View Payroll
                </Button>
              </Grid>
              <Grid item xs={6} alignItems="start">
                {Array.isArray(employeesList) && (
                  <Button
                    style={{ width: 300 }}
                    variant="contained"
                    size="large"
                    startIcon={<SummarizeIcon />}
                    onClick={setopenExtract} // Adjust onClick handler as needed
                  >
                    View Extract
                  </Button>
                )}
              </Grid>
              {/* <Grid item xs={6} alignItems="start">
                {Array.isArray(employeesList) && (
                  <Button
                    style={{ width: 300 }}
                    variant="contained"
                    size="large"
                    startIcon={<SummarizeIcon />}
                    onClick={setopenExtractnapsa} // Adjust onClick handler as needed
                  >
                    View NAPSA Extract
                  </Button>

                )}
              </Grid> */}
              {/* <Grid item xs={6} alignItems="start">
                {Array.isArray(employeesList) && (
                  <Button
                    style={{ width: 300 }}
                    variant="contained"
                    size="large"
                    startIcon={<SummarizeIcon />}
                    onClick={setopenExtractnhima} // Adjust onClick handler as needed
                  >
                    View NHIMA Extract
                  </Button>

                )}
              </Grid> */}
              {/* <Grid item xs={6} alignItems="start">
                {Array.isArray(employeesList) && (
                  <Button
                    style={{ width: 300 }}
                    variant="contained"
                    size="large"
                    startIcon={<SummarizeIcon />}
                    onClick={setopenExtractpaye} // Adjust onClick handler as needed
                  >
                    View PAYE Extract
                  </Button>

                )}
              </Grid> */}
            </Grid>
          </div>
        </section>
      </React.Fragment>
    </PermissionsGate >
  );
}

export default Payroll;
