import { useState, useEffect } from "react";
import { Amplify, Auth, Hub } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "../../aws-exports";
import { useNavigate } from "react-router";
import KozoLogo from "../../assets/img/images/Kozo_Logo.png";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Button,
  Divider,
  Grid,
  Typography,
  TextField,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  DialogTitle,
} from "@mui/material";
import React from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import NumberFormat from "react-number-format";
import ReactCountryFlag from "react-country-flag";
import { getAllStudents, getAllowanceTypes, getDeductionTypes, getServiceProviders } from "../../util/api-calls";
import { Student } from "../customer/customerlist";
import InvoiceModal from "../editcontact/invoicemodal";
import { Enrolment } from "../../util/data-models";
import {
  InvoiceData,
  ReceiptData,
} from "../editcontact/pdfgenerator/invoice-data";
import ReceiptModal from "../editcontact/receiptmodal";
import ReceiptTableFooter from "../editcontact/pdfgenerator/invoice-table-components/receipt-table-footer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { PDFViewer, Document, Page, Text, View, StyleSheet, Image, PDFDownloadLink } from '@react-pdf/renderer';
import DownloadIcon from "@mui/icons-material/Download";

import tri8logo from "../../assets/img/images/tri8logo.png";
Amplify.configure(awsExports);

function EmployeeAccess(student: Student | any, school, serviceProviders, deductionTypes, allowanceTypes) {
  const navigate = useNavigate();
  const [errormsg, updateError] = useState("");
  const [isCountdownActive, setIsCountdownActive] = useState(true);
  const [invoiceFlag, updateinvoiceFlag] = useState(false);
  const [receiptFlag, updateReceiptFlag] = useState(false);

  const [openPayroll, setOpenPayrollModal] = useState(false);
  const [isSelectingMonth, setIsSelectingMonth] = React.useState(true);
  const [selectedPayrollMonth, setSelectedPayrollMonth] = useState("");
  const [payrollMonths, setPayrollMonths] = useState([]);

  const [isEnroled, setIsEnroled] = useState(false);

  const [openLoadDialog, setOpenLoadDialog] = React.useState(false);
  const [countDown, setCountDown] = React.useState(0);
  const [runTimer, setRunTimer] = React.useState(false);
  const closeLoadDialog = () => {
    setOpenLoadDialog(false);
  };
  const handleOpenLoadDialog = () => {
    setOpenLoadDialog(true);
  };

  React.useEffect(() => {
    let timerId;
    if (runTimer && isCountdownActive) {
      setCountDown(60 * 5); // Reset to 5 minutes whenever the countdown is started
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer, isCountdownActive]);



  React.useEffect(() => {
    if (countDown < 0 && runTimer) {
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  const togglerTimer = () => setRunTimer((t) => !t);

  const seconds = String(countDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

  useEffect(() => {
    // if (student?.enrolments[0]) {
    setIsEnroled(true);
    // }
    if (window.location.pathname === "/employee-access") {
      setRunTimer(true);
      setTimeout(() => {
        if (window.location.pathname === "/employee-access") {
          navigate("/employee-signin");
        }
      }, 300000);
    }
  }, [student]);

  useEffect(() => {
    if (student) {
      const extractPayrollMonths = () => {
        const uniqueMonths = new Set(); // Use a Set to ensure uniqueness


        student.payslips.forEach(payslip => {
          if (payslip.status === "2") {
            uniqueMonths.add(payslip.payslipperiod); // Add to Set if status is "2"
          }
        });

        return Array.from(uniqueMonths); // Convert Set back to Array
      };

      const payrollMonths = extractPayrollMonths();
      setPayrollMonths(payrollMonths); // Update state
    }
  }, [student]); // Rerun effect if employeesList changes

  function setopenPayroll() {
    if (openPayroll === true) {
      setOpenPayrollModal(false);
      setIsCountdownActive(true); // Reactivate the countdown when closing the PDF
    } else {
      setOpenPayrollModal(true);
      setIsCountdownActive(false); // Pause the countdown when opening the PDF
    }
  }

  const payrollArray = [
    {
      school: "Ebenezer Schools - Mazabuka",
      date: new Date("01/01/2024"),
      salary: "4000",
      allowances: "800",
      ytd: 8000,
      ytdAllowances: 1600,
      ytdOther: 0,
      other: 0,
      napsa: 80,
      nhima: 20,
      paye: 100,
      ytdNapsa: 160,
      ytdNhima: 40,
      gratuity: 25,
      accruedGratuity: 2000,
      contractEndDate: new Date("01/01/2024"),
      projectedPayout: 24000,
    },
    {
      school: "Ebenezer Schools - Mazabuka",
      date: new Date("01/01/2024"),
      salary: "4000",
      allowances: "800",
      ytd: 8000,
      ytdAllowances: 1600,
      ytdOther: 0,
      other: 0,
      napsa: 80,
      nhima: 40,
      paye: 100,
      ytdNapsa: 160,
      ytdNhima: 20,
      gratuity: 25,
      accruedGratuity: 2000,
      contractEndDate: new Date("01/01/2024"),
      projectedPayout: 24000,
    },
  ];

  function today() {
    let date = new Date();
    return (
      date.getFullYear() +
      "-" +
      ("0" + (new Date().getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + (new Date().getDate() + 1)).slice(-2)
    ).toString();
  }

  async function signIn() { }

  function getCurrentSchoolYear() {
    const date = new Date();
    let year = date.getFullYear();

    if (date.getMonth() === 11) {
      // Month in JavaScript is 0-indexed, so 11 is December
      year += 1;
    }
    return year.toString();
  }

  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set());
  function formatDate(dateStr: string): string {
    const date = new Date(dateStr);
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    let formattedDate = date.toLocaleDateString('en-US', options);

    // Function to add ordinal suffix
    function getOrdinal(n: number): string {
      return n + (["st", "nd", "rd"][((n + 90) % 100 - 10) % 10 - 1] || "th");
    }

    // Replace the day number with its ordinal counterpart
    const day = date.getDate();
    formattedDate = formattedDate.replace(day.toString(), getOrdinal(day));

    return formattedDate;
  }

  const toggleRow = (index: number) => {
    const newExpandedRows = new Set(expandedRows);
    if (newExpandedRows.has(index)) {
      newExpandedRows.delete(index);
    } else {
      newExpandedRows.add(index);
    }
    setExpandedRows(newExpandedRows);
  };

  const handlePayrollMonthChange = (event) => {
    setSelectedPayrollMonth(event.target.value);
    setIsSelectingMonth(false); // Hide month selection and show the PDF
  };



  const borderColor = "#000000";

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    },
    title: {
      fontSize: 24,
      textAlign: 'center',
      marginBottom: 20,
    },
    headerContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 10,
    },
    header: {
      fontSize: 18,
      marginBottom: 5,
    },
    subtitle: {
      fontSize: 15,
      marginBottom: 3,
    },
    contentContainer: {
      flexDirection: 'row',
    },
    leftColumn: {
      flexDirection: 'column',
      flexGrow: 9,
    },
    rightColumn: {
      flexDirection: 'column',
      flexGrow: 5,
      marginLeft: 15,
    },
    sectionHeader: {
      fontSize: 13,
      fontWeight: 'bold',
      marginBottom: 3,
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    box: {
      marginBottom: 5,
    },
    footer: {
      textAlign: 'center',
      marginTop: 25,
      fontSize: 12,
    },
    payslipHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 10, // Reduced margin
    },
    payslipColumn: {
      flexDirection: 'column',
    },
    subTitle: {
      fontSize: 12,
      marginBottom: 3, // Reduced margin
    },
    bodyText: {
      fontSize: 10,
      margin: 2, // Margin kept small
    },
    salarySection: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: 2, // Added to reduce spacing
    },
    totalSection: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderTop: 1,
      paddingTop: 5,
      marginTop: 5,
    },
    boldText: {
      fontWeight: 'bold',
    },
    payslipHeaderColumn: {
      flexDirection: 'column',
    },
    payslipBody: {
      flexDirection: 'row',
      borderTop: 1,
      borderColor: '#000',
      paddingTop: 5,
    },
    earningsSection: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginVertical: 2,
    },
    sum: {
      fontWeight: 'bold',
    },
    row2: {
      flexDirection: "row",
      borderBottomColor: "#000000",
      borderBottomWidth: 1,
      borderTopColor: "#000000",
      borderTopWidth: 1,
      alignItems: "center",
      height: 50,
      fontStyle: "bold",
    },
    row3: {
      flexDirection: "row",
      borderBottomColor: "#000000",
      borderBottomWidth: 1,
      borderTopColor: "#000000",
      borderTopWidth: 1,
      alignItems: "center",
      height: 60,
      fontStyle: "bold",
    },
    row4: {
      flexDirection: "row",
      borderBottomColor: "#000000",
      borderBottomWidth: 1,
      borderTopColor: "#000000",
      borderTopWidth: 1,
      alignItems: "center",
      height: 75,
      fontStyle: "bold",
    },
    container: {
      flexDirection: "row",
      borderBottomColor: "#000000",
      borderBottomWidth: 1,
      alignItems: "center",
      height: 24,
      textAlign: "center",
      fontStyle: "bold",
      flexGrow: 1,
    },
    description: {
      width: "6.25%",
      borderRightColor: borderColor,
      borderLeftColor: borderColor,
      borderRightWidth: 1,
      borderLeftWidth: 1,
      height: 24,
      paddingLeft: 2,
      paddingTop: 5,
    },
    description2: {
      width: "6.25%",
      borderRightColor: borderColor,
      borderLeftColor: borderColor,
      borderRightWidth: 1,
      borderLeftWidth: 1,
      height: 50,
      paddingLeft: 2,
      paddingTop: 5,
    },
    description3: {
      width: "6.25%",
      borderRightColor: borderColor,
      borderLeftColor: borderColor,
      borderRightWidth: 1,
      borderLeftWidth: 1,
      height: 60,
      paddingLeft: 2,
      paddingTop: 5,
    },
    description4: {
      width: "6.25%",
      borderRightColor: borderColor,
      borderLeftColor: borderColor,
      borderRightWidth: 1,
      borderLeftWidth: 1,
      height: 75,
      paddingLeft: 2,
      paddingTop: 5,
    },
    allowance: {
      width: "6.25%",
      borderRightColor: borderColor,
      borderLeftColor: borderColor,
      borderRightWidth: 1,
      borderLeftWidth: 1,
      height: 24,
      paddingLeft: 2,
      paddingTop: 5,
    },
    billTo: {
      paddingBottom: 5,
      fontFamily: "Helvetica-Oblique",
    },
  });

  const findAllowanceTypeNameById = (id) => {
    const type = allowanceTypes?.find(type => type.id === id);
    return type ? type.allowancetype : 'Unknown Type';
  };

  const findServiceProviderNameById = (id) => {
    const provider = serviceProviders?.find(provider => provider.id === id);
    return provider ? provider.serviceprovider : 'Unknown Provider';
  };

  // Example function to find deduction type name by ID
  const findDeductionTypeNameById = (id) => {
    const type = deductionTypes?.find(type => type.id === id);
    return type ? type.deductiontype : 'Unknown Type';
  };

  const PayrollDocument = ({ employee, selectedMonth }) => {
    const payslipForMonth = student?.payslips?.find(payslip => {
      return payslip?.payslipperiod?.toString() === selectedMonth?.toString();
    });

    // Proceed with rendering only if payslipForMonth is found
    if (!payslipForMonth) {
      return null;
    }

    return (
      <Document>
        <Page size="A4" style={{
          flexDirection: 'column',
          backgroundColor: '#F9F9F9',
          padding: 5,
        }}>



          <View >
            <View style={{
              // fontFamily: "'Arial', sans-serif",
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "10px",
              margin: "5px",
              backgroundColor: "#fff"
            }}>
              <View style={{
                borderBottomWidth: 2,
                borderColor: "#000",
                paddingBottom: 10,
                marginBottom: 20,
              }}>
                <View style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}>
                  {/* Left Column */}
                  <View style={{ flex: 1 }}>
                    <Text style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 4 }}>
                      {school.clientname}
                    </Text>
                    <Text style={{ fontSize: 10 }}>
                      {`Employee Payslip - ${formatDate(payslipForMonth.createdAt)}`}
                    </Text>
                  </View>

                  {/* Right Column */}
                  <View style={{ flex: 1, alignItems: 'flex-end' }}>
                    <View style={{
                      flexDirection: 'row',
                      justifyContent: 'flex-end', alignItems: 'flex-end', paddingBottom: 4
                    }}>
                      <Text style={{ fontWeight: 'bold', fontSize: 14, textAlign: 'right' }}>
                        {payslipForMonth?.payslipfirstname + " " + payslipForMonth?.paysliplastname}
                      </Text>
                      <Text style={{ fontWeight: 100, fontSize: 12, textAlign: 'right' }}>
                        {" - ID: " + payslipForMonth?.payslipid}
                      </Text>
                    </View>
                    <Text style={{ fontSize: 10, textAlign: 'right' }}>
                      {payslipForMonth?.paysliptitle}
                    </Text>
                    <Text style={{ fontSize: 10, textAlign: 'right' }}>
                      {`NRC # ${payslipForMonth?.payslipnrc}, TPIN # ${payslipForMonth?.paysliptpin}, SSN # ${payslipForMonth?.payslipssn}`}
                    </Text>
                  </View>
                </View>
              </View>

              <View style={{
                flexDirection: 'row',
              }}>
                <View style={{
                  width: '65%',
                }}>
                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 0,
                  }}>
                    <View style={{
                      width: '55%', // Adjust the width as necessary
                      justifyContent: 'center',
                    }}>
                      <Text style={{
                        fontSize: 12, // Adjust font size as necessary
                        marginBottom: 5, // Adjust spacing as necessary
                        fontWeight: 'bold',
                      }}>
                        EARNINGS
                      </Text>
                    </View>
                    <View style={{
                      width: '30%', // Adjust the width as necessary
                      justifyContent: 'center',
                    }}>
                      <Text style={{
                        fontSize: 12, // Adjust font size as necessary
                        marginBottom: 5, // Adjust spacing as necessary
                        fontWeight: 'bold',
                      }}>
                        THIS MONTH
                      </Text>
                    </View>
                    <View style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                    }}>
                      <Text style={{
                        fontSize: 12, // Adjust font size as necessary
                        marginBottom: 5, // Adjust spacing as necessary
                        fontWeight: 'bold',
                      }}>
                        YTD
                      </Text>
                    </View>
                  </View>
                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                  }}>
                    <Text style={{
                      width: '60%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10,
                      paddingLeft: 10
                    }}>
                      Salary
                    </Text>
                    <Text style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10
                    }}>
                      K{payslipForMonth?.payslipmonthsalary}
                    </Text>
                    <Text style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10
                    }}>
                      K{payslipForMonth?.payslipytdsalary}
                    </Text>
                  </View>

                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                  }}>
                    <Text style={{
                      width: '60%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10,
                      paddingLeft: 10
                    }}>
                      Other:
                    </Text>

                  </View>
                  {payslipForMonth.payslipmonthcustomallowances && payslipForMonth.payslipmonthcustomallowances.map((allowance, index) => (


                    <View style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginBottom: 5,
                    }}>
                      <Text style={{
                        width: '60%', // Adjust the width as necessary
                        justifyContent: 'center',
                        fontSize: 10,
                        paddingLeft: 20
                      }}>
                        {"• "}{findAllowanceTypeNameById(allowance.allowanceType)}
                      </Text>
                      <Text style={{
                        width: '20%', // Adjust the width as necessary
                        justifyContent: 'center',
                        fontSize: 10,
                      }}>
                        K{allowance.allowanceAmount}
                      </Text>
                      <Text style={{
                        width: '20%', // Adjust the width as necessary
                        justifyContent: 'center',
                        fontSize: 10,
                      }}>

                      </Text>
                    </View>

                    // <View key={index} style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
                    //   <Text style={{ width: '66%', fontSize: 10, textAlign: 'left', paddingLeft: 20 }}>
                    //     •{findDeductionTypeNameById(deduction.deductionType)} - {findServiceProviderNameById(deduction.deductionServiceProvider)}: K{deduction.deductionAmount}
                    //   </Text>
                    // </View>
                  ))}
                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                  }}>
                    <Text style={{
                      width: '60%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10,
                      paddingLeft: 10
                    }}>
                      Total Allowances:
                    </Text>
                    <Text style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10
                    }}>
                      K{payslipForMonth?.payslipmonthallowances ? payslipForMonth?.payslipmonthallowances : "0"}
                    </Text>
                    <Text style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10
                    }}>
                      K{payslipForMonth?.payslipytdallowances}
                    </Text>
                  </View>
                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                  }}>
                    <View style={{
                      width: '60%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 12
                    }}>
                      <Text
                        style={{
                          width: '100%',
                          backgroundColor: '#ccc',
                          fontSize: 12,
                          paddingLeft: 10
                        }}
                      >
                        GROSS PAY
                      </Text>
                    </View>
                    <View style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 12
                    }}>
                      <Text
                        style={{
                          width: '100%',
                          backgroundColor: '#ccc',
                        }}
                      >
                        K{payslipForMonth?.payslipmonthgrosspay}
                      </Text>
                    </View>
                    <View style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 12
                    }}>
                      <Text style={{
                        fontSize: 12,
                      }}>
                        K{payslipForMonth?.payslipytdgrosspay}
                      </Text>
                    </View>
                  </View>

                  <Text
                    style={{
                      fontSize: 12,
                    }}>
                    Deductions
                  </Text>
                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                  }}>
                    <View style={{
                      width: '60%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10,
                      paddingLeft: 10
                    }}>
                      <Text style={{ fontSize: 10 }}>
                        Statutory
                      </Text>
                    </View>
                  </View>
                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                  }}>
                    <Text style={{
                      width: '60%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10,
                      paddingLeft: 20
                    }}>PAYE</Text>
                    <Text style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10
                    }}>
                      K{payslipForMonth?.payslipmonthpaye}
                    </Text>
                    <Text style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10
                    }}>
                      K{payslipForMonth?.payslipytdpaye}
                    </Text>
                  </View>
                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                  }}>
                    <Text style={{
                      width: '60%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10,
                      paddingLeft: 20
                    }}>NAPSA</Text>
                    <Text style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10
                    }}>
                      K{payslipForMonth?.payslipmonthnapsa}
                    </Text>
                    <Text style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10
                    }}>
                      K{payslipForMonth?.payslipytdnapsa}
                    </Text>
                  </View>
                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                  }}>
                    <Text style={{
                      width: '60%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10,
                      paddingLeft: 20
                    }}>
                      NHIMA
                    </Text>
                    <Text style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10
                    }}>
                      K{payslipForMonth?.payslipmonthnhima}
                    </Text>
                    <Text style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10
                    }}>
                      K{payslipForMonth?.payslipytdnhima}
                    </Text>
                  </View>
                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                  }}>
                    <Text style={{
                      width: '33%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10,
                      paddingLeft: 10
                    }}>
                      Other
                    </Text>
                  </View>

                  {payslipForMonth.payslipmonthcustomdeductions && payslipForMonth.payslipmonthcustomdeductions.map((deduction, index) => (


                    <View style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginBottom: 5,
                    }}>
                      <Text style={{
                        width: '60%', // Adjust the width as necessary
                        justifyContent: 'center',
                        fontSize: 10,
                        paddingLeft: 20
                      }}>
                        {"• "}{findDeductionTypeNameById(deduction.deductionType)} - {findServiceProviderNameById(deduction.deductionServiceProvider)}
                      </Text>
                      <Text style={{
                        width: '20%', // Adjust the width as necessary
                        justifyContent: 'center',
                        fontSize: 10,
                      }}>
                        K{deduction.deductionAmount}
                      </Text>
                      <Text style={{
                        width: '20%', // Adjust the width as necessary
                        justifyContent: 'center',
                        fontSize: 10,
                      }}>

                      </Text>
                    </View>

                    // <View key={index} style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
                    //   <Text style={{ width: '66%', fontSize: 10, textAlign: 'left', paddingLeft: 20 }}>
                    //     •{findDeductionTypeNameById(deduction.deductionType)} - {findServiceProviderNameById(deduction.deductionServiceProvider)}: K{deduction.deductionAmount}
                    //   </Text>
                    // </View>
                  ))}

                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                  }}>
                    <View style={{
                      width: '60%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10,
                      paddingLeft: 10
                    }}>
                      <Text style={{ fontSize: 10 }}>
                        Total Deductions
                      </Text>
                    </View>
                    <View style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10
                    }}>
                      <Text style={{ fontSize: 12 }}>
                        K{payslipForMonth?.payslipmonthtotaldeductions}
                      </Text>
                    </View>
                    <View style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10
                    }}>
                      <Text style={{ fontSize: 12 }}>
                        K{payslipForMonth?.payslipytdtotaldeductions}
                      </Text>
                    </View>
                  </View>

                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                  }}>
                    <View style={{
                      width: '60%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 12
                    }}>
                      <Text
                        style={{
                          width: '100%',
                          backgroundColor: '#ccc',
                          paddingLeft: 10
                        }}
                      >
                        NET PAY
                      </Text>
                    </View>
                    <View style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 12
                    }}>
                      <Text
                        style={{
                          width: '100%',
                          backgroundColor: '#ccc',
                        }}
                      >
                        K{payslipForMonth?.payslipmonthnetpay}
                      </Text>
                    </View>
                    <View style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 12
                    }}>
                      <Text style={{ fontSize: 12 }}>
                        K{payslipForMonth?.payslipytdnetpay}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={{
                  width: '35%',
                }}>
                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 0,
                  }}>
                    <Text style={{
                      fontSize: 12, // This represents 'subtitle1'. Adjust the size as needed.
                      marginBottom: 4, // For spacing
                    }}>
                      OTHER INFORMATION
                    </Text>
                  </View>

                  {/* Repeated structure for each item, with inline styles */}
                  <View style={{ alignSelf: 'stretch', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                    <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                      Gratuity % Rate
                    </Text>
                    <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                      {`${payslipForMonth.payslipgratuity}%`}
                    </Text>
                  </View>

                  <View style={{ alignSelf: 'stretch', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                    <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                      YTD Accrued Gratuity*
                    </Text>
                    <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                      TBD{/* {`K${payslipForMonth.payslipytdaccruedgratuity}`} */}
                    </Text>
                  </View>

                  <View style={{ alignSelf: 'stretch', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                    <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                      Contract End Date
                    </Text>
                    <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                      {payslipForMonth.payslipcontractend}
                    </Text>
                  </View>

                  <View style={{ alignSelf: 'stretch', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10, marginBottom: 10 }}>
                    <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                      Projected Payout at Maturity*
                    </Text>
                    <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                      TBD{/* {`K${payslipForMonth.payslipprojectedpayout}`} */}
                    </Text>
                  </View>

                  <View style={{ alignSelf: 'stretch', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
                    <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                      * Estimated amounts only
                    </Text>
                  </View>

                  {/* For sections that require a distinct background or layout */}
                  <View style={{
                    backgroundColor: '#f9f9f9', // Example background color
                    padding: 10, // Inner spacing
                    borderRadius: 5, // Rounded corners
                    marginBottom: 5,
                    border: "1px solid #ccc",// Space between this and the next section
                  }}>
                    <Text style={{
                      fontSize: 12,
                      fontWeight: 'bold',
                      marginBottom: 4,
                    }}>
                      Salary Deposit Details
                    </Text>
                    <Text style={{ fontSize: 10 }}>
                      {"Employee Bank: "}
                      <Text style={{ fontWeight: 'normal' }}>
                        {payslipForMonth.payslipbank}
                      </Text>
                    </Text>

                    <Text style={{ fontSize: 10 }}>
                      {"Account Number: "}
                      <Text style={{ fontWeight: 'normal' }}>
                        {payslipForMonth.payslipaccount}
                      </Text>
                    </Text>

                    <Text style={{ fontSize: 10 }}>
                      {"Branch Location: "}
                      <Text style={{ fontWeight: 'normal' }}>
                        {payslipForMonth.paysliplocation}
                      </Text>
                    </Text>
                  </View>
                  <View style={{
                    flexDirection: 'row', // Aligns children (Text and Image) in a row.
                    alignItems: 'center', // Centers items vertically in the container.
                    justifyContent: 'center', // Centers the container's content horizontally.
                    marginTop: 10,
                  }}>
                    <Text style={{
                      fontSize: 10,
                      marginRight: 3, // Adds some space between the text and the image.
                    }}>
                      KOZO Pay by
                    </Text>
                    <Image
                      style={{ width: 20, height: 20 }}
                      src={tri8logo}
                    />
                  </View>
                </View>
              </View>
              <View style={{
                textAlign: "center",
                marginTop: "10px",
                fontSize: 10
              }}>
                <Text >
                  If you notice an error on the payslip - please contact management
                </Text>

              </View>
            </View>
          </View>
        </Page>
      </Document>
    );
  };

  return (
    <div className="parentaccesspage">
      <Dialog open={openLoadDialog} keepMounted>
        <DialogContent>
          <CircularProgress></CircularProgress>
        </DialogContent>
      </Dialog>
      <Dialog open={openPayroll} onClose={() => {
        setIsSelectingMonth(true)
        setOpenPayrollModal(false)
      }
      }>
        {isSelectingMonth ? (
          <>
            <DialogTitle>Select Payroll Period</DialogTitle>
            <DialogContent>
              <FormControl fullWidth>
                <InputLabel id="payroll-month-select-label">Month</InputLabel>
                <Select
                  labelId="payroll-month-select-label"
                  id="payroll-month-select"
                  value={selectedPayrollMonth}
                  label="Month"
                  onChange={handlePayrollMonthChange}
                >
                  {payrollMonths.map((month) => (
                    <MenuItem key={month} value={month}>
                      {month}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
          </>
        ) : (
          <>

            {/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "90vh" }}> */}
            <PDFViewer style={{ height: 700, maxHeight: "100vh", maxWidth: "100vw", width: 600 }}>
              <PayrollDocument employee={student} selectedMonth={selectedPayrollMonth} />
            </PDFViewer>
            <PDFDownloadLink
              document={
                <PayrollDocument employee={student} selectedMonth={selectedPayrollMonth} />
              }
              fileName="mock_payroll.pdf"
              style={{ maxWidth: 172, display: "flex", alignSelf: "center", marginTop: 20 }} // Added marginTop for spacing
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  "Loading document..."
                ) : (
                  <Button
                    startIcon={<DownloadIcon />}
                    variant="contained"
                    color="success"
                    size="small"
                  >
                    Download Payslip
                  </Button>

                )
              }
            </PDFDownloadLink>
            {/* </div> */}



          </>
        )}
      </Dialog>
      <Grid
        item
        className="row "
        style={{
          float: "right",
        }}
        xs={3}
      >
        <Typography
          style={{
            float: "right",
            textAlign: "left",
            marginTop: 80,
            fontSize: 16,
            marginRight: 50,
          }}
        >
          This session will expire in: {minutes}:{seconds}
        </Typography>
      </Grid>
      <Grid item className="row parentaccessheader" xs={3}>
        <Typography
          style={{
            marginTop: 100,
          }}
          variant="h4"
        >
          Welcome to KOZO
        </Typography>
        <Typography variant="h5" style={{}}>
          Employee Access
        </Typography>
      </Grid>

      <Divider
        style={{ marginLeft: 40, marginRight: 40, color: "#1565C0" }}
        sx={{ borderBottomWidth: 3, my: 1.5 }}
      />

      <div className="row">
        <div className="col" style={{ width: 400, maxWidth: 400 }}>
          <Grid item className=" parentaccessheader" xs={3}>
            <Typography
              style={{
                fontSize: 16,
              }}
            >
              Access payroll information
            </Typography>
          </Grid>
        </div>
        <div className="col" style={{ width: 700, maxWidth: 1000 }}>
          <Grid item className="col" xs={7}>
            <Typography
              style={{
                textAlign: "left",
                marginLeft: 10,
                marginTop: 10,
              }}
              variant="h6"
            >
              Payslips
            </Typography>
            <Typography
              style={{
                textAlign: "left",
                marginLeft: 10,
                marginTop: 0,
              }}
              variant="h6"
            >
              for {student?.firstname + " " + student?.lastname}
            </Typography>
            <div
              style={{
                border: "1px solid black",
                borderRadius: 15,
                height: "500px", // Set a fixed height
                overflowY: "auto", // Enable vertical scrolling
                paddingTop: 3,
                paddingRight: 10,
                marginTop: 3,
              }}
            >
              <Grid item xs={12} alignItems="start">
                <Button
                  variant="contained"
                  size="large"
                  startIcon={<SummarizeIcon />}
                  onClick={setopenPayroll} // Adjust onClick handler as needed
                >
                  View Payslip
                </Button>
              </Grid>

              <div
                style={{
                  alignContent: "right",
                }}
              >




              </div>
            </div>
          </Grid>
        </div>

      </div>
    </div>
  );
}

export default EmployeeAccess;
