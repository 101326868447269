import React from "react";
import "./App.css";
import Navbar from "./components/navbar/navbar";
import Sidebar from "./components/navbar/sideBar";
import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./components/dashboard/dashboard";
import EmployeeList from "./components/contactlist/employeelist";
import StudentList from "./components/customer/studentListFunctional";
import GuardianList from "./components/parent/ParentList";
import SignIn from "./components/authentication/signin";
import ResetPassword from "./components/authentication/resetpassword";
import ParentSignIn from "./components/authentication/parentsignin";
import ParentAccess from "./components/customer/parentaccess";
import Settings from "./components/invoice/invoice";
import InvoiceGenerator from "./components/invoice/components/App";
import ConfirmPassword from "./components/invoice/confirmpassword";

import { useState, useEffect } from "react";
import { Amplify, Auth, Hub } from "aws-amplify";
import { useNavigate } from "react-router";
import "@aws-amplify/ui-react/styles.css";

import awsExports from "./aws-exports";
import Managefee from "./components/invoice/managefee";
import NewRegistration from "./components/invoice/newRegistration";
import ManageEnrolments from "./components/invoice/manageEnrolments";
import {
  getAllEmployees,
  getAllGuardians,
  getAllStudents,
  getSchools,
  getAllowanceTypes,
  getDeductionTypes,
  getServiceProviders,
} from "./util/api-calls";
import { Guardian, Student } from "./util/data-models";
import { Employee } from "./components/contactlist/newcontactlist";
import EmployeeSignIn from "./components/authentication/employeesignin";
import EmployeeAccess from "./components/customer/employeeaccess";
import payroll from "./components/payroll/payroll";
Amplify.configure(awsExports);

function App() {
  const [user, updateUser] = useState(null);
  const [userName, updateUserName] = useState("");


  const [serviceProviders, setServiceProviders] = useState(null);
  const [deductionTypes, setDeductionTypes] = useState(null);
  const [allowanceTypes, setAllowanceTypes] = useState(null);

  const [employeeaccess, setEmployeeaccess] = useState(null);
  const [parentaccessStudent, setParentaccessStudent] = useState(null);
  const [updateStudentPage, setUpdateStudentPage] = useState(false);
  const [updateParentPage, setUpdateParentPage] = useState(false);

  const [studentsList, setStudents] = useState({} as Student);
  const [employeesList, setEmployeess] = useState({} as Employee);
  const [parentsList, setParents] = useState({} as Guardian);
  const [school, setSchool] = useState({});
  const [schoolFromSignin, setSchoolFromSignin] = useState("Ebenezer Private School"); //"Ebenezer Schools - Mazabuka"

  useEffect(() => {
    checkUser();
    setAuthListener();
  }, []);

  useEffect(() => {
    async function setDropdown() {
      if (userName != "") {
        let schoolInfo = ""
        await getAllStudents().then((students) => {
          setStudents(students);
        });
        await getAllEmployees().then((employees) => {
          setEmployeess(employees);
        });
        await getAllGuardians().then((parents) => {
          setParents(parents);
        });
        await getSchools().then((schools) => {
          schoolInfo = findSchoolByName(schools)
          setSchool(schoolInfo);
        });
        await getServiceProviders().then((serviceProviders) => {
          const filteredServiceProviders = serviceProviders.filter(sp => sp.clientid === schoolInfo?.clientid);
          setServiceProviders(filteredServiceProviders);
        });
        await getDeductionTypes().then((deductionTypes) => {
          const filteredDeductionTypes = deductionTypes.filter(dt => dt.clientid === schoolInfo?.clientid);
          setDeductionTypes(filteredDeductionTypes);

        });
        await getAllowanceTypes().then((allowanceTypes) => {
          const filteredAllowanceTypes = allowanceTypes.filter(dt => dt.clientid === schoolInfo?.clientid);
          setAllowanceTypes(filteredAllowanceTypes);
        });
      }
    }
    setDropdown();
  }, [userName]);



  function findSchoolByName(schools) {
    return schools.find(school => {
      if (school.clientname === schoolFromSignin) {
        return school
      }
    })
  }

  const navigate = useNavigate();

  async function setAuthListener() {
    Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn":
          break;
        case "signOut":
          navigate("/signin");
          break;
        default:
      }
    });
  }

  async function checkUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      updateUser(user);

      if (user.signInUserSession.idToken.payload["cognito:groups"]) {
        if (
          user.signInUserSession.idToken.payload["cognito:groups"][0] ==
          "StudentParent"
        ) {
          if (window.location.pathname == "/dashboard") {
            window.location.href = "/students";
          } else if (window.location.pathname == "/employees") {
            window.location.href = "/students";
          } else if (window.location.pathname == "/settings") {
            window.location.href = "/students";
          }
        }
      }

      if (window.location.pathname == "/signin") {
        window.location.href = "/dashboard";
      } else if (window.location.pathname == "/parent-access") {
        navigate("/parent-signin");
      }
      if (user.pool.userPoolId === "ca-central-1_TxjoIawIu") {
        updateUserName(user.attributes.email);
      } else {
        updateUserName(user.username);
      }
    } catch (err) {
      if (window.location.pathname == "/parent-access") {
        navigate("/parent-signin");
      } else if (window.location.pathname != "/parent-signin" && window.location.pathname != "/resetpassword") {
        navigate("/signin");
      }
    }
  }

  return (
    <React.Fragment>
      <div className="App">
        <React.Fragment>
          <Navbar userName={userName} school={school} />

          {Sidebar(userName)}
          <div className="main">
            <Routes>
              <Route path={"/"} element={<Navigate to={"signin"} />} />

              <Route path={"/signin"} element={SignIn(updateUserName)} />
              <Route path={"/resetpassword"} element={ResetPassword(updateUserName)} />
              <Route
                path={"/parent-signin"}
                element={ParentSignIn(setParentaccessStudent)}
              />
              <Route
                path={"/employee-signin"}
                element={EmployeeSignIn(setEmployeeaccess)}
              />
              <Route
                path={"/parent-access"}
                element={ParentAccess(parentaccessStudent)}
              />
              <Route
                path={"/employee-access"}
                element={EmployeeAccess(employeeaccess, school, serviceProviders, deductionTypes, allowanceTypes)}
              />

              <Route
                path={"/dashboard"}
                element={Dashboard(
                  userName,
                  studentsList,
                  employeesList,
                  parentsList
                )}
              />

              <Route
                path={"/employees"}
                element={EmployeeList(userName, employeesList, school, serviceProviders, deductionTypes, allowanceTypes)}
              />

              <Route
                path={"/students"}
                element={StudentList(
                  employeesList,
                  studentsList,
                  setUpdateParentPage,
                  updateParentPage,
                  updateStudentPage,
                  userName
                )}
              />
              <Route
                path={"/parents"}
                element={GuardianList(
                  parentsList,
                  studentsList,
                  setUpdateStudentPage,
                  updateStudentPage,
                  updateParentPage,
                  userName
                )}
              />
              <Route
                path={"/payroll"}
                element={payroll(userName, employeesList, school, serviceProviders, deductionTypes, allowanceTypes)}
              />

              <Route
                path={"/settings"}
                element={Settings(userName, employeesList)}
              />
              <Route path={"/confirmpassword"} element={<ConfirmPassword />} />
              <Route path={"/settings/managefee"} element={<Managefee key={userName} userName={userName} />} />
              <Route
                path={"/settings/manage-enrolments"}
                element={<ManageEnrolments />}
              />
              <Route
                path={"/settings/newinvoice"}
                element={<InvoiceGenerator />}
              />
              <Route
                path={"/settings/newregistration"}
                element={<NewRegistration />}
              />
            </Routes>
          </div>
        </React.Fragment>
      </div>
    </React.Fragment>
  );
}

export default React.memo(App);
