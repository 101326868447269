import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Avatar,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Divider,
  OutlinedInput,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  CircularProgress,
  List,
  ListItemText,
  Menu,
  ListItem,
  ListItemSecondaryAction,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import BadgeTwoToneIcon from "@mui/icons-material/BadgeTwoTone";
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridColumnHeaderParams,
  GridEventListener,
  GridRenderCellParams,
  GridRowParams,
  MuiEvent,
} from "@mui/x-data-grid";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { NavigateFunction, useNavigate } from "react-router";
import {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  Allowance,
  JobTitle,
  languageList,
  Status,
} from "../../util/data-models";
import { Employee } from "./newcontactlist";
import { deleteEmployee, getAllEmployees, getAllowanceTypes, getDeductionTypes, getSchools, getServiceProviders } from "../../util/api-calls";
import React from "react";
import NumberFormat from "react-number-format";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import { StepIconProps } from "@mui/material/StepIcon";
import PersonIcon from "@mui/icons-material/Person";
import SavingsIcon from "@mui/icons-material/Savings";
import BoltIcon from "@mui/icons-material/Bolt";
import MainCard from "../dashboard/ui-component/cards/MainCard";
import { styled } from "@mui/material/styles";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import NumbersIcon from "@mui/icons-material/Numbers";
import CloseIcon from "@mui/icons-material/Close";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import CancelIcon from "@mui/icons-material/Cancel";
import DepartureBoardIcon from "@mui/icons-material/DepartureBoard";
import TableRowsIcon from "@mui/icons-material/TableRows";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Child, EmployeeLeave } from "../../util/data-models";
import ViewDocumentLink from "../viewcontact/documentlink";
import ReactCountryFlag from "react-country-flag";
import EditChildren from "../editcontact/editchildren";
import EditAllowance from "../editcontact/editallowance";
import EditStatus from "../editcontact/editstatus";
import EditLeave from "../editcontact/editleave";
import { getS3URL, uploadToS3Bucket } from "../../util/s3-utils";
import EditContract from "../editcontact/contracthandler";
import PermissionsGate from "../../util/perminssion-gate";
import { SCOPES } from "../../util/permission-map";
import { TransitionProps } from "@mui/material/transitions";
import ArticleIcon from "@mui/icons-material/Article";
import InvoiceModal from "../editcontact/invoicemodal";
import GenerateEmployeeContract from "../editcontact/generateEmployeeContract";
import { PictureAsPdf } from "@mui/icons-material";
import StudentList from "../customer/studentListFunctional";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { PDFViewer, Document, Page, Text, View, StyleSheet, Image, PDFDownloadLink } from '@react-pdf/renderer';
import DownloadIcon from "@mui/icons-material/Download";

import tri8logo from "../../assets/img/images/tri8logo.png";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const options = ["ACTIVE", "INACTIVE", "ALL"];

const CardWrapper = styled(MainCard)(({ theme }) => ({
  overflow: "hidden",
  color: "#fff",
  position: "relative",
  backgroundColor: "#5E35B1",
  borderRadius: "10px",
  "&>div": {
    position: "relative",
    zIndex: 5,
  },
  "&:after": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    backgroundColor: "#4526a0",
    borderRadius: "50%",
    top: -85,
    right: -95,
    [theme.breakpoints.down("sm")]: {
      top: -105,
      right: -140,
    },
  },
  "&:before": {
    content: '""',
    position: "absolute",
    width: 210,
    height: 210,
    backgroundColor: "#4A2A9A",
    borderRadius: "50%",
    top: -125,
    right: -15,
    opacity: 0.5,
    [theme.breakpoints.down("sm")]: {
      top: -155,
      right: -70,
    },
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 35,
  height: 35,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",

  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, #4A2A9A 0%, #4A2A9A 50%, #4A2A9A 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <PersonIcon />,
    2: <SavingsIcon />,
    3: <DepartureBoardIcon />,
    4: <BoltIcon />,
  };
  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const DetailRow: React.FC<{ title: string; value: string }> = ({
  title,
  value,
}) => (
  <div className="col">
    <Typography style={{ fontSize: 10, color: "#878787" }}>{title}</Typography>
    <Typography style={{ fontSize: 13 }}>{value}</Typography>
  </div>
);

function EmployeeList(userName: string, employeesList, school, serviceProviders, deductionTypes, allowanceTypes) {
  const [loadingGrid, setLoadingGrid] = useState(true);
  const [search, setSearch] = useState(``);
  const [employeeFilter, setEmployeeFilter] = useState("ACTIVE");
  const [activeStep, setStep] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLoadingproviders, setLoadingproviders] = useState(true);


  const [list, setList] = useState([] as Employee[]);
  const [bulkList, setBulkList] = useState([] as Employee[]);

  const [selectedEmployee, setEmployee] = useState({} as Employee);
  const [editingEmployee, setEditingEmployeet] = useState({} as Employee);
  const [addingEmployee, setAddingEmployee] = useState({} as Employee);

  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isBulkEditing, setIsBulkEditing] = useState(false);
  const [isViewingTable, setIsViewingTable] = useState(false);

  const [openPayroll, setOpenPayrollModal] = useState(false);
  const [openDeductions, setOpenDeductionsModal] = useState(false);
  const [openAllowances, setOpenAllowancesModal] = useState(false);
  const [isSelectingMonth, setIsSelectingMonth] = React.useState(true);
  const [selectedPayrollMonth, setSelectedPayrollMonth] = useState("");
  const [payrollMonths, setPayrollMonths] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedFilterIndex, setSelectedFilterIndex] = React.useState(0);
  const open = Boolean(anchorEl);

  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set());

  const [deductions, setDeductions] = useState(selectedEmployee?.deduction || []);
  const [deductionType, setDeductionType] = useState(null); // null, 'oneTime', 'recurring'
  const [isEditngDeductions, setIsEditngDeductions] = useState(false);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const [isConfirmingDeleteIndex, setIsConfirmingDeleteIndex] = useState(null);
  const [isConfirmingDeleteIndexAllowance, setIsConfirmingDeleteIndexAllowance] = useState(null);

  const [allowances, setAllowances] = useState(selectedEmployee?.allowances || []);
  const [allowanceType, setAllowanceType] = useState(null); // null, 'oneTime', 'recurring'
  const [isEditngAllowances, setIsEditngAllowances] = useState(false);
  const [isConfirmingDeleteAllowance, setIsConfirmingDeleteAllowance] = useState(false);

  const [isEditingSingleDeduction, setIsEditingSingleDeduction] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);



  const [isEditingSingleAllowance, setIsEditingSingleAllowance] = useState(false);
  const [editingIndexAllowance, setEditingIndexAllowance] = useState(null);


  const [nextMonth, setNextMonth] = useState(null);
  const [monthlyDeductionAmount, setMonthlyDeductionAmount] = useState('');
  const [monthlyAllowanceAmount, setMonthlyAllowanceAmount] = useState('');




  const [form, setForm] = useState({
    deductiontype: '',
    deductionserviceprovider: '',
    deductionamount: '',
    deductionstartmonth: '',
    deductionendmonth: '',
    deductionemployeeid: '',
  });

  const [allowanceForm, setAllowanceForm] = useState({
    allowancetype: '',
    allowanceamount: '',
    allowancestartmonth: '',
    allowanceendmonth: '',
    allowanceemployeeid: '',
  });

  const toggleRow = (index: number) => {
    const newExpandedRows = new Set(expandedRows);
    if (newExpandedRows.has(index)) {
      newExpandedRows.delete(index);
    } else {
      newExpandedRows.add(index);
    }
    setExpandedRows(newExpandedRows);
  };
  function formatDate(dateStr: string): string {
    const date = new Date(dateStr);
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    let formattedDate = date.toLocaleDateString('en-US', options);

    // Function to add ordinal suffix
    function getOrdinal(n: number): string {
      return n + (["st", "nd", "rd"][((n + 90) % 100 - 10) % 10 - 1] || "th");
    }

    // Replace the day number with its ordinal counterpart
    const day = date.getDate();
    formattedDate = formattedDate.replace(day.toString(), getOrdinal(day));

    return formattedDate;
  }

  const navigate = useNavigate();

  const handlePayrollMonthChange = (event) => {
    setSelectedPayrollMonth(event.target.value);
    setIsSelectingMonth(false); // Hide month selection and show the PDF
  };

  const payrollArray = [
    {
      school: "Ebenezer Schools - Mazabuka",
      date: new Date("01/01/2024"),
      salary: "4000",
      allowances: "800",
      ytd: 8000,
      ytdAllowances: 1600,
      ytdOther: 0,
      other: 0,
      napsa: 80,
      nhima: 20,
      paye: 100,
      ytdNapsa: 160,
      ytdNhima: 40,
      gratuity: 25,
      accruedGratuity: 2000,
      contractEndDate: new Date("01/01/2024"),
      projectedPayout: 24000,
    },
    {
      school: "Ebenezer Schools - Mazabuka",
      date: new Date("01/01/2024"),
      salary: "4000",
      allowances: "800",
      ytd: 8000,
      ytdAllowances: 1600,
      ytdOther: 0,
      other: 0,
      napsa: 80,
      nhima: 40,
      paye: 100,
      ytdNapsa: 160,
      ytdNhima: 20,
      gratuity: 25,
      accruedGratuity: 2000,
      contractEndDate: new Date("01/01/2024"),
      projectedPayout: 24000,
    },
  ];

  const filterEmployees = (employees: Employee[], searchTerm: string) => {
    return employees.filter((employee: Employee) => {
      let fullName = (
        employee.firstname +
        " " +
        employee.lastname
      ).toLowerCase();

      return fullName.includes(searchTerm?.toLowerCase() ?? "");
    });
  };

  const getMostRecentStatus = (statuses: Status[]) => {
    return statuses?.sort(
      (a, b) =>
        new Date(b.statusdate).getTime() - new Date(a.statusdate).getTime()
    )[0];
  };

  const filterByStatus = (list: Employee[], status: string) => {
    let employees = [...list];
    if (status == "INACTIVE") {
      employees = employees.filter(
        (emp) =>
          emp?.status?.length >= 1 &&
          !getMostRecentStatus(emp.status)?.status.includes("Probation") &&
          !getMostRecentStatus(emp.status)?.status.includes("Active")
      );
    } else if (status == "ACTIVE") {
      employees = employees.filter(
        (emp) =>
          (emp?.status?.length >= 1 &&
            getMostRecentStatus(emp.status)?.status.includes("Probation")) ||
          getMostRecentStatus(emp.status)?.status.includes("Active")
      );
    }
    return employees;
  };

  useEffect(() => {
    setDeductions(selectedEmployee?.deduction || [])
    setAllowances(selectedEmployee?.allowances || [])
  }, [selectedEmployee]);


    useEffect(() => {
    if(serviceProviders && deductionTypes && allowanceTypes){
	monthsSinceJanuary2024();
      setLoadingproviders(false);
    }
  }, [school, serviceProviders, deductionTypes, allowanceTypes]);

  useEffect(() => {
    setLoadingGrid(true);
    // getAllEmployees().then((employees) => {

    if (employeesList.length > 0) {
      const newList = [...filterByStatus(employeesList, "ACTIVE")];
      setList(filterEmployees(newList, search));
      setLoadingGrid(false);
      setEmployee(newList[0]);
      // });
    }
  }, [employeesList]);


  useEffect(() => {
    if (employeesList && employeesList.length > 0) {
      const extractPayrollMonths = () => {
        const uniqueMonths = new Set(); // Use a Set to ensure uniqueness

        employeesList.forEach(employee => {
          employee.payslips.forEach(payslip => {
            if (payslip.status === "2") {
              uniqueMonths.add(payslip.payslipperiod); // Add to Set if status is "2"
            }
          });
        });

        return Array.from(uniqueMonths); // Convert Set back to Array
      };

      const payrollMonths = extractPayrollMonths();
      setPayrollMonths(payrollMonths); // Update state
    }
  }, [employeesList]); // Rerun effect if employeesList changes

  useEffect(() => {
    setLoadingGrid(true);
    // getAllEmployees().then((employees) => {
    if (employeesList.length > 0) {
      const newList = [...filterByStatus(employeesList, "ACTIVE")];
      setList(filterEmployees(newList, search));
      setLoadingGrid(false);
      // });
    }
  }, [search, employeesList]);

  useEffect(() => {
    if (form.deductionstartmonth && form.deductionendmonth && form.deductionamount && (form.deductionstartmonth !== form.deductionendmonth)) {
      const start = new Date(form.deductionstartmonth + '-01'); // Assuming deduction month format is YYYY-MM
      const end = new Date(form.deductionendmonth + '-01');
      const monthsDifference = (end.getFullYear() - start.getFullYear()) * 12 + end.getMonth() - start.getMonth() + 1; // +1 to include both start and end months
      // Ensure there's at least one month difference and the total amount is a positive number
      if (monthsDifference > 0 && !isNaN(form.deductionamount) && Number(form.deductionamount) > 0) {
        const monthlyAmount = Number(form.deductionamount) / monthsDifference;
        setMonthlyDeductionAmount(monthlyAmount.toFixed(2)); // Set calculated monthly amount
      } else {
        setMonthlyDeductionAmount(''); // Reset if conditions are not met
      }
    } else {
      setMonthlyDeductionAmount(''); // Reset if not a recurring deduction
    }
  }, [form.deductionstartmonth, form.deductionendmonth, form.deductionamount, deductionType, isEditingSingleDeduction]);

  useEffect(() => {
    if (allowanceForm.allowancestartmonth && allowanceForm.allowanceamount &&
      allowanceForm.allowanceamount && (allowanceForm.allowancestartmonth !== allowanceForm.allowanceendmonth)) {
      const start = new Date(allowanceForm.allowancestartmonth + '-01'); // Assuming deduction month format is YYYY-MM
      const end = new Date(allowanceForm.allowanceendmonth + '-01');
      const monthsDifference = (end.getFullYear() - start.getFullYear()) * 12 + end.getMonth() - start.getMonth() + 1; // +1 to include both start and end months
      // Ensure there's at least one month difference and the total amount is a positive number
      if (monthsDifference > 0 && !isNaN(allowanceForm.allowanceamount) && Number(allowanceForm.allowanceamount) > 0) {
        const monthlyAmount = Number(allowanceForm.allowanceamount) / monthsDifference;
        setMonthlyAllowanceAmount(monthlyAmount.toFixed(2)); // Set calculated monthly amount
      } else {
        setMonthlyAllowanceAmount(''); // Reset if conditions are not met
      }
    } else {
      setMonthlyAllowanceAmount(''); // Reset if not a recurring deduction
    }
  }, [allowanceForm.allowancestartmonth, allowanceForm.allowanceendmonth, allowanceForm.allowanceamount, allowanceType, isEditingSingleAllowance]);




  const handleFormChange = (event) => {
    const { name, value } = event.target;

    // Automatically set end time to start time for one-time deductions
    if (deductionType === 'oneTime' && name === 'deductionstartmonth') {
      setForm((prevForm) => ({
        ...prevForm,
        [name]: value,
        deductionendmonth: value, // Set end time to start time
      }));
    } else {
      setForm((prevForm) => ({ ...prevForm, [name]: value }));
    }
  };

  const handleFormChangeAllowance = (event) => {
    const { name, value } = event.target;

    // Automatically set end time to start time for one-time deductions
    if (allowanceType === 'oneTime' && name === 'allowancestartmonth') {
      setAllowanceForm((prevForm) => ({
        ...prevForm,
        [name]: value,
        allowanceendmonth: value, // Set end time to start time
      }));
    } else {
      setAllowanceForm((prevForm) => ({ ...prevForm, [name]: value }));
    }
  };

  const startEditDeduction = (index) => {
    const deduction = deductions[index];
    setForm({ ...deduction });
    setIsEditingSingleDeduction(true);
    setEditingIndex(index); // Keep track of which deduction is being edited
  };

  const startEditAllowance = (index) => {
    const allowance = allowances[index];
    setAllowanceForm({ ...allowance });
    setIsEditingSingleAllowance(true);
    setEditingIndexAllowance(index); // Keep track of which deduction is being edited
  };

  const resetForm = () => {
    setForm({
      deductiontype: '',
      deductionserviceprovider: '',
      deductionamount: '',
      deductionstartmonth: '',
      deductionendmonth: '',
    });
    setIsEditingSingleDeduction(false);
    setEditingIndex(null);
  };

  const resetFormAllowance = () => {
    setForm({
      allowancetype: '',
      allowanceamount: '',
      allowancestartmonth: '',
      allowanceendmonth: '',
    });
    setIsEditingSingleAllowance(false);
    setEditingIndexAllowance(null);
  };

  const addDeduction = (e) => {
    e.preventDefault(); // Prevent the form from submitting traditionally
    setIsEditngDeductions(true)
    const newDeduction = {
      ...form,
      deductionemployeeid: selectedEmployee.employeeid,
      deductionstartmonth: deductionType === 'oneTime' ? nextMonth : form.deductionstartmonth,
      deductionendmonth: deductionType === 'oneTime' ? nextMonth : form.deductionendmonth,
    };

    if (!Array.isArray(selectedEmployee.deduction)) {
      selectedEmployee.deduction = [];
    }

    selectedEmployee.deduction = [...selectedEmployee.deduction, newDeduction];
    setForm({
      deductiontype: '',
      deductionserviceprovider: '',
      deductionamount: '',
      deductionstartmonth: '',
      deductionendmonth: '',
      deductionemployeeid: '',
    });
    setDeductionType(null);

    handleOpenLoadDialog();
    selectedEmployee.updatedAt = new Date().toISOString();
    selectedEmployee.updatedBy = userName;

    const requestOptions = {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(selectedEmployee),
    };

    fetch("https://school.kozosms.com/api/api/employees/" + selectedEmployee.id, requestOptions)
      .then((response) => response.json())
      .then((_emps) => {
        return getAllEmployees();
      })
      .then((employees) => {
        let index = employees.findIndex((object) => object.id === selectedEmployee.id);
        setSearch("");
        setList(employees);
        setEmployee(employees[index]); // Update the local state to reflect the changes
        closeLoadDialog();
        setSnackbarOpen(true); // Assuming this shows a confirmation message to the user
        setIsEditing(false); // Assuming this toggles edit mode off
        setIsEditngDeductions(false)
        resetForm()
      }).catch((error) => {
        console.error('Failed to update employee deductions:', error);
        setIsEditngDeductions(false);
      });
  };

  const addAllowance = (e) => {
    e.preventDefault(); // Prevent the form from submitting traditionally
    setIsEditngAllowances(true)
    const newAllowance = {
      ...allowanceForm,
      allowanceemployeeid: selectedEmployee.employeeid,
      allowancestartmonth: allowanceType === 'oneTime' ? nextMonth : allowanceForm.allowancestartmonth,
      allowanceendmonth: allowanceType === 'oneTime' ? nextMonth : allowanceForm.allowanceendmonth,
    };

    if (!Array.isArray(selectedEmployee.allowances)) {
      selectedEmployee.allowances = [];
    }

    selectedEmployee.allowances = [...selectedEmployee.allowances, newAllowance];
    setAllowanceForm({
      allowancetype: '',
      allowanceamount: '',
      allowancestartmonth: '',
      allowanceendmonth: '',
      allowanceemployeeid: '',
    });
    setAllowanceType(null);

    handleOpenLoadDialog();
    selectedEmployee.updatedAt = new Date().toISOString();
    selectedEmployee.updatedBy = userName;

    const requestOptions = {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(selectedEmployee),
    };

    fetch("https://school.kozosms.com/api/api/employees/" + selectedEmployee.id, requestOptions)
      .then((response) => response.json())
      .then((_emps) => {
        return getAllEmployees();
      })
      .then((employees) => {
        let index = employees.findIndex((object) => object.id === selectedEmployee.id);
        setSearch("");
        setList(employees);
        setEmployee(employees[index]); // Update the local state to reflect the changes
        closeLoadDialog();
        setSnackbarOpen(true); // Assuming this shows a confirmation message to the user
        setIsEditing(false); // Assuming this toggles edit mode off
        setIsEditngAllowances(false)
        resetFormAllowance()
      }).catch((error) => {
        console.error('Failed to update employee allowances:', error);
        setIsEditngAllowances(false);
      });
  };

  const handleEditDeductionSubmit = async (index) => {
    setIsEditngDeductions(true);
    const updatedDeductions = [...selectedEmployee.deduction];
    updatedDeductions[index] = {
      ...updatedDeductions[index],
      ...form, // Assuming 'form' holds the updated deduction data
      deductionstartmonth: form.deductiontype === 'oneTime' ? nextMonth : form.deductionstartmonth,
      deductionendmonth: form.deductiontype === 'oneTime' ? nextMonth : form.deductionendmonth,
    };
    const updatedEmployee = {
      ...selectedEmployee,
      deduction: updatedDeductions,
    };
    const requestOptions = {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(updatedEmployee),
    };
    try {
      const response = await fetch(`https://school.kozosms.com/api/api/employees/${selectedEmployee.id}`, requestOptions)
        .then((response) => response.json())
        .then(() => {
          return getAllEmployees();
        })
        .then((employees) => {
          let index = employees.findIndex((object) => object.id === selectedEmployee.id);
          setIsEditingSingleDeduction(false);
          setSearch("");
          resetForm()
          setList(employees);
          setEmployee(employees[index]); // Update the local state to reflect the changes
          closeLoadDialog();
          setSnackbarOpen(true); // Assuming this shows a confirmation message to the user
          setIsEditing(false); // Assuming this toggles edit mode off
          setIsEditngDeductions(false);
          setSnackbarOpen(true);
        }).catch((error) => {
          console.error('Failed to update employee deductions:', error);
          setIsEditngDeductions(false);
        });
    } catch (error) {
      console.error('Failed to update employee deductions:', error);
      setIsEditngDeductions(false);
    }
  };

  const handleEditAllowanceSubmit = async (index) => {
    setIsEditngAllowances(true);
    const updatedAllowances = [...selectedEmployee.allowances];
    updatedAllowances[index] = {
      ...updatedAllowances[index],
      ...allowanceForm, // Assuming 'form' holds the updated deduction data
      allowancestartmonth: allowanceForm.allowancetype === 'oneTime' ? nextMonth : allowanceForm.allowancestartmonth,
      allowanceendmonth: allowanceForm.allowancetype === 'oneTime' ? nextMonth : allowanceForm.allowanceendmonth,
    };
    const updatedEmployee = {
      ...selectedEmployee,
      allowances: updatedAllowances,
    };
    const requestOptions = {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(updatedEmployee),
    };
    try {
      const response = await fetch(`https://school.kozosms.com/api/api/employees/${selectedEmployee.id}`, requestOptions)
        .then((response) => response.json())
        .then(() => {
          return getAllEmployees();
        })
        .then((employees) => {
          let index = employees.findIndex((object) => object.id === selectedEmployee.id);
          setIsEditingSingleAllowance(false);
          setSearch("");
          resetFormAllowance()
          setList(employees);
          setEmployee(employees[index]); // Update the local state to reflect the changes
          closeLoadDialog();
          setSnackbarOpen(true); // Assuming this shows a confirmation message to the user
          setIsEditing(false); // Assuming this toggles edit mode off
          setIsEditngAllowances(false);
          setSnackbarOpen(true);
        }).catch((error) => {
          console.error('Failed to update employee allowances:', error);
          setIsEditngAllowances(false);
        });
    } catch (error) {
      console.error('Failed to update employee allowances:', error);
      setIsEditngAllowances(false);
    }
  };

  const deleteDeduction = (index) => {
    setIsEditngDeductions(true);
    const updatedDeductions = selectedEmployee?.deduction.filter((_, i) => i !== index);
    selectedEmployee.deduction = updatedDeductions;

    handleOpenLoadDialog();
    const requestOptions = {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ deduction: updatedDeductions }),
    };

    fetch(`https://school.kozosms.com/api/api/employees/${selectedEmployee.id}`, requestOptions)
      .then((response) => response.json())
      .then(() => {
        return getAllEmployees();
      })
      .then((employees) => {
        let index = employees.findIndex((object) => object.id === selectedEmployee.id);
        setSearch("");
        setList(employees);
        setEmployee(employees[index]); // Update the local state to reflect the changes
        closeLoadDialog();
        setSnackbarOpen(true); // Assuming this shows a confirmation message to the user
        setIsEditing(false); // Assuming this toggles edit mode off
        setIsEditngDeductions(false);
        setSnackbarOpen(true);
        resetForm()
      }).catch((error) => {
        console.error('Failed to update employee deductions:', error);
        setIsEditngDeductions(false);
      });
  };

  const deleteAllowance = (index) => {
    setIsEditngAllowances(true);
    const updatedAllowances = selectedEmployee?.allowances.filter((_, i) => i !== index);
    selectedEmployee.allowances = updatedAllowances;

    handleOpenLoadDialog();
    const requestOptions = {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ allowances: updatedAllowances }),
    };

    fetch(`https://school.kozosms.com/api/api/employees/${selectedEmployee.id}`, requestOptions)
      .then((response) => response.json())
      .then(() => {
        return getAllEmployees();
      })
      .then((employees) => {
        let index = employees.findIndex((object) => object.id === selectedEmployee.id);
        setSearch("");
        setList(employees);
        setEmployee(employees[index]); // Update the local state to reflect the changes
        closeLoadDialog();
        setSnackbarOpen(true); // Assuming this shows a confirmation message to the user
        setIsEditing(false); // Assuming this toggles edit mode off
        setIsEditngAllowances(false);
        setSnackbarOpen(true);
        resetFormAllowance()
      }).catch((error) => {
        console.error('Failed to update employee allowances:', error);
        setIsEditngAllowances(false);
      });
  };


  const editDeduction = (index, newValues) => {
    const updatedDeductions = deductions.map((item, i) => i === index ? newValues : item);
    setDeductions(updatedDeductions);
    // Call your API to update the deductions in the backend
  };

  const addEmployeeClicked = () => {
    if (isAdding) {
      setIsAdding(false);
    } else {
      setIsAdding(true);
    }
    setStep(0);
  };

  const deleteHandler = (employeeId: number) => {
    deleteEmployee(employeeId)
      .then((emps) => {
        return getAllEmployees();
      })
      .then((employees) => {
        setList(employees);
        setEmployee({} as Employee);
        setAddingEmployee({} as Employee);
        setDeletePopUp(false);
      });
  };

  const steps = [
    "Personal Information",
    "Compensation & Benefits",
    "Leave Management",
    "Performance Management",
  ];

  function setopenPayroll() {
    if (openPayroll === true) {
      setOpenPayrollModal(false);
    } else {
      setOpenPayrollModal(true);
    }
  }

  function setopenDeductions() {
    if (openDeductions === true) {
      setOpenDeductionsModal(false);
    } else {
      setOpenDeductionsModal(true);
    }
  }

  function setopenAllowances() {
    if (openDeductions === true) {
      setOpenAllowancesModal(false);
    } else {
      setOpenAllowancesModal(true);
    }
  }

  const handleProgress = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    param: number
  ) => {
    if (isEditing) {
      if (activeStep === 0) {
        if (
          !editingEmployee.firstname ||
          !editingEmployee.lastname ||
          !editingEmployee.gender ||
          !editingEmployee.town ||
          !editingEmployee.number ||
          !editingEmployee.dob ||
          !editingEmployee.nrc ||
          !editingEmployee.address
        ) {
          handleOpenRequiredFieldsPopUp();
        } else {
          setStep(param);
        }
      } else if (activeStep === 1) {
        if (
          !editingEmployee.salary ||
          !editingEmployee.datehire ||
          !editingEmployee.datehireoriginal
        ) {
          handleOpenRequiredFieldsPopUp();
        } else {
          setStep(param);
        }
      } else if (activeStep === 2) {
        setStep(param);
      } else if (activeStep === 3) {
        setStep(param);
      }
    } else if (isAdding) {
      if (activeStep === 0) {
        if (
          !addingEmployee.firstname ||
          !addingEmployee.lastname ||
          !addingEmployee.gender ||
          !addingEmployee.town ||
          !addingEmployee.number ||
          !addingEmployee.dob ||
          !addingEmployee.nrc ||
          !addingEmployee.address
        ) {
          handleOpenRequiredFieldsPopUp();
        } else {
          setStep(param);
        }
      } else if (activeStep === 1) {
        if (
          !addingEmployee.salary ||
          !addingEmployee.datehire ||
          !addingEmployee.datehireoriginal
        ) {
          handleOpenRequiredFieldsPopUp();
        } else {
          setStep(param);
        }
      } else if (activeStep === 2) {
        setStep(param);
      } else if (activeStep === 3) {
        setStep(param);
      }
    } else {
      setStep(param);
    }
  };

  function stringToColor(string: string) {
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }
  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 100,
        height: 100,
        marginRight: 0,
        marginTop: -1,
        fontSize: 35,
      },
      children: `${name.split(" ")[0][0] || ""}${name.split(" ")[1][0] || ""}`,
    };
  }

  function handleNext() {
    let listSize = list.length;

    let index = list.findIndex((object) => {
      return object.id === selectedEmployee.id;
    });

    if (index + 1 === listSize) {
      setEmployee(list[0]);
    } else {
      setEmployee(list[index + 1]);
    }
  }

  function handleBack() {
    let listSize = list.length;

    let index = list.findIndex((object) => {
      return object.id === selectedEmployee.id;
    });

    if (index === 0) {
      setEmployee(list[listSize - 1]);
    } else {
      setEmployee(list[index - 1]);
    }
  }

  function editEmployee() {
    if (isEditing) {
      getAllEmployees().then((employees) => {
        let index = list.findIndex((object) => {
          return object.id === selectedEmployee.id;
        });
        setList(employees);
        setEmployee(employees[index]);
        setIsEditing(false);
        setCancelEditPopUp(false);
      });
    } else {
      setEditingEmployeet(selectedEmployee);
      setIsEditing(true);
    }
  }

  function addEmployee() {
    if (isAdding) {
      setAddingEmployee({} as Employee);
      setIsAdding(false);
      setCancelAddPopUp(false);
    } else {
      setIsAdding(true);
    }
  }

  let addSubmitForm = (event: any) => {
    event.preventDefault();
    handleOpenLoadDialog();

    // addingEmployee.id = uuidv4();

    var largest = 0;

    for (var i = 0; i < employeesList.length; i++) {
      // Extract the "xxxxxx" part from the employee ID.
      var currentID = parseInt(employeesList[i].employeeid.split('-')[0]);

      if (currentID > largest) {
        largest = currentID;
      }
    }

    addingEmployee.employeeid = generateEmployeeID(parseInt(largest) + 1);


    addingEmployee.createdAt = new Date(Date.now()).toISOString();
    addingEmployee.updatedAt = new Date(Date.now()).toISOString();
    addingEmployee.updatedBy = userName;

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(addingEmployee),
    };

    fetch("https://school.kozosms.com/api/api/employees/", requestOptions)
      .then((_emps) => {
        return getAllEmployees();
      })
      .then((employees) => {
        setList(employees);
        closeLoadDialog();
        setSnackbarOpen(true);
        setEmployee(addingEmployee);
        setAddingEmployee({} as Employee);
        setIsAdding(false);
      });
  };

  let submitForm = (event: any) => {
    event.preventDefault();
    handleOpenLoadDialog();

    editingEmployee.updatedAt = new Date(Date.now()).toISOString();
    editingEmployee.updatedBy = userName;

    const requestOptions = {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(editingEmployee),
    };

    fetch(
      "https://school.kozosms.com/api/api/employees/" + editingEmployee.id,
      requestOptions
    )
      .then((response) => response.json())
      .then((_emps) => {
        return getAllEmployees();
      })
      .then((employees) => {
        let index = employees.findIndex((object: Employee) => {
          return object.id === editingEmployee.id;
        });
        setSearch("");
        setList(employees);
        setEmployee(employees[index]);
        closeLoadDialog();
        setSnackbarOpen(true);
        setIsEditing(false);
      });
  };

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const actionSnackbar = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  let updateInput = (event: any) => {
    editingEmployee[event.target.name] = event.target.value;
  };

  let updateAddInput = (event: any) => {
    addingEmployee[event.target.name] = event.target.value;
  };

  function today() {
    let date = new Date();
    return (
      date.getFullYear() +
      "-" +
      ("0" + (new Date().getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + (new Date().getDate() + 1)).slice(-2)
    ).toString();
  }

  countries.registerLocale(enLocale);
  const countriesObj = countries.getNames("en", { select: "alias" });

  const countriesArr = Object.entries(countriesObj).map(([key, value]) => {
    return {
      label: value,
      value: value,
      id: key,
    };
  });

  const handleLanguagesChange = (event: any) => {
    editingEmployee.languagesList = event.target.value;
    addingEmployee.languagesList = event.target.value;
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChildrenChange = (children: Child[]) => {
    editingEmployee.children = children;
    addingEmployee.children = children;
  };

  const handleAllowanceChange = (allowances: Allowance[]) => {
    if (isEditing) {
      editingEmployee.allowances = allowances;
    } else if (isAdding) {
      addingEmployee.allowances = allowances;
    }
  };

  const handleStatusChange = (status: Status[]) => {
    editingEmployee.status = status;
    addingEmployee.status = status;
  };

  const handleContractUpdate = (e) => {
    const reader = new FileReader();
    if (isEditing) {
      reader.onload = () => {
        uploadToS3Bucket(
          e.target.files[0],
          "employee-contracts/" + editingEmployee.id + "_" + today()
        ).then(() => {
          if (reader.readyState === 2) {
            editingEmployee.contractfile = getS3URL(
              "employee-contracts/" + editingEmployee.id + "_" + today()
            );
          }
        });
      };
    } else if (isAdding) {
      reader.onload = () => {
        uploadToS3Bucket(
          e.target.files[0],
          "employee-contracts/" + addingEmployee.id + "_" + today()
        ).then(() => {
          if (reader.readyState === 2) {
            addingEmployee.contractfile = getS3URL(
              "employee-contracts/" + addingEmployee.id + "_" + today()
            );
          }
        });
      };
    }

    reader.readAsDataURL(e.target.files[0]);
  };

  const handleLeaveChange = (leaves: EmployeeLeave[]) => {
    editingEmployee.leaves = leaves;
    addingEmployee.leaves = leaves;
  };

  const handlePerformanceDocumentUpdate = (event: any) => {
    const reader = new FileReader();

    if (isEditing) {
      reader.onload = () => {
        uploadToS3Bucket(
          event.target.files[0],
          "employee-performance/" + editingEmployee.id
        ).then(() => {
          if (reader.readyState === 2) {
            editingEmployee.performancedocument = getS3URL(
              "employee-performance/" + editingEmployee.id
            );
          }
        });
      };
    } else if (isAdding) {
      reader.onload = () => {
        uploadToS3Bucket(
          event.target.files[0],
          "employee-performance/" + addingEmployee.id
        ).then(() => {
          if (reader.readyState === 2) {
            addingEmployee.performancedocument = getS3URL(
              "employee-performance/" + addingEmployee.id
            );
          }
        });
      };
    }

    reader.readAsDataURL(event.target.files[0]);
  };

  const imageHandler = (e: any) => {
    const reader = new FileReader();

    if (isEditing) {
      reader.onload = () => {
        handleOpenLoadDialog();
        uploadToS3Bucket(
          e.target.files[0],
          "employee-images/" + editingEmployee.id
        ).then(() => {
          if (reader.readyState === 2) {
            editingEmployee.img = getS3URL(
              "employee-images/" + editingEmployee.id
            );
            closeLoadDialog();
          }
        });
      };
    } else if (isAdding) {
      reader.onload = () => {
        handleOpenLoadDialog();
        uploadToS3Bucket(
          e.target.files[0],
          "employee-images/" + addingEmployee.id
        ).then(() => {
          if (reader.readyState === 2) {
            addingEmployee.img = getS3URL(
              "employee-images/" + addingEmployee.id
            );
            closeLoadDialog();
          }
        });
      };
    }

    reader.readAsDataURL(e.target.files[0]);
  };

  function saveBulkEdit() {
    handleOpenLoadDialog();
    const requestOptions = {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bulkList),
    };

    fetch("https://school.kozosms.com/api/api/employees/", requestOptions)
      .then((response) => response.json())
      .then((_emps) => {
        return getAllEmployees();
      })
      .then((employees) => {
        setEmployeeFilter("ACTIVE");
        let newEmployees = [...employees];
        if (employeeFilter == "ALL") {
          newEmployees = employees;
        } else if (employeeFilter == "INACTIVE") {
          newEmployees = newEmployees.filter(
            (emp) =>
              emp?.status?.length >= 1 &&
              !getMostRecentStatus(emp?.status)?.status.includes("Probation") &&
              !getMostRecentStatus(emp?.status)?.status.includes("Active")
          );
        } else if (employeeFilter == "ACTIVE") {
          newEmployees = newEmployees.filter(
            (emp) =>
              (emp?.status?.length >= 1 &&
                getMostRecentStatus(emp?.status)?.status.includes(
                  "Probation"
                )) ||
              getMostRecentStatus(emp?.status)?.status.includes("Active")
          );
        }

        setList(newEmployees);
        closeLoadDialog();
        setSnackbarOpen(true);
        setIsBulkEditing(false);
      });
  }

  function bulkEdit() {
    if (isBulkEditing) {
      if (isViewingTable) {
        setIsViewingTable(false);
        setIsBulkEditing(false);
      } else {
        setIsBulkEditing(false);
        setCancelBulkPopUp(false);
      }
    } else {
      setIsBulkEditing(true);
    }
  }

  function selectEmployeeFromBulkEdit(index: number) {
    if (isBulkEditing) {
      if (isViewingTable) {
        setIsViewingTable(false);
        setIsBulkEditing(false);
      } else {
        setIsBulkEditing(false);
        setCancelBulkPopUp(false);
      }
    } else {
      setIsBulkEditing(true);
    }
    const newList = [
      ...filterByStatus(employeesList, options[selectedFilterIndex]),
    ];
    setEmployee(newList[index]);
  }

  function getContractDate() {
    let urlArray = selectedEmployee.contractfile?.split("_");
    return urlArray[1] || "";
  }

  function tableView() {
    setIsViewingTable(true);
    setIsBulkEditing(true);
  }

  function verifyContractFields() {
    if (
      !editingEmployee.datehire ||
      !editingEmployee.contractlength ||
      !editingEmployee.firstname ||
      !editingEmployee.lastname ||
      !editingEmployee.nrc ||
      !editingEmployee.pensionno ||
      !editingEmployee.reportsto ||
      !editingEmployee.salary ||
      !editingEmployee.gratuity ||
      !editingEmployee.title
    ) {
      setVerifyContractFields(true);
    } else {
      setOpenContract(true);
    }
  }

  const [CancelEditPopUp, setCancelEditPopUp] = React.useState(false);
  const handleCancelEdit = () => {
    setCancelEditPopUp(false);
  };
  const handleOpenEdit = () => {
    setCancelEditPopUp(true);
  };
  const [CancelAddPopUp, setCancelAddPopUp] = React.useState(false);
  const handleCancelAdd = () => {
    setCancelAddPopUp(false);
  };
  const handleOpenAdd = () => {
    setCancelAddPopUp(true);
  };
  const [CancelBulkPopUp, setCancelBulkPopUp] = React.useState(false);
  const handleCancelBulk = () => {
    setCancelBulkPopUp(false);
  };
  const handleOpenBulk = () => {
    setCancelBulkPopUp(true);
  };
  const [deletePopUp, setDeletePopUp] = React.useState(false);
  const handleCloseDelete = () => {
    setDeletePopUp(false);
  };
  const handleOpenDelete = () => {
    setDeletePopUp(true);
  };
  const [requiredFieldsPopUp, setRequiredFieldsPopUp] = React.useState(false);
  const handleCloseRequiredFieldsPopUp = () => {
    setRequiredFieldsPopUp(false);
  };
  const handleOpenRequiredFieldsPopUp = () => {
    setRequiredFieldsPopUp(true);
  };
  const [openLoadDialog, setOpenLoadDialog] = React.useState(false);
  const closeLoadDialog = () => {
    setOpenLoadDialog(false);
  };
  const handleOpenLoadDialog = () => {
    setOpenLoadDialog(true);
  };

  const [isModalOpen, setOpenContract] = React.useState(false);
  const handleCloseContract = () => {
    setOpenContract(false);
  };

  const [verifyContractField, setVerifyContractFields] = React.useState(false);
  const handleVerifyContractFields = () => {
    setVerifyContractFields(false);
  };

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setSelectedFilterIndex(index);
    setAnchorEl(null);
    newEmployeeFilter(options[index]);
  };

  const handleCloseFilterMenu = () => {
    setAnchorEl(null);
  };

  const newEmployeeFilter = (newFilter: string) => {
    setEmployeeFilter(newFilter);
    const getMostRecentStatus = (statuses) => {
      return statuses?.sort(
        (a, b) =>
          new Date(b.statusdate).getTime() - new Date(a.statusdate).getTime()
      )[0];
    };

    getAllEmployees()
      .then((employees) => {
        if (newFilter == "ALL") {
          return employees;
        } else if (newFilter == "INACTIVE") {
          return employees.filter(
            (emp) =>
              emp?.status?.length >= 1 &&
              !getMostRecentStatus(emp?.status)?.status.includes("Probation") &&
              !getMostRecentStatus(emp?.status)?.status.includes("Active")
          );
        } else if (newFilter == "ACTIVE") {
          return employees.filter(
            (emp) =>
              (emp?.status?.length >= 1 &&
                getMostRecentStatus(emp?.status)?.status.includes(
                  "Probation"
                )) ||
              getMostRecentStatus(emp?.status)?.status.includes("Active")
          );
        }
      })
      .then((employees) => {
        setList(filterEmployees(employees, search));
      });
  };

  function generateEmployeeID(employeeIndex: number) {
    let currentDate = new Date();
    let year = currentDate.getFullYear();

    // If you also want to increment the year if the current month is December, you can keep this check.
    if (currentDate.getMonth() === 11) {
      year++;
    }

    let yearString = year.toString().slice(-2);

    // Assuming employeeIndex is the unique identifier for the employee which you want to format as "xxxxxx".
    // Make sure that employeeIndex does not exceed 999999 to maintain the "xxxxxx" format.
    let employeeIDString = employeeIndex.toString().padStart(6, "0");

    // Company code is fixed to "0101" as mentioned.
    let companyCode = "0101";

    return `${employeeIDString}-${yearString}-${companyCode}`;
  }


  const borderColor = "#000000";

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    },
    title: {
      fontSize: 24,
      textAlign: 'center',
      marginBottom: 20,
    },
    headerContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 10,
    },
    header: {
      fontSize: 18,
      marginBottom: 5,
    },
    subtitle: {
      fontSize: 15,
      marginBottom: 3,
    },
    contentContainer: {
      flexDirection: 'row',
    },
    leftColumn: {
      flexDirection: 'column',
      flexGrow: 9,
    },
    rightColumn: {
      flexDirection: 'column',
      flexGrow: 5,
      marginLeft: 15,
    },
    sectionHeader: {
      fontSize: 13,
      fontWeight: 'bold',
      marginBottom: 3,
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    box: {
      marginBottom: 5,
    },
    footer: {
      textAlign: 'center',
      marginTop: 25,
      fontSize: 12,
    },
    payslipHeader: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 10, // Reduced margin
    },
    payslipColumn: {
      flexDirection: 'column',
    },
    subTitle: {
      fontSize: 12,
      marginBottom: 3, // Reduced margin
    },
    bodyText: {
      fontSize: 10,
      margin: 2, // Margin kept small
    },
    salarySection: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: 2, // Added to reduce spacing
    },
    totalSection: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderTop: 1,
      paddingTop: 5,
      marginTop: 5,
    },
    boldText: {
      fontWeight: 'bold',
    },
    payslipHeaderColumn: {
      flexDirection: 'column',
    },
    payslipBody: {
      flexDirection: 'row',
      borderTop: 1,
      borderColor: '#000',
      paddingTop: 5,
    },
    earningsSection: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginVertical: 2,
    },
    sum: {
      fontWeight: 'bold',
    },
    row2: {
      flexDirection: "row",
      borderBottomColor: "#000000",
      borderBottomWidth: 1,
      borderTopColor: "#000000",
      borderTopWidth: 1,
      alignItems: "center",
      height: 50,
      fontStyle: "bold",
    },
    row3: {
      flexDirection: "row",
      borderBottomColor: "#000000",
      borderBottomWidth: 1,
      borderTopColor: "#000000",
      borderTopWidth: 1,
      alignItems: "center",
      height: 60,
      fontStyle: "bold",
    },
    row4: {
      flexDirection: "row",
      borderBottomColor: "#000000",
      borderBottomWidth: 1,
      borderTopColor: "#000000",
      borderTopWidth: 1,
      alignItems: "center",
      height: 75,
      fontStyle: "bold",
    },
    container: {
      flexDirection: "row",
      borderBottomColor: "#000000",
      borderBottomWidth: 1,
      alignItems: "center",
      height: 24,
      textAlign: "center",
      fontStyle: "bold",
      flexGrow: 1,
    },
    description: {
      width: "6.25%",
      borderRightColor: borderColor,
      borderLeftColor: borderColor,
      borderRightWidth: 1,
      borderLeftWidth: 1,
      height: 24,
      paddingLeft: 2,
      paddingTop: 5,
    },
    description2: {
      width: "6.25%",
      borderRightColor: borderColor,
      borderLeftColor: borderColor,
      borderRightWidth: 1,
      borderLeftWidth: 1,
      height: 50,
      paddingLeft: 2,
      paddingTop: 5,
    },
    description3: {
      width: "6.25%",
      borderRightColor: borderColor,
      borderLeftColor: borderColor,
      borderRightWidth: 1,
      borderLeftWidth: 1,
      height: 60,
      paddingLeft: 2,
      paddingTop: 5,
    },
    description4: {
      width: "6.25%",
      borderRightColor: borderColor,
      borderLeftColor: borderColor,
      borderRightWidth: 1,
      borderLeftWidth: 1,
      height: 75,
      paddingLeft: 2,
      paddingTop: 5,
    },
    allowance: {
      width: "6.25%",
      borderRightColor: borderColor,
      borderLeftColor: borderColor,
      borderRightWidth: 1,
      borderLeftWidth: 1,
      height: 24,
      paddingLeft: 2,
      paddingTop: 5,
    },
    billTo: {
      paddingBottom: 5,
      fontFamily: "Helvetica-Oblique",
    },
  });

  const findServiceProviderNameById = (id) => {
    const provider = serviceProviders?.find(provider => provider.id === id);
    return provider ? provider.serviceprovider : 'Unknown Provider';
  };

  // Example function to find deduction type name by ID
  const findDeductionTypeNameById = (id) => {
    const type = deductionTypes?.find(type => type.id === id);
    return type ? type.deductiontype : 'Unknown Type';
  };

  // Example function to find deduction type name by ID
  const findAllowanceTypeNameById = (id) => {
    const type = allowanceTypes?.find(type => type.id === id);
    return type ? type.allowancetype : 'Unknown Type';
  };

  const PayrollDocument = ({ employee, selectedMonth }) => {
    const payslipForMonth = selectedEmployee?.payslips?.find(payslip => {
      return payslip?.payslipperiod?.toString() === selectedMonth?.toString();
    });

    // Proceed with rendering only if payslipForMonth is found
    if (!payslipForMonth) {
      return null;
    }

    return (
      <Document>
        <Page size="A4" style={{
          flexDirection: 'column',
          backgroundColor: '#F9F9F9',
          padding: 30,
        }}>
          <View >
            <View style={{
              // fontFamily: "'Arial', sans-serif",
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "10px",
              margin: "5px",
              backgroundColor: "#fff"
            }}>
              <View style={{
                borderBottomWidth: 2,
                borderColor: "#000",
                paddingBottom: 10,
                marginBottom: 10,
              }}>
                <View style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}>
                  {/* Left Column */}
                  <View style={{ flex: 1 }}>
                    <Text style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 4 }}>
                      {school.clientname}
                    </Text>
                    <Text style={{ fontSize: 10 }}>
                      {`Employee Payslip - ${formatDate(payslipForMonth.createdAt)}`}
                    </Text>
                  </View>

                  {/* Right Column */}
                  <View style={{ flex: 1, alignItems: 'flex-end' }}>
                    <View style={{
                      flexDirection: 'row',
                      justifyContent: 'flex-end', alignItems: 'flex-end', paddingBottom: 4
                    }}>
                      <Text style={{ fontWeight: 'bold', fontSize: 14, textAlign: 'right' }}>
                        {payslipForMonth?.payslipfirstname + " " + payslipForMonth?.paysliplastname}
                      </Text>
                      <Text style={{ fontWeight: 100, fontSize: 12, textAlign: 'right' }}>
                        {" - ID: " + payslipForMonth?.payslipid}
                      </Text>
                    </View>
                    <Text style={{ fontSize: 10, textAlign: 'right' }}>
                      {payslipForMonth?.paysliptitle}
                    </Text>
                    <Text style={{ fontSize: 10, textAlign: 'right' }}>
                      {`NRC # ${payslipForMonth?.payslipnrc}, TPIN # ${payslipForMonth?.paysliptpin}, SSN # ${payslipForMonth?.payslipssn}`}
                    </Text>
                  </View>
                </View>
              </View>

              <View style={{
                flexDirection: 'row',
              }}>
                <View style={{
                  width: '65%',
                }}>
                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 0,
                  }}>
                    <View style={{
                      width: '55%', // Adjust the width as necessary
                      justifyContent: 'center',
                    }}>
                      <Text style={{
                        fontSize: 12, // Adjust font size as necessary
                        marginBottom: 5, // Adjust spacing as necessary
                        fontWeight: 'bold',
                      }}>
                        EARNINGS
                      </Text>
                    </View>
                    <View style={{
                      width: '30%', // Adjust the width as necessary
                      justifyContent: 'center',
                    }}>
                      <Text style={{
                        fontSize: 12, // Adjust font size as necessary
                        marginBottom: 5, // Adjust spacing as necessary
                        fontWeight: 'bold',
                      }}>
                        THIS MONTH
                      </Text>
                    </View>
                    <View style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                    }}>
                      <Text style={{
                        fontSize: 12, // Adjust font size as necessary
                        marginBottom: 5, // Adjust spacing as necessary
                        fontWeight: 'bold',
                      }}>
                        YTD
                      </Text>
                    </View>
                  </View>
                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                  }}>
                    <Text style={{
                      width: '60%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10,
                      paddingLeft: 10
                    }}>
                      Salary
                    </Text>
                    <Text style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10
                    }}>
                      K{payslipForMonth?.payslipmonthsalary}
                    </Text>
                    <Text style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10
                    }}>
                      K{payslipForMonth?.payslipytdsalary}
                    </Text>
                  </View>

                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                  }}>
                    <Text style={{
                      width: '60%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10,
                      paddingLeft: 10
                    }}>
                      Other:
                    </Text>

                  </View>

                  {payslipForMonth.payslipmonthcustomallowances && payslipForMonth.payslipmonthcustomallowances.map((allowance, index) => (


                    <View style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginBottom: 5,
                    }}>
                      <Text style={{
                        width: '60%', // Adjust the width as necessary
                        justifyContent: 'center',
                        fontSize: 10,
                        paddingLeft: 20
                      }}>
                        {"• "}{findAllowanceTypeNameById(allowance.allowanceType)}
                      </Text>
                      <Text style={{
                        width: '20%', // Adjust the width as necessary
                        justifyContent: 'center',
                        fontSize: 10,
                      }}>
                        K{allowance.allowanceAmount}
                      </Text>
                      <Text style={{
                        width: '20%', // Adjust the width as necessary
                        justifyContent: 'center',
                        fontSize: 10,
                      }}>

                      </Text>
                    </View>

                    // <View key={index} style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
                    //   <Text style={{ width: '66%', fontSize: 10, textAlign: 'left', paddingLeft: 20 }}>
                    //     •{findDeductionTypeNameById(deduction.deductionType)} - {findServiceProviderNameById(deduction.deductionServiceProvider)}: K{deduction.deductionAmount}
                    //   </Text>
                    // </View>
                  ))}
                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                  }}>
                    <Text style={{
                      width: '60%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10,
                      paddingLeft: 10
                    }}>
                      Total Allowances:
                    </Text>
                    <Text style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10
                    }}>
                      K{payslipForMonth?.payslipmonthallowances ? payslipForMonth?.payslipmonthallowances : "0"}
                    </Text>
                    <Text style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10
                    }}>
                      K{payslipForMonth?.payslipytdallowances}
                    </Text>
                  </View>
                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                  }}>
                    <View style={{
                      width: '60%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 12
                    }}>
                      <Text
                        style={{
                          width: '100%',
                          backgroundColor: '#ccc',
                          fontSize: 12,
                          paddingLeft: 10
                        }}
                      >
                        GROSS PAY
                      </Text>
                    </View>
                    <View style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 12
                    }}>
                      <Text
                        style={{
                          width: '100%',
                          backgroundColor: '#ccc',
                        }}
                      >
                        K{payslipForMonth?.payslipmonthgrosspay}
                      </Text>
                    </View>
                    <View style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 12
                    }}>
                      <Text style={{
                        fontSize: 12,
                      }}>
                        K{payslipForMonth?.payslipytdgrosspay}
                      </Text>
                    </View>
                  </View>

                  <Text
                    style={{
                      fontSize: 12,
                    }}>
                    Deductions
                  </Text>
                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                  }}>
                    <View style={{
                      width: '60%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10,
                      paddingLeft: 10
                    }}>
                      <Text style={{ fontSize: 10 }}>
                        Statutory
                      </Text>
                    </View>
                  </View>
                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                  }}>
                    <Text style={{
                      width: '60%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10,
                      paddingLeft: 20
                    }}>PAYE</Text>
                    <Text style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10
                    }}>
                      K{payslipForMonth?.payslipmonthpaye}
                    </Text>
                    <Text style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10
                    }}>
                      K{payslipForMonth?.payslipytdpaye}
                    </Text>
                  </View>
                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                  }}>
                    <Text style={{
                      width: '60%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10,
                      paddingLeft: 20
                    }}>NAPSA</Text>
                    <Text style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10
                    }}>
                      K{payslipForMonth?.payslipmonthnapsa}
                    </Text>
                    <Text style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10
                    }}>
                      K{payslipForMonth?.payslipytdnapsa}
                    </Text>
                  </View>
                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                  }}>
                    <Text style={{
                      width: '60%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10,
                      paddingLeft: 20
                    }}>
                      NHIMA
                    </Text>
                    <Text style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10
                    }}>
                      K{payslipForMonth?.payslipmonthnhima}
                    </Text>
                    <Text style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10
                    }}>
                      K{payslipForMonth?.payslipytdnhima}
                    </Text>
                  </View>
                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                  }}>
                    <Text style={{
                      width: '33%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10,
                      paddingLeft: 10
                    }}>
                      Other
                    </Text>
                  </View>

                  {payslipForMonth.payslipmonthcustomdeductions && payslipForMonth.payslipmonthcustomdeductions.map((deduction, index) => (


                    <View style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginBottom: 5,
                    }}>
                      <Text style={{
                        width: '60%', // Adjust the width as necessary
                        justifyContent: 'center',
                        fontSize: 10,
                        paddingLeft: 20
                      }}>
                        {"• "}{findDeductionTypeNameById(deduction.deductionType)} - {findServiceProviderNameById(deduction.deductionServiceProvider)}
                      </Text>
                      <Text style={{
                        width: '20%', // Adjust the width as necessary
                        justifyContent: 'center',
                        fontSize: 10,
                      }}>
                        K{deduction.deductionAmount}
                      </Text>
                      <Text style={{
                        width: '20%', // Adjust the width as necessary
                        justifyContent: 'center',
                        fontSize: 10,
                      }}>

                      </Text>
                    </View>

                    // <View key={index} style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
                    //   <Text style={{ width: '66%', fontSize: 10, textAlign: 'left', paddingLeft: 20 }}>
                    //     •{findDeductionTypeNameById(deduction.deductionType)} - {findServiceProviderNameById(deduction.deductionServiceProvider)}: K{deduction.deductionAmount}
                    //   </Text>
                    // </View>
                  ))}


                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                  }}>
                    <View style={{
                      width: '60%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10,
                      paddingLeft: 10
                    }}>
                      <Text style={{ fontSize: 10 }}>
                        Total Deductions
                      </Text>
                    </View>
                    <View style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10
                    }}>
                      <Text style={{ fontSize: 12 }}>
                        K{payslipForMonth?.payslipmonthtotaldeductions}
                      </Text>
                    </View>
                    <View style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 10
                    }}>
                      <Text style={{ fontSize: 12 }}>
                        K{payslipForMonth?.payslipytdtotaldeductions}
                      </Text>
                    </View>
                  </View>

                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                  }}>
                    <View style={{
                      width: '60%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 12
                    }}>
                      <Text
                        style={{
                          width: '100%',
                          backgroundColor: '#ccc',
                          paddingLeft: 10
                        }}
                      >
                        NET PAY
                      </Text>
                    </View>
                    <View style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 12
                    }}>
                      <Text
                        style={{
                          width: '100%',
                          backgroundColor: '#ccc',
                        }}
                      >
                        K{payslipForMonth?.payslipmonthnetpay}
                      </Text>
                    </View>
                    <View style={{
                      width: '20%', // Adjust the width as necessary
                      justifyContent: 'center',
                      fontSize: 12
                    }}>
                      <Text style={{ fontSize: 12 }}>
                        K{payslipForMonth?.payslipytdnetpay}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={{
                  width: '35%',
                }}>
                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 0,
                  }}>
                    <Text style={{
                      fontSize: 12, // This represents 'subtitle1'. Adjust the size as needed.
                      marginBottom: 4, // For spacing
                    }}>
                      OTHER INFORMATION
                    </Text>
                  </View>

                  {/* Repeated structure for each item, with inline styles */}
                  <View style={{ alignSelf: 'stretch', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                    <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                      Gratuity % Rate
                    </Text>
                    <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                      {`${payslipForMonth.payslipgratuity}%`}
                    </Text>
                  </View>

                  <View style={{ alignSelf: 'stretch', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                    <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                      YTD Accrued Gratuity*
                    </Text>
                    <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                      TBD{/* {`K${payslipForMonth.payslipytdaccruedgratuity}`} */}
                    </Text>
                  </View>

                  <View style={{ alignSelf: 'stretch', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                    <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                      Contract End Date
                    </Text>
                    <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                      {payslipForMonth.payslipcontractend}
                    </Text>
                  </View>

                  <View style={{ alignSelf: 'stretch', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10, marginBottom: 10 }}>
                    <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                      Projected Payout at Maturity*
                    </Text>
                    <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                      TBD{/* {`K${payslipForMonth.payslipprojectedpayout}`} */}
                    </Text>
                  </View>

                  <View style={{ alignSelf: 'stretch', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
                    <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                      * Estimated amounts only
                    </Text>
                  </View>

                  {/* For sections that require a distinct background or layout */}
                  <View style={{
                    backgroundColor: '#f9f9f9', // Example background color
                    padding: 10, // Inner spacing
                    borderRadius: 5, // Rounded corners
                    marginBottom: 5,
                    border: "1px solid #ccc",// Space between this and the next section
                  }}>
                    <Text style={{
                      fontSize: 12,
                      fontWeight: 'bold',
                      marginBottom: 4,
                    }}>
                      Salary Deposit Details
                    </Text>
                    <Text style={{ fontSize: 10 }}>
                      {"Employee Bank: "}
                      <Text style={{ fontWeight: 'normal' }}>
                        {payslipForMonth.payslipbank}
                      </Text>
                    </Text>

                    <Text style={{ fontSize: 10 }}>
                      {"Account Number: "}
                      <Text style={{ fontWeight: 'normal' }}>
                        {payslipForMonth.payslipaccount}
                      </Text>
                    </Text>

                    <Text style={{ fontSize: 10 }}>
                      {"Branch Location: "}
                      <Text style={{ fontWeight: 'normal' }}>
                        {payslipForMonth.paysliplocation}
                      </Text>
                    </Text>
                  </View>
                  <View style={{
                    flexDirection: 'row', // Aligns children (Text and Image) in a row.
                    alignItems: 'center', // Centers items vertically in the container.
                    justifyContent: 'center', // Centers the container's content horizontally.
                    marginTop: 10,
                  }}>
                    <Text style={{
                      fontSize: 10,
                      marginRight: 3, // Adds some space between the text and the image.
                    }}>
                      KOZO Pay by
                    </Text>
                    <Image
                      style={{ width: 20, height: 20 }}
                      src={tri8logo}
                    />
                  </View>
                </View>
              </View>
              <View style={{
                textAlign: "center",
                marginTop: "10px",
                fontSize: 10
              }}>
                <Text >
                  If you notice an error on the payslip - please contact management
                </Text>

              </View>
            </View>
          </View>
        </Page>
      </Document>
    );
  };


  function generateSelectableMonths() {
    let currentYear, currentMonth;

    if (nextMonth) {
      // If nextMonth is provided, parse it to set currentYear and currentMonth
      const [year, month] = nextMonth.split('-').map(Number);
      currentYear = year;
      currentMonth = month;
    } else {
      // If nextMonth is not provided, use the current date
      currentYear = new Date().getFullYear();
      currentMonth = new Date().getMonth() + 1; // JavaScript months are 0-indexed
    }

    const months = [];

    // Assuming you want to generate months till the end of the current year from nextMonth or the current month
    for (let month = currentMonth; month <= 12; month++) {
      months.push({
        value: `${currentYear}-${month.toString().padStart(2, '0')}`,
        label: `${currentYear}-${month.toString().padStart(2, '0')}`,
      });
    }

    // If nextMonth is in the next year, you might need additional logic to handle year transition

    return months;
  };

  function monthsSinceJanuary2024() {
    if (employeesList && employeesList.length > 0) {
      const monthCountsMap = new Map();
      const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];
      const startDate = new Date(2024, 0, 1);
      const today = new Date();
      let months = [];

      // Initialize counts for each month based on employees eligible before that month
      for (
        let year = startDate.getFullYear();
        year <= today.getFullYear();
        year++
      ) {
        for (let month = (year === startDate.getFullYear() ? startDate.getMonth() : 0); month <= (year === today.getFullYear() ? today.getMonth() : 11); month++) {
          const monthStr = `${year}-${(month + 1).toString().padStart(2, "0")}`;
          monthCountsMap.set(monthStr, {
            approvedCount: 0,
            mockCount: 0,
            liveCount: 0,
            noPayslipCount: 0
          });

          // Calculate noPayslipCount based on status and status date
          employeesList.forEach((employee) => {
            const recentStatusInfo = getMostRecentStatus(employee?.status);
            if (recentStatusInfo &&
              (recentStatusInfo.status === "Active" || recentStatusInfo.status === "Probation")) {
              const statusDate = new Date(recentStatusInfo.statusdate);
              const monthDate = new Date(year, month, 1);
              if (statusDate <= monthDate) {
                const counts = monthCountsMap.get(monthStr);
                counts.noPayslipCount++;
              }
            }
          });
        }
      }

      // Process each employee and update the corresponding month
      employeesList.forEach((employee) => {
        employee.payslips.forEach((payslip) => {
          const monthStr = payslip.payslipperiod;
          if (monthCountsMap.has(monthStr)) {
            const counts = monthCountsMap.get(monthStr);
            // Update only if the employee was eligible before the payslip month
            const recentStatusInfo = getMostRecentStatus(employee?.status);
            if (recentStatusInfo) {
              const statusDate = new Date(recentStatusInfo.statusdate);
              const [payslipYear, payslipMonth] = monthStr.split('-');
              const payslipDate = new Date(payslipYear, parseInt(payslipMonth, 10) - 1, 1);
              // console.log(payslip.status === "0")
              // console.log(payslip.status)
              if (statusDate <= payslipDate) {
                if (payslip.status === "1") {
                  counts.approvedCount++;
                  counts.noPayslipCount--;
                } else if (payslip.status === "0") {
                  counts.mockCount++;
                  counts.noPayslipCount--;
                } else if (payslip.status === "2") {
                  counts.liveCount++;
                  counts.noPayslipCount--;
                }
              }
            }
          }
        });
      });

      // Build the months array
      monthCountsMap.forEach((counts, monthStr) => {
        const [year, month] = monthStr.split('-');
        if (counts.noPayslipCount > 0 || counts.approvedCount > 0 || counts.mockCount > 0) {
          months.push({
            year: parseInt(year, 10),
            month: parseInt(month, 10),
            monthName: monthNames[parseInt(month, 10) - 1],
            approvedCount: counts.approvedCount,
            mockCount: counts.mockCount,
            liveCount: counts.liveCount,
            noPayslipCount: counts.noPayslipCount,
          });
        }
      });

      if (months[0]) {
        setNextMonth(`${months[0].year}-${months[0].month
          .toString()
          .padStart(2, "0")}`)
      }
    }
  }





  const selectableMonths = generateSelectableMonths();

  return (
    <PermissionsGate scopes={[SCOPES.canEdit]}>
      <React.Fragment>
        <section>
          <div>
            <Grid
              item
              container
              className="listbackground"
              direction="column"
              justifyContent="space-between"
              alignItems="stretch"
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Dialog open={openPayroll} onClose={() => {
                setIsSelectingMonth(true)
                setOpenPayrollModal(false)
              }
              }>
                {isSelectingMonth ? (
                  <>
                    <DialogTitle>Select Payroll Period</DialogTitle>
                    <DialogContent>
                      <FormControl fullWidth>
                        <InputLabel id="payroll-month-select-label">Month</InputLabel>
                        <Select
                          labelId="payroll-month-select-label"
                          id="payroll-month-select"
                          value={selectedPayrollMonth}
                          label="Month"
                          onChange={handlePayrollMonthChange}
                        >
                          {payrollMonths.map((month) => (
                            <MenuItem key={month} value={month}>
                              {month}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </DialogContent>
                  </>
                ) : (
                  <>

                    {/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "90vh" }}> */}
                    <PDFViewer style={{ height: 700, maxHeight: "100vh", maxWidth: "100vw", width: 600 }}>
                      <PayrollDocument employee={selectedEmployee} selectedMonth={selectedPayrollMonth} />
                    </PDFViewer>

                    <PDFDownloadLink
                      document={
                        <PayrollDocument employee={selectedEmployee} selectedMonth={selectedPayrollMonth} />
                      }
                      fileName="mock_payroll.pdf"
                      style={{ maxWidth: 172, display: "flex", alignSelf: "center", marginTop: 20 }} // Added marginTop for spacing
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          "Loading document..."
                        ) : (
                          <Button
                            startIcon={<DownloadIcon />}
                            variant="contained"
                            color="success"
                            size="small"
                          >
                            Download Payslip
                          </Button>

                        )
                      }
                    </PDFDownloadLink>
                    {/* </div> */}
                  </>
                )}
              </Dialog>
              <Dialog PaperProps={{
                style: { minWidth: "90vw" }, // Adjust the minWidth to your preference
              }} open={openDeductions} onClose={() => {
                setOpenDeductionsModal(false)
              }
              }>
                <>
                  <div style={{ minHeight: "90vh", minWidth: "90vw", backgroundColor: "#fff", borderRadius: 15 }}>
                    <DialogTitle style={{
                      minWidth: "90vw",
                      backgroundColor: "#5E35B1",
                      color: "#fff",
                      marginBottom: 10
                    }} > Employee Deductions - {selectedEmployee.firstname} {selectedEmployee.lastname}</DialogTitle>
                    <DialogContent>

                      {isEditngDeductions ? (
                        <CircularProgress></CircularProgress>) : (
                        <div >
                          {(
                            <div>
                              <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="stretch"
                                spacing={2}
                              >
                                <Grid container item xs={12} spacing={2}>
                                  <Grid item xs={6}>

                                    <PermissionsGate scopes={[SCOPES.cancreateonetimededuction]}>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={isEditingSingleDeduction || deductionType} style={{ marginRight: 15 }} onClick={() => setDeductionType('oneTime')}>
                                        Create One-Time Deduction
                                      </Button>
                                    </PermissionsGate>

                                  </Grid>

                                  <Grid item xs={6}>

                                    <PermissionsGate scopes={[SCOPES.cancreaterecurringdeduction]}>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={isEditingSingleDeduction || deductionType} onClick={() => setDeductionType('recurring')}>
                                        Create Recurring Deduction
                                      </Button>
                                    </PermissionsGate>


                                  </Grid>
                                </Grid>
                              </Grid>


                              {deductionType && (
                                <form style={{ marginBottom: -60, marginTop: 10 }} onSubmit={addDeduction}>
                                  <FormControl size="small" style={{ maxWidth: 300, marginTop: 15, minWidth: 200, flex: 1, marginRight: 9 }}>
                                    <InputLabel id="deduction-type-label">Deduction Type</InputLabel>
                                    <Select
                                      style={{
                                        marginTop: 5,
                                      }}
                                      labelId="deduction-type-label"
                                      id="deductiontype"
                                      name="deductiontype"
                                      value={form.deductiontype}
                                      label="Deduction Type"
                                      onChange={handleFormChange}
                                    >
                                      {deductionTypes.map((deductionType, index) => (
                                        <MenuItem key={deductionType.id} value={deductionType.id}>{deductionType.deductiontype}</MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>

                                  <FormControl size="small" style={{ maxWidth: 300, marginTop: 15, minWidth: 200, flex: 1, marginRight: 9 }}>
                                    <InputLabel id="service-provider-label">Service Provider</InputLabel>
                                    <Select
                                      style={{
                                        marginTop: 5,
                                      }}
                                      labelId="service-provider-label"
                                      id="deductionserviceprovider"
                                      name="deductionserviceprovider"
                                      value={form.deductionserviceprovider}
                                      label="Service Provider"
                                      onChange={handleFormChange}
                                    >
                                      {serviceProviders.map((provider, index) => (
                                        <MenuItem key={provider.id} value={provider.id}>{provider.serviceprovider}</MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>


                                  <TextField
                                    size="small"
                                    InputProps={{
                                      inputProps: {
                                        min: 0,
                                        style: { height: '100px' }, // Adjust this value as needed
                                      },
                                      startAdornment: (
                                        <InputAdornment position="start">K</InputAdornment>
                                      ),
                                    }}
                                    style={{ maxWidth: 300, marginTop: 20, minWidth: 100, flex: 1, marginRight: 9, minHeight: 100 }}
                                    label="Deduction Amount"
                                    name="deductionamount"
                                    value={form.deductionamount}
                                    onChange={handleFormChange}
                                    type="number"
                                  />



                                  {deductionType === 'recurring' ? (
                                    <>
                                      <FormControl size="small" style={{ maxWidth: 200, marginTop: 15, minWidth: 100, flex: 1, marginRight: 9 }}>
                                        <InputLabel id="deduction-start-time-label">Start Month</InputLabel>
                                        <Select

                                          style={{
                                            marginTop: 5,
                                          }}
                                          labelId="deduction-start-time-label"
                                          id="deductionstartmonth"
                                          name="deductionstartmonth"
                                          value={form.deductionstartmonth}
                                          label="Deduction Start Month"
                                          onChange={handleFormChange}
                                        >
                                          {selectableMonths.map((month) => (
                                            <MenuItem style={{ width: 200 }} key={month.value} value={month.value}>{month.label}</MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                      <FormControl size="small" style={{ maxWidth: 200, marginTop: 15, minWidth: 100, flex: 1, marginRight: 9 }}>
                                        <InputLabel
                                          id="deduction-end-time-label">End Month</InputLabel>
                                        <Select
                                          style={{
                                            marginTop: 5,
                                          }}
                                          labelId="deduction-end-time-label"
                                          id="deductionendmonth"
                                          name="deductionendmonth"
                                          value={form.deductionendmonth}
                                          label="Deduction End Month"
                                          onChange={handleFormChange}
                                        >
                                          {selectableMonths.filter(month => month.value > form.deductionstartmonth).map((month) => (
                                            <MenuItem key={month.value} value={month.value}>{month.label}</MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                      <TextField
                                        disabled={true}
                                        size="small"
                                        style={{ maxWidth: 300, marginTop: 20, minWidth: 100, flex: 1, marginRight: 9, minHeight: 100 }}
                                        label="Monthly Deduction Amount"
                                        name="monthlyDeductionAmount"
                                        value={monthlyDeductionAmount}
                                        InputProps={{
                                          inputProps: {
                                            min: 0,
                                            style: { height: '100px' }, // Adjust this value as needed
                                          },
                                          startAdornment: (
                                            <InputAdornment position="start">K</InputAdornment>
                                          ),
                                          style: {
                                            color: 'black', // Set the text color
                                            // Any other styling you want to apply to the input
                                          }
                                        }}
                                        InputLabelProps={{
                                          style: {
                                            color: 'black', // You can also style the label similarly if needed
                                          }
                                        }}
                                      // Since it's a calculated and displayed field, no need for onChange
                                      />
                                    </>
                                  ) :
                                    (
                                      <>
                                        <TextField
                                          disabled={true}
                                          size="small"
                                          style={{ maxWidth: 300, marginTop: 20, minWidth: 100, flex: 1, marginRight: 9, minHeight: 100 }}
                                          label="Month"
                                          name="deductionstartmonth"
                                          value={nextMonth}
                                          onChange={handleFormChange}
                                        />
                                      </>
                                    )}

                                  <Button style={{ marginTop: 20, marginRight: 5 }}
                                    variant="contained"
                                    color="success"
                                    size="small"
                                    type="submit">Create</Button>
                                  <Button style={{ marginTop: 20 }} variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                      setDeductionType(null)
                                      resetForm()
                                    }}>
                                    Cancel
                                  </Button>
                                </form>
                              )}
                            </div>)}


                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="stretch"
                            spacing={2}
                          >
                            <Grid container item xs={12} spacing={2}>
                              <Grid item xs={6}>

                                <List>
                                  {deductions?.map((deduction, index) => {
                                    if (deduction.deductionendmonth === deduction.deductionstartmonth) {
                                      if (isEditingSingleDeduction && editingIndex === index) {
                                        // Render form for editing this specific deduction
                                        return (
                                          <ListItem key={index} component="div" style={{ flexDirection: 'column', alignItems: 'flex-start', marginTop: 10 }}>
                                            <form>
                                              {/* Deduction Type */}
                                              <FormControl size="small" style={{ maxWidth: 300, marginTop: 15, minWidth: 200, marginRight: 9 }}>
                                                <InputLabel id={`deduction-type-label-${index}`}>Deduction Type</InputLabel>
                                                <Select
                                                  labelId={`deduction-type-label-${index}`}
                                                  id={`deductiontype-${index}`}
                                                  name="deductiontype"
                                                  value={form.deductiontype}
                                                  label="Deduction Type"
                                                  onChange={handleFormChange}
                                                  style={{
                                                    marginTop: 5,
                                                  }}
                                                >
                                                  {deductionTypes.filter(deductionType => deductionType.clientid === school.clientid).map((deductionType) => (
                                                    <MenuItem key={deductionType.id} value={deductionType.id}>{deductionType.deductiontype}</MenuItem>
                                                  ))}
                                                </Select>
                                              </FormControl>


                                              <FormControl size="small" style={{ maxWidth: 300, marginTop: 15, minWidth: 200, marginRight: 9 }}>
                                                <InputLabel id={`service-provider-label-${index}`}>Service Provider</InputLabel>
                                                <Select
                                                  labelId={`service-provider-label-${index}`}
                                                  id={`deductionserviceprovider-${index}`}
                                                  name="deductionserviceprovider"
                                                  value={form.deductionserviceprovider}
                                                  label="Service Provider"
                                                  onChange={handleFormChange}
                                                  style={{
                                                    marginTop: 5,
                                                  }}
                                                >
                                                  {serviceProviders.filter(provider => provider.clientid === school.clientid).map((provider) => (
                                                    <MenuItem key={provider.id} value={provider.id}>{provider.serviceprovider}</MenuItem>
                                                  ))}
                                                </Select>
                                              </FormControl>

                                              <TextField
                                                size="small"
                                                InputProps={{
                                                  inputProps: { min: 0 },
                                                  startAdornment: <InputAdornment position="start">K</InputAdornment>,
                                                }}
                                                style={{ maxWidth: 300, marginTop: 20, minWidth: 100, marginRight: 9 }}
                                                label="Deduction Amount"
                                                name="deductionamount"
                                                value={form.deductionamount}
                                                onChange={handleFormChange}
                                                type="number"
                                              />
                                              {/* Term End - only if recurring */}
                                              {form.deductionendmonth !== form.deductionstartmonth ? (
                                                <>
                                                  <FormControl size="small" style={{ maxWidth: 200, marginTop: 15, minWidth: 100, marginRight: 9 }}>
                                                    <InputLabel id={`deduction-start-time-label-${index}`}>Start Month</InputLabel>
                                                    <Select
                                                      labelId={`deduction-start-time-label-${index}`}
                                                      id={`deductionstartmonth-${index}`}
                                                      name="deductionstartmonth"
                                                      value={form.deductionstartmonth}
                                                      label="Deduction Start Time"
                                                      onChange={handleFormChange}

                                                      style={{
                                                        marginTop: 5,
                                                      }}
                                                    >
                                                      {selectableMonths.map((month, monthIndex) => (
                                                        <MenuItem key={monthIndex} value={month.value}>{month.label}</MenuItem>
                                                      ))}
                                                    </Select>
                                                  </FormControl>
                                                  <FormControl size="small" style={{ maxWidth: 200, marginTop: 15, minWidth: 100, marginRight: 9 }}>
                                                    <InputLabel id={`deduction-end-time-label-${index}`}>End Month</InputLabel>
                                                    <Select
                                                      labelId={`deduction-end-time-label-${index}`}
                                                      id={`deductionendmonth-${index}`}
                                                      name="deductionendmonth"
                                                      value={form.deductionendmonth}
                                                      label="Deduction End Time"
                                                      onChange={handleFormChange}

                                                      style={{
                                                        marginTop: 5,
                                                      }}
                                                    >
                                                      {selectableMonths.filter(month => month.value > form.deductionstartmonth).map((month, monthIndex) => (
                                                        <MenuItem key={monthIndex} value={month.value}>{month.label}</MenuItem>
                                                      ))}
                                                    </Select>
                                                  </FormControl>
                                                  <TextField
                                                    disabled={true}
                                                    size="small"
                                                    style={{ maxWidth: 200, marginTop: 15, minWidth: 100, marginRight: 9 }}
                                                    label="Monthly Deduction Amount"
                                                    name="monthlyDeductionAmount"
                                                    value={monthlyDeductionAmount}
                                                    InputProps={{
                                                      inputProps: {
                                                        min: 0,
                                                        style: { height: '100px' }, // Adjust this value as needed
                                                      },
                                                      startAdornment: (
                                                        <InputAdornment position="start">K</InputAdornment>
                                                      ),
                                                      style: {
                                                        color: 'black', // Set the text color
                                                        // Any other styling you want to apply to the input
                                                      }
                                                    }}
                                                    InputLabelProps={{
                                                      style: {
                                                        color: 'black', // You can also style the label similarly if needed
                                                      }
                                                    }}
                                                  // Since it's a calculated and displayed field, no need for onChange
                                                  />
                                                </>
                                              ) :
                                                (<>
                                                  <TextField
                                                    disabled={true}
                                                    size="small"
                                                    style={{ maxWidth: 300, marginTop: 20, minWidth: 100, marginRight: 9 }}
                                                    label="Month"
                                                    name="deductionstartmonth"
                                                    value={form.deductionstartmonth}
                                                    onChange={handleFormChange}
                                                  />
                                                </>)}

                                              {/* Save and Cancel Buttons */}
                                              <div
                                                style={{ marginTop: 10, marginBottom: 5 }}
                                              >
                                                <Button style={{ marginRight: 10 }}
                                                  variant="contained"
                                                  color="primary"
                                                  type="submit"
                                                  size="small" onClick={() => {
                                                    handleEditDeductionSubmit(index)
                                                    setIsEditingSingleDeduction(false)
                                                  }}>
                                                  Save
                                                </Button>
                                                <Button style={{ marginRight: 10 }}
                                                  startIcon={<DeleteIcon />}
                                                  variant="contained"
                                                  color="warning"
                                                  size="small" onClick={() => {
                                                    setIsEditingSingleDeduction(false)
                                                    resetForm()
                                                  }}>
                                                  Cancel
                                                </Button>
                                              </div>
                                            </form>
                                          </ListItem>
                                        );
                                      } else {
                                        return (
                                          <div>
                                            <ListItem key={index} style={{ borderBottom: '1px solid #ccc', paddingBottom: '8px', paddingTop: '8px' }}>
                                              <ListItemText
                                                primary={
                                                  <>
                                                    <Typography component="span" variant="body1">
                                                      {deduction.deductionstartmonth === deduction.deductionendmonth ?
                                                        "One-time Deduction" :
                                                        "Recurring Deduction"}
                                                    </Typography>
                                                    <Typography component="span" variant="body2" display="block">
                                                      {deductionTypes?.find(dt => dt.id === deduction.deductiontype)?.deductiontype ||
                                                        'Unknown Deduction Type'}
                                                      {' - '}
                                                      {serviceProviders?.find(dt => dt.id === deduction.deductionserviceprovider)?.serviceprovider ||
                                                        'Unknown Service Provider Type'}
                                                    </Typography>
                                                  </>
                                                }
                                                secondary={
                                                  <>
                                                    <Typography component="span" variant="body2">
                                                      Amount: K{deduction.deductionamount}
                                                    </Typography>
                                                    <Typography component="span" variant="body2" display="block">
                                                      {deduction.deductionstartmonth === deduction.deductionendmonth ?
                                                        `Month: ${deduction.deductionstartmonth}` :
                                                        `Start Month: ${deduction.deductionstartmonth}, End Month: ${deduction.deductionendmonth}`}
                                                    </Typography>
                                                  </>
                                                }
                                              />
                                              <ListItemSecondaryAction>
                                                {isConfirmingDelete && index === isConfirmingDeleteIndex ? (
                                                  <div>
                                                    <Button style={{ marginRight: 10 }}
                                                      startIcon={<DeleteIcon />}
                                                      variant="contained"
                                                      color="warning"
                                                      size="small" onClick={() => {
                                                        deleteDeduction(index)
                                                        setIsConfirmingDelete(false)
                                                        setIsConfirmingDeleteIndex(null)
                                                      }}>
                                                      Confirm Deletion
                                                    </Button>
                                                    <Button style={{ marginRight: 10 }}
                                                      startIcon={<DeleteIcon />}
                                                      variant="contained"
                                                      color="primary"
                                                      size="small" onClick={() => {
                                                        setIsConfirmingDelete(false)
                                                        setIsConfirmingDeleteIndex(null)
                                                        resetForm()
                                                      }}>
                                                      Cancel
                                                    </Button>
                                                  </div>
                                                ) : (
                                                  <div>
                                                    {deduction.deductionstartmonth !== deduction.deductionendmonth ? (
                                                      <>
                                                        <PermissionsGate scopes={[SCOPES.cancreaterecurringdeduction]}>
                                                          <div>
                                                            <IconButton disabled={isEditingSingleDeduction || deductionType} style={{ marginRight: 10 }} color="warning" edge="end" aria-label="delete" onClick={() => {
                                                              setIsConfirmingDelete(true)
                                                              setIsConfirmingDeleteIndex(index)
                                                            }
                                                            }>
                                                              <DeleteIcon />
                                                            </IconButton>
                                                            <IconButton disabled={isEditingSingleDeduction || deductionType} color="primary" edge="end" aria-label="edit" onClick={() => startEditDeduction(index)}>
                                                              <EditIcon />
                                                            </IconButton>

                                                          </div>
                                                        </PermissionsGate></>
                                                    ) : (
                                                      <>
                                                        <PermissionsGate scopes={[SCOPES.cancreateonetimededuction]}>
                                                          <div>
                                                            <IconButton disabled={isEditingSingleDeduction || deductionType} style={{ marginRight: 10 }} color="warning" edge="end" aria-label="delete" onClick={() => {
                                                              setIsConfirmingDelete(true)
                                                              setIsConfirmingDeleteIndex(index)
                                                            }}>
                                                              <DeleteIcon />
                                                            </IconButton>
                                                            <IconButton disabled={isEditingSingleDeduction || deductionType} color="primary" edge="end" aria-label="edit" onClick={() => startEditDeduction(index)}>
                                                              <EditIcon />
                                                            </IconButton>

                                                          </div>
                                                        </PermissionsGate></>

                                                    )}

                                                  </div>
                                                )}
                                              </ListItemSecondaryAction>
                                            </ListItem>
                                            <Divider component="li" />
                                          </div>
                                        )
                                      }

                                    }
                                  })}
                                </List>

                              </Grid>

                              <Grid item xs={6}>

                                <List>
                                  {deductions?.map((deduction, index) => {
                                    if (deduction.deductionendmonth !== deduction.deductionstartmonth) {
                                      if (isEditingSingleDeduction && editingIndex === index) {
                                        // Render form for editing this specific deduction
                                        return (
                                          <ListItem key={index} component="div" style={{ flexDirection: 'column', alignItems: 'flex-start', marginTop: 10 }}>
                                            <form>
                                              {/* Deduction Type */}
                                              <FormControl size="small" style={{ maxWidth: 300, marginTop: 15, minWidth: 200, marginRight: 9 }}>
                                                <InputLabel id={`deduction-type-label-${index}`}>Deduction Type</InputLabel>
                                                <Select
                                                  labelId={`deduction-type-label-${index}`}
                                                  id={`deductiontype-${index}`}
                                                  name="deductiontype"
                                                  value={form.deductiontype}
                                                  label="Deduction Type"
                                                  onChange={handleFormChange}
                                                  style={{
                                                    marginTop: 5,
                                                  }}
                                                >
                                                  {deductionTypes.filter(deductionType => deductionType.clientid === school.clientid).map((deductionType) => (
                                                    <MenuItem key={deductionType.id} value={deductionType.id}>{deductionType.deductiontype}</MenuItem>
                                                  ))}
                                                </Select>
                                              </FormControl>


                                              <FormControl size="small" style={{ maxWidth: 300, marginTop: 15, minWidth: 200, marginRight: 9 }}>
                                                <InputLabel id={`service-provider-label-${index}`}>Service Provider</InputLabel>
                                                <Select
                                                  labelId={`service-provider-label-${index}`}
                                                  id={`deductionserviceprovider-${index}`}
                                                  name="deductionserviceprovider"
                                                  value={form.deductionserviceprovider}
                                                  label="Service Provider"
                                                  onChange={handleFormChange}
                                                  style={{
                                                    marginTop: 5,
                                                  }}
                                                >
                                                  {serviceProviders.filter(provider => provider.clientid === school.clientid).map((provider) => (
                                                    <MenuItem key={provider.id} value={provider.id}>{provider.serviceprovider}</MenuItem>
                                                  ))}
                                                </Select>
                                              </FormControl>

                                              <TextField
                                                size="small"
                                                InputProps={{
                                                  inputProps: { min: 0 },
                                                  startAdornment: <InputAdornment position="start">K</InputAdornment>,
                                                }}
                                                style={{ maxWidth: 300, marginTop: 20, minWidth: 100, marginRight: 9 }}
                                                label="Deduction Amount"
                                                name="deductionamount"
                                                value={form.deductionamount}
                                                onChange={handleFormChange}
                                                type="number"
                                              />
                                              {/* Term End - only if recurring */}
                                              {form.deductionendmonth !== form.deductionstartmonth ? (
                                                <>
                                                  <FormControl size="small" style={{ maxWidth: 200, marginTop: 15, minWidth: 100, marginRight: 9 }}>
                                                    <InputLabel id={`deduction-start-time-label-${index}`}>Start Month</InputLabel>
                                                    <Select
                                                      labelId={`deduction-start-time-label-${index}`}
                                                      id={`deductionstartmonth-${index}`}
                                                      name="deductionstartmonth"
                                                      value={form.deductionstartmonth}
                                                      label="Deduction Start Time"
                                                      onChange={handleFormChange}

                                                      style={{
                                                        marginTop: 5,
                                                      }}
                                                    >
                                                      {selectableMonths.map((month, monthIndex) => (
                                                        <MenuItem key={monthIndex} value={month.value}>{month.label}</MenuItem>
                                                      ))}
                                                    </Select>
                                                  </FormControl>
                                                  <FormControl size="small" style={{ maxWidth: 200, marginTop: 15, minWidth: 100, marginRight: 9 }}>
                                                    <InputLabel id={`deduction-end-time-label-${index}`}>End Month</InputLabel>
                                                    <Select
                                                      labelId={`deduction-end-time-label-${index}`}
                                                      id={`deductionendmonth-${index}`}
                                                      name="deductionendmonth"
                                                      value={form.deductionendmonth}
                                                      label="Deduction End Time"
                                                      onChange={handleFormChange}

                                                      style={{
                                                        marginTop: 5,
                                                      }}
                                                    >
                                                      {selectableMonths.filter(month => month.value > form.deductionstartmonth).map((month, monthIndex) => (
                                                        <MenuItem key={monthIndex} value={month.value}>{month.label}</MenuItem>
                                                      ))}
                                                    </Select>
                                                  </FormControl>
                                                  <TextField
                                                    disabled={true}
                                                    size="small"
                                                    style={{ maxWidth: 200, marginTop: 15, minWidth: 100, marginRight: 9 }}
                                                    label="Monthly Deduction Amount"
                                                    name="monthlyDeductionAmount"
                                                    value={monthlyDeductionAmount}
                                                    InputProps={{
                                                      inputProps: {
                                                        min: 0,
                                                        style: { height: '100px' }, // Adjust this value as needed
                                                      },
                                                      startAdornment: (
                                                        <InputAdornment position="start">K</InputAdornment>
                                                      ),
                                                      style: {
                                                        color: 'black', // Set the text color
                                                        // Any other styling you want to apply to the input
                                                      }
                                                    }}
                                                    InputLabelProps={{
                                                      style: {
                                                        color: 'black', // You can also style the label similarly if needed
                                                      }
                                                    }}
                                                  // Since it's a calculated and displayed field, no need for onChange
                                                  />
                                                </>
                                              ) :
                                                (<>
                                                  <TextField
                                                    disabled={true}
                                                    size="small"
                                                    style={{ maxWidth: 300, marginTop: 20, minWidth: 100, marginRight: 9 }}
                                                    label="Month"
                                                    name="deductionstartmonth"
                                                    value={form.deductionstartmonth}
                                                    onChange={handleFormChange}
                                                  />
                                                </>)}

                                              {/* Save and Cancel Buttons */}
                                              <div
                                                style={{ marginTop: 10, marginBottom: 5 }}
                                              >
                                                <Button style={{ marginRight: 10 }}
                                                  variant="contained"
                                                  color="primary"
                                                  type="submit"
                                                  size="small" onClick={() => {
                                                    handleEditDeductionSubmit(index)
                                                    setIsEditingSingleDeduction(false)
                                                  }}>
                                                  Save
                                                </Button>
                                                <Button style={{ marginRight: 10 }}
                                                  startIcon={<DeleteIcon />}
                                                  variant="contained"
                                                  color="warning"
                                                  size="small" onClick={() => {
                                                    setIsEditingSingleDeduction(false)
                                                    resetForm()
                                                  }}>
                                                  Cancel
                                                </Button>
                                              </div>
                                            </form>
                                          </ListItem>
                                        );
                                      } else {
                                        return (
                                          <div>
                                            <ListItem key={index} style={{ borderBottom: '1px solid #ccc', paddingBottom: '8px', paddingTop: '8px' }}>
                                              <ListItemText
                                                primary={
                                                  <>
                                                    <Typography component="span" variant="body1">
                                                      {deduction.deductionstartmonth === deduction.deductionendmonth ?
                                                        "One-time Deduction" :
                                                        "Recurring Deduction"}
                                                    </Typography>
                                                    <Typography component="span" variant="body2" display="block">
                                                      {deductionTypes?.find(dt => dt.id === deduction.deductiontype)?.deductiontype ||
                                                        'Unknown Deduction Type'}
                                                      {' - '}
                                                      {serviceProviders?.find(dt => dt.id === deduction.deductionserviceprovider)?.serviceprovider ||
                                                        'Unknown Service Provider Type'}
                                                    </Typography>
                                                  </>
                                                }
                                                secondary={
                                                  <>
                                                    <Typography component="span" variant="body2">
                                                      Amount: K{deduction.deductionamount}
                                                    </Typography>
                                                    <Typography component="span" variant="body2" display="block">
                                                      {deduction.deductionstartmonth === deduction.deductionendmonth ?
                                                        `Month: ${deduction.deductionstartmonth}` :
                                                        `Start Month: ${deduction.deductionstartmonth}, End Month: ${deduction.deductionendmonth}`}
                                                    </Typography>
                                                  </>
                                                }
                                              />
                                              <ListItemSecondaryAction>
                                                {isConfirmingDelete && index === isConfirmingDeleteIndex ? (
                                                  <div>
                                                    <Button style={{ marginRight: 10 }}
                                                      startIcon={<DeleteIcon />}
                                                      variant="contained"
                                                      color="warning"
                                                      size="small" onClick={() => {
                                                        deleteDeduction(index)
                                                        setIsConfirmingDelete(false)
                                                        setIsConfirmingDeleteIndex(null)
                                                      }}>
                                                      Confirm Deletion
                                                    </Button>
                                                    <Button style={{ marginRight: 10 }}
                                                      startIcon={<DeleteIcon />}
                                                      variant="contained"
                                                      color="primary"
                                                      size="small" onClick={() => {
                                                        setIsConfirmingDelete(false)
                                                        setIsConfirmingDeleteIndex(null)
                                                        resetForm()
                                                      }}>
                                                      Cancel
                                                    </Button>
                                                  </div>
                                                ) : (
                                                  <div>
                                                    {deduction.deductionstartmonth !== deduction.deductionendmonth ? (
                                                      <>
                                                        <PermissionsGate scopes={[SCOPES.cancreaterecurringdeduction]}>
                                                          <div>
                                                            <IconButton disabled={isEditingSingleDeduction || deductionType} style={{ marginRight: 10 }} color="warning" edge="end" aria-label="delete" onClick={() => {
                                                              setIsConfirmingDelete(true)
                                                              setIsConfirmingDeleteIndex(index)
                                                            }
                                                            }>
                                                              <DeleteIcon />
                                                            </IconButton>
                                                            <IconButton disabled={isEditingSingleDeduction || deductionType} color="primary" edge="end" aria-label="edit" onClick={() => startEditDeduction(index)}>
                                                              <EditIcon />
                                                            </IconButton>

                                                          </div>
                                                        </PermissionsGate></>
                                                    ) : (
                                                      <>
                                                        <PermissionsGate scopes={[SCOPES.cancreateonetimededuction]}>
                                                          <div>
                                                            <IconButton disabled={isEditingSingleDeduction || deductionType} style={{ marginRight: 10 }} color="warning" edge="end" aria-label="delete" onClick={() => {
                                                              setIsConfirmingDelete(true)
                                                              setIsConfirmingDeleteIndex(index)
                                                            }}>
                                                              <DeleteIcon />
                                                            </IconButton>
                                                            <IconButton disabled={isEditingSingleDeduction || deductionType} color="primary" edge="end" aria-label="edit" onClick={() => startEditDeduction(index)}>
                                                              <EditIcon />
                                                            </IconButton>

                                                          </div>
                                                        </PermissionsGate></>

                                                    )}

                                                  </div>
                                                )}
                                              </ListItemSecondaryAction>
                                            </ListItem>
                                            <Divider component="li" />
                                          </div>
                                        )
                                      }
                                    }

                                  })}
                                </List>


                              </Grid>
                            </Grid>
                          </Grid>

                        </div>)}
                    </DialogContent>
                  </div>
                </>
              </Dialog>
              <Dialog PaperProps={{
                style: { minWidth: "90vw" }, // Adjust the minWidth to your preference
              }} open={openAllowances} onClose={() => {
                setOpenAllowancesModal(false)
              }
              }>
                <>
                  <div style={{ minHeight: "90vh", minWidth: "90vw", backgroundColor: "#fff", borderRadius: 15 }}>
                    <DialogTitle style={{
                      minWidth: "90vw",
                      backgroundColor: "#5E35B1",
                      color: "#fff",
                      marginBottom: 10
                    }} > Employee Allowances - {selectedEmployee.firstname} {selectedEmployee.lastname}</DialogTitle>
                    <DialogContent>

                      {isEditngAllowances ? (
                        <CircularProgress></CircularProgress>) : (
                        <div >
                          {(
                            <div>
                              <div >
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="stretch"
                                  spacing={2}
                                >
                                  <Grid container item xs={12} spacing={2}>
                                    <Grid item xs={6}>

                                      <PermissionsGate scopes={[SCOPES.cancreateonetimeallowance]}>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          disabled={isEditingSingleAllowance || allowanceType} style={{ marginRight: 15 }} onClick={() => setAllowanceType('oneTime')}>
                                          Create One-Time Allowance
                                        </Button>
                                      </PermissionsGate>

                                    </Grid>

                                    <Grid item xs={6}>

                                      <PermissionsGate scopes={[SCOPES.cancreaterecurringallowance]}>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          disabled={isEditingSingleAllowance || allowanceType} onClick={() => setAllowanceType('recurring')}>
                                          Create Recurring Allowance
                                        </Button>
                                      </PermissionsGate>


                                    </Grid>
                                  </Grid>
                                </Grid>


                              </div>

                              {allowanceType && (
                                <form style={{ marginBottom: -60, marginTop: 10 }} onSubmit={addAllowance}>
                                  <FormControl size="small" style={{ maxWidth: 300, marginTop: 15, minWidth: 200, flex: 1, marginRight: 9 }}>
                                    <InputLabel id="allowance-type-label">Allowance Type</InputLabel>
                                    <Select
                                      style={{
                                        marginTop: 5,
                                      }}
                                      labelId="allowance-type-label"
                                      id="allowancetype"
                                      name="allowancetype"
                                      value={allowanceForm.allowancetype}
                                      label="Allowance Type"
                                      onChange={handleFormChangeAllowance}
                                    >
                                      {allowanceTypes?.map((allowanceType, index) => (
                                        <MenuItem key={allowanceType.id} value={allowanceType.id}>{allowanceType.allowancetype}</MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>

                                  <TextField
                                    disabled={true}
                                    size="small"
                                    style={{ maxWidth: 300, marginTop: 20, minWidth: 100, flex: 1, marginRight: 9, minHeight: 100 }}
                                    label="Service Provider"
                                    name="allowanceserviceprovider"
                                    value={school.clientname}
                                    onChange={handleFormChangeAllowance}
                                  />
                                  {/* <FormControl size="small" style={{ maxWidth: 300, marginTop: 15, minWidth: 200, flex: 1, marginRight: 9 }}>
                                    <InputLabel id="service-provider-label">Service Provider</InputLabel>
                                    <Select
                                      style={{
                                        marginTop: 5,
                                      }}
                                      labelId="service-provider-label"
                                      id="allowanceserviceprovider"
                                      name="allowanceserviceprovider"
                                      value={allowanceForm.allowanceserviceprovider}
                                      label="Service Provider"
                                      onChange={handleFormChangeAllowance}
                                    >
                                      {serviceProviders.map((provider, index) => (
                                        <MenuItem key={provider.id} value={provider.id}>{provider.serviceprovider}</MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl> */}


                                  <TextField
                                    size="small"
                                    InputProps={{
                                      inputProps: {
                                        min: 0,
                                        style: { height: '100px' }, // Adjust this value as needed
                                      },
                                      startAdornment: (
                                        <InputAdornment position="start">K</InputAdornment>
                                      ),
                                    }}
                                    style={{ maxWidth: 300, marginTop: 20, minWidth: 100, flex: 1, marginRight: 9, minHeight: 100 }}
                                    label="Allowance Amount"
                                    name="allowanceamount"
                                    value={allowanceForm.allowanceamount}
                                    onChange={handleFormChangeAllowance}
                                    type="number"
                                  />



                                  {allowanceType === 'recurring' ? (
                                    <>
                                      <FormControl size="small" style={{ maxWidth: 200, marginTop: 15, minWidth: 100, flex: 1, marginRight: 9 }}>
                                        <InputLabel id="allowance-start-time-label">Start Month</InputLabel>
                                        <Select

                                          style={{
                                            marginTop: 5,
                                          }}
                                          labelId="allowance-start-time-label"
                                          id="allowancestartmonth"
                                          name="allowancestartmonth"
                                          value={allowanceForm.allowancestartmonth}
                                          label="Allowance Start Month"
                                          onChange={handleFormChangeAllowance}
                                        >
                                          {selectableMonths.map((month) => (
                                            <MenuItem style={{ width: 200 }} key={month.value} value={month.value}>{month.label}</MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                      <FormControl size="small" style={{ maxWidth: 200, marginTop: 15, minWidth: 100, flex: 1, marginRight: 9 }}>
                                        <InputLabel
                                          id="allowance-end-time-label">End Month</InputLabel>
                                        <Select
                                          style={{
                                            marginTop: 5,
                                          }}
                                          labelId="allowance-end-time-label"
                                          id="allowanceendmonth"
                                          name="allowanceendmonth"
                                          value={allowanceForm.allowanceendmonth}
                                          label="Allowance End Month"
                                          onChange={handleFormChangeAllowance}
                                        >
                                          {selectableMonths.filter(month => month.value > allowanceForm.allowancestartmonth).map((month) => (
                                            <MenuItem key={month.value} value={month.value}>{month.label}</MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                      <TextField
                                        disabled={true}
                                        size="small"
                                        style={{ maxWidth: 300, marginTop: 20, minWidth: 100, flex: 1, marginRight: 9, minHeight: 100 }}
                                        label="Monthly Allowance Amount"
                                        name="monthlyAllowanceAmount"
                                        value={monthlyAllowanceAmount}
                                        InputProps={{
                                          inputProps: {
                                            min: 0,
                                            style: { height: '100px' }, // Adjust this value as needed
                                          },
                                          startAdornment: (
                                            <InputAdornment position="start">K</InputAdornment>
                                          ),
                                          style: {
                                            color: 'black', // Set the text color
                                            // Any other styling you want to apply to the input
                                          }
                                        }}
                                        InputLabelProps={{
                                          style: {
                                            color: 'black', // You can also style the label similarly if needed
                                          }
                                        }}
                                      // Since it's a calculated and displayed field, no need for onChange
                                      />
                                    </>
                                  ) :
                                    (
                                      <>
                                        <TextField
                                          disabled={true}
                                          size="small"
                                          style={{ maxWidth: 300, marginTop: 20, minWidth: 100, flex: 1, marginRight: 9, minHeight: 100 }}
                                          label="Month"
                                          name="allowancestartmonth"
                                          value={nextMonth}
                                          onChange={handleFormChangeAllowance}
                                        />
                                      </>
                                    )}

                                  <Button style={{ marginTop: 20, marginRight: 5 }}
                                    variant="contained"
                                    color="success"
                                    size="small"
                                    type="submit">Create</Button>
                                  <Button style={{ marginTop: 20 }} variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                      setAllowanceType(null)
                                      resetFormAllowance()
                                    }}>
                                    Cancel
                                  </Button>
                                </form>
                              )}
                            </div>)}


                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="stretch"
                            spacing={2}
                          >
                            <Grid container item xs={12} spacing={2}>
                              <Grid item xs={6}>

                                <List>
                                  {allowances?.map((allowance, index) => {
                                    if (allowance.allowanceendmonth === allowance.allowancestartmonth) {
                                      if (isEditingSingleAllowance && editingIndexAllowance === index) {
                                        // Render form for editing this specific deduction
                                        return (
                                          <ListItem key={index} component="div" style={{ flexDirection: 'column', alignItems: 'flex-start', marginTop: 10 }}>
                                            <form>
                                              {/* Deduction Type */}
                                              <FormControl size="small" style={{ maxWidth: 300, marginTop: 15, minWidth: 200, marginRight: 9 }}>
                                                <InputLabel id={`allowance-type-label-${index}`}>Allowance Type</InputLabel>
                                                <Select
                                                  labelId={`allowance-type-label-${index}`}
                                                  id={`allowancetype-${index}`}
                                                  name="allowancetype"
                                                  value={allowanceForm.allowancetype}
                                                  label="Allowance Type"
                                                  onChange={handleFormChangeAllowance}
                                                  style={{
                                                    marginTop: 5,
                                                  }}
                                                >
                                                  {allowanceTypes?.filter(allowanceType => allowanceType.clientid === school.clientid).map((allowanceType) => (
                                                    <MenuItem key={allowanceType.id} value={allowanceType.id}>{allowanceType.allowancetype}</MenuItem>
                                                  ))}
                                                </Select>
                                              </FormControl>

                                              <TextField
                                                disabled={true}
                                                size="small"
                                                style={{ maxWidth: 300, marginTop: 20, minWidth: 100, marginRight: 9 }}
                                                label="Service Provider"
                                                name="allowanceserviceprovider"
                                                value={school.clientname}
                                                onChange={handleFormChangeAllowance}
                                              />
                                              {/* 
                                      <FormControl size="small" style={{ maxWidth: 300, marginTop: 15, minWidth: 200, marginRight: 9 }}>
                                        <InputLabel id={`service-provider-label-${index}`}>Service Provider</InputLabel>
                                        <Select
                                          labelId={`service-provider-label-${index}`}
                                          id={`allowanceserviceprovider-${index}`}
                                          name="allowanceserviceprovider"
                                          value={allowanceForm.allowanceserviceprovider}
                                          label="Service Provider"
                                          onChange={handleFormChangeAllowance}
                                          style={{
                                            marginTop: 5,
                                          }}
                                        >
                                          {serviceProviders.filter(provider => provider.clientid === school.clientid).map((provider) => (
                                            <MenuItem key={provider.id} value={provider.id}>{provider.serviceprovider}</MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl> */}

                                              <TextField
                                                size="small"
                                                InputProps={{
                                                  inputProps: { min: 0 },
                                                  startAdornment: <InputAdornment position="start">K</InputAdornment>,
                                                }}
                                                style={{ maxWidth: 300, marginTop: 20, minWidth: 100, marginRight: 9 }}
                                                label="Allowance Amount"
                                                name="allowanceamount"
                                                value={allowanceForm.allowanceamount}
                                                onChange={handleFormChangeAllowance}
                                                type="number"
                                              />
                                              {/* Term End - only if recurring */}
                                              {allowanceForm.allowanceendmonth !== allowanceForm.allowancestartmonth ? (
                                                <>
                                                  <FormControl size="small" style={{ maxWidth: 200, marginTop: 15, minWidth: 100, marginRight: 9 }}>
                                                    <InputLabel id={`allowance-start-time-label-${index}`}>Start Month</InputLabel>
                                                    <Select
                                                      labelId={`allowance-start-time-label-${index}`}
                                                      id={`allowancestartmonth-${index}`}
                                                      name="allowancestartmonth"
                                                      value={allowanceForm.allowancestartmonth}
                                                      label="Allowance Start Time"
                                                      onChange={handleFormChangeAllowance}

                                                      style={{
                                                        marginTop: 5,
                                                      }}
                                                    >
                                                      {selectableMonths.map((month, monthIndex) => (
                                                        <MenuItem key={monthIndex} value={month.value}>{month.label}</MenuItem>
                                                      ))}
                                                    </Select>
                                                  </FormControl>
                                                  <FormControl size="small" style={{ maxWidth: 200, marginTop: 15, minWidth: 100, marginRight: 9 }}>
                                                    <InputLabel id={`allowance-end-time-label-${index}`}>End Month</InputLabel>
                                                    <Select
                                                      labelId={`alowance-end-time-label-${index}`}
                                                      id={`allowanceendmonth-${index}`}
                                                      name="allowanceendmonth"
                                                      value={allowanceForm.allowanceendmonth}
                                                      label="Allowance End Time"
                                                      onChange={handleFormChangeAllowance}

                                                      style={{
                                                        marginTop: 5,
                                                      }}
                                                    >
                                                      {selectableMonths.filter(month => month.value > allowanceForm.allowancestartmonth).map((month, monthIndex) => (
                                                        <MenuItem key={monthIndex} value={month.value}>{month.label}</MenuItem>
                                                      ))}
                                                    </Select>
                                                  </FormControl>
                                                  <TextField
                                                    disabled={true}
                                                    size="small"
                                                    style={{ maxWidth: 200, marginTop: 15, minWidth: 100, marginRight: 9 }}
                                                    label="Monthly Allowance Amount"
                                                    name="monthlyAllowanceAmount"
                                                    value={monthlyAllowanceAmount}
                                                    InputProps={{
                                                      inputProps: {
                                                        min: 0,
                                                        style: { height: '100px' }, // Adjust this value as needed
                                                      },
                                                      startAdornment: (
                                                        <InputAdornment position="start">K</InputAdornment>
                                                      ),
                                                      style: {
                                                        color: 'black', // Set the text color
                                                        // Any other styling you want to apply to the input
                                                      }
                                                    }}
                                                    InputLabelProps={{
                                                      style: {
                                                        color: 'black', // You can also style the label similarly if needed
                                                      }
                                                    }}
                                                  // Since it's a calculated and displayed field, no need for onChange
                                                  />
                                                </>
                                              ) :
                                                (<>
                                                  <TextField
                                                    disabled={true}
                                                    size="small"
                                                    style={{ maxWidth: 300, marginTop: 20, minWidth: 100, marginRight: 9 }}
                                                    label="Month"
                                                    name="allowancestartmonth"
                                                    value={allowanceForm.allowancestartmonth}
                                                    onChange={handleFormChangeAllowance}
                                                  />
                                                </>)}

                                              {/* Save and Cancel Buttons */}
                                              <div
                                                style={{ marginTop: 10, marginBottom: 5 }}
                                              >
                                                <Button style={{ marginRight: 10 }}
                                                  variant="contained"
                                                  color="primary"
                                                  type="submit"
                                                  size="small" onClick={() => {
                                                    handleEditAllowanceSubmit(index)
                                                    setIsEditingSingleAllowance(false)
                                                  }}>
                                                  Save
                                                </Button>
                                                <Button style={{ marginRight: 10 }}
                                                  startIcon={<DeleteIcon />}
                                                  variant="contained"
                                                  color="warning"
                                                  size="small" onClick={() => {
                                                    setIsEditingSingleAllowance(false)
                                                    resetFormAllowance()
                                                  }}>
                                                  Cancel
                                                </Button>
                                              </div>
                                            </form>
                                          </ListItem>
                                        );
                                      } else {
                                        return (
                                          <div>
                                            <ListItem key={index} style={{ borderBottom: '1px solid #ccc', paddingBottom: '8px', paddingTop: '8px' }}>
                                              <ListItemText
                                                primary={
                                                  <>
                                                    <Typography component="span" variant="body1">
                                                      {allowance.allowancestartmonth === allowance.allowanceendmonth ?
                                                        "One-time Allowance" :
                                                        "Recurring Allowance"}
                                                    </Typography>
                                                    <Typography component="span" variant="body2" display="block">
                                                      {allowanceTypes?.find(dt => dt.id === allowance.allowancetype)?.allowancetype ||
                                                        'Unknown Allowance Type'}
                                                      {' - '}
                                                      {school.clientname}
                                                    </Typography>
                                                  </>
                                                }
                                                secondary={
                                                  <>
                                                    <Typography component="span" variant="body2">
                                                      Amount: K{allowance.allowanceamount}
                                                    </Typography>
                                                    <Typography component="span" variant="body2" display="block">
                                                      {allowance.allowancestartmonth === allowance.allowanceendmonth ?
                                                        `Month: ${allowance.allowancestartmonth}` :
                                                        `Start Month: ${allowance.allowancestartmonth}, End Month: ${allowance.allowanceendmonth}`}
                                                    </Typography>
                                                  </>
                                                }
                                              />
                                              <ListItemSecondaryAction>
                                                {isConfirmingDeleteAllowance && index === isConfirmingDeleteIndexAllowance ? (
                                                  <div>
                                                    <Button style={{ marginRight: 10 }}
                                                      startIcon={<DeleteIcon />}
                                                      variant="contained"
                                                      color="warning"
                                                      size="small" onClick={() => {
                                                        deleteAllowance(index)
                                                        setIsConfirmingDeleteAllowance(false)
                                                        setIsConfirmingDeleteIndexAllowance(null)
                                                      }}>
                                                      Confirm Deletion
                                                    </Button>
                                                    <Button style={{ marginRight: 10 }}
                                                      startIcon={<DeleteIcon />}
                                                      variant="contained"
                                                      color="primary"
                                                      size="small" onClick={() => {
                                                        setIsConfirmingDeleteAllowance(false)
                                                        setIsConfirmingDeleteIndexAllowance(null)
                                                        resetFormAllowance()
                                                      }}>
                                                      Cancel
                                                    </Button>
                                                  </div>
                                                ) : (
                                                  <div>
                                                    {allowance.allowancestartmonth !== allowance.allowanceendmonth ? (
                                                      <>
                                                        <PermissionsGate scopes={[SCOPES.cancreaterecurringallowance]}>
                                                          <div>
                                                            <IconButton disabled={isEditingSingleAllowance || allowanceType} style={{ marginRight: 10 }} color="warning" edge="end" aria-label="delete" onClick={() => {
                                                              setIsConfirmingDeleteAllowance(true)
                                                              setIsConfirmingDeleteIndexAllowance(index)
                                                            }}>
                                                              <DeleteIcon />
                                                            </IconButton>
                                                            <IconButton disabled={isEditingSingleAllowance || allowanceType} color="primary" edge="end" aria-label="edit" onClick={() => startEditAllowance(index)}>
                                                              <EditIcon />
                                                            </IconButton>

                                                          </div>
                                                        </PermissionsGate>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <PermissionsGate scopes={[SCOPES.cancreateonetimeallowance]}>
                                                          <div>
                                                            <IconButton disabled={isEditingSingleAllowance || allowanceType} style={{ marginRight: 10 }} color="warning" edge="end" aria-label="delete" onClick={() => {
                                                              setIsConfirmingDeleteAllowance(true)
                                                              setIsConfirmingDeleteIndexAllowance(index)
                                                            }}>
                                                              <DeleteIcon />
                                                            </IconButton>
                                                            <IconButton disabled={isEditingSingleAllowance || allowanceType} color="primary" edge="end" aria-label="edit" onClick={() => startEditAllowance(index)}>
                                                              <EditIcon />
                                                            </IconButton>

                                                          </div>
                                                        </PermissionsGate>
                                                      </>

                                                    )}

                                                  </div>
                                                )}
                                              </ListItemSecondaryAction>
                                            </ListItem>
                                            <Divider component="li" />
                                          </div>
                                        )
                                      }
                                    }
                                  })}
                                </List>

                              </Grid>

                              <Grid item xs={6}>

                                <List>
                                  {allowances?.map((allowance, index) => {
                                    if (allowance.allowanceendmonth !== allowance.allowancestartmonth) {
                                      if (isEditingSingleAllowance && editingIndexAllowance === index) {
                                        // Render form for editing this specific deduction
                                        return (
                                          <ListItem key={index} component="div" style={{ flexDirection: 'column', alignItems: 'flex-start', marginTop: 10 }}>
                                            <form>
                                              {/* Deduction Type */}
                                              <FormControl size="small" style={{ maxWidth: 300, marginTop: 15, minWidth: 200, marginRight: 9 }}>
                                                <InputLabel id={`allowance-type-label-${index}`}>Allowance Type</InputLabel>
                                                <Select
                                                  labelId={`allowance-type-label-${index}`}
                                                  id={`allowancetype-${index}`}
                                                  name="allowancetype"
                                                  value={allowanceForm.allowancetype}
                                                  label="Allowance Type"
                                                  onChange={handleFormChangeAllowance}
                                                  style={{
                                                    marginTop: 5,
                                                  }}
                                                >
                                                  {allowanceTypes?.filter(allowanceType => allowanceType.clientid === school.clientid).map((allowanceType) => (
                                                    <MenuItem key={allowanceType.id} value={allowanceType.id}>{allowanceType.allowancetype}</MenuItem>
                                                  ))}
                                                </Select>
                                              </FormControl>

                                              <TextField
                                                disabled={true}
                                                size="small"
                                                style={{ maxWidth: 300, marginTop: 20, minWidth: 100, marginRight: 9 }}
                                                label="Service Provider"
                                                name="allowanceserviceprovider"
                                                value={school.clientname}
                                                onChange={handleFormChangeAllowance}
                                              />
                                              {/* 
                                      <FormControl size="small" style={{ maxWidth: 300, marginTop: 15, minWidth: 200, marginRight: 9 }}>
                                        <InputLabel id={`service-provider-label-${index}`}>Service Provider</InputLabel>
                                        <Select
                                          labelId={`service-provider-label-${index}`}
                                          id={`allowanceserviceprovider-${index}`}
                                          name="allowanceserviceprovider"
                                          value={allowanceForm.allowanceserviceprovider}
                                          label="Service Provider"
                                          onChange={handleFormChangeAllowance}
                                          style={{
                                            marginTop: 5,
                                          }}
                                        >
                                          {serviceProviders.filter(provider => provider.clientid === school.clientid).map((provider) => (
                                            <MenuItem key={provider.id} value={provider.id}>{provider.serviceprovider}</MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl> */}

                                              <TextField
                                                size="small"
                                                InputProps={{
                                                  inputProps: { min: 0 },
                                                  startAdornment: <InputAdornment position="start">K</InputAdornment>,
                                                }}
                                                style={{ maxWidth: 300, marginTop: 20, minWidth: 100, marginRight: 9 }}
                                                label="Allowance Amount"
                                                name="allowanceamount"
                                                value={allowanceForm.allowanceamount}
                                                onChange={handleFormChangeAllowance}
                                                type="number"
                                              />
                                              {/* Term End - only if recurring */}
                                              {allowanceForm.allowanceendmonth !== allowanceForm.allowancestartmonth ? (
                                                <>
                                                  <FormControl size="small" style={{ maxWidth: 200, marginTop: 15, minWidth: 100, marginRight: 9 }}>
                                                    <InputLabel id={`allowance-start-time-label-${index}`}>Start Month</InputLabel>
                                                    <Select
                                                      labelId={`allowance-start-time-label-${index}`}
                                                      id={`allowancestartmonth-${index}`}
                                                      name="allowancestartmonth"
                                                      value={allowanceForm.allowancestartmonth}
                                                      label="Allowance Start Time"
                                                      onChange={handleFormChangeAllowance}

                                                      style={{
                                                        marginTop: 5,
                                                      }}
                                                    >
                                                      {selectableMonths.map((month, monthIndex) => (
                                                        <MenuItem key={monthIndex} value={month.value}>{month.label}</MenuItem>
                                                      ))}
                                                    </Select>
                                                  </FormControl>
                                                  <FormControl size="small" style={{ maxWidth: 200, marginTop: 15, minWidth: 100, marginRight: 9 }}>
                                                    <InputLabel id={`allowance-end-time-label-${index}`}>End Month</InputLabel>
                                                    <Select
                                                      labelId={`alowance-end-time-label-${index}`}
                                                      id={`allowanceendmonth-${index}`}
                                                      name="allowanceendmonth"
                                                      value={allowanceForm.allowanceendmonth}
                                                      label="Allowance End Time"
                                                      onChange={handleFormChangeAllowance}

                                                      style={{
                                                        marginTop: 5,
                                                      }}
                                                    >
                                                      {selectableMonths.filter(month => month.value > allowanceForm.allowancestartmonth).map((month, monthIndex) => (
                                                        <MenuItem key={monthIndex} value={month.value}>{month.label}</MenuItem>
                                                      ))}
                                                    </Select>
                                                  </FormControl>
                                                  <TextField
                                                    disabled={true}
                                                    size="small"
                                                    style={{ maxWidth: 200, marginTop: 15, minWidth: 100, marginRight: 9 }}
                                                    label="Monthly Allowance Amount"
                                                    name="monthlyAllowanceAmount"
                                                    value={monthlyAllowanceAmount}
                                                    InputProps={{
                                                      inputProps: {
                                                        min: 0,
                                                        style: { height: '100px' }, // Adjust this value as needed
                                                      },
                                                      startAdornment: (
                                                        <InputAdornment position="start">K</InputAdornment>
                                                      ),
                                                      style: {
                                                        color: 'black', // Set the text color
                                                        // Any other styling you want to apply to the input
                                                      }
                                                    }}
                                                    InputLabelProps={{
                                                      style: {
                                                        color: 'black', // You can also style the label similarly if needed
                                                      }
                                                    }}
                                                  // Since it's a calculated and displayed field, no need for onChange
                                                  />
                                                </>
                                              ) :
                                                (<>
                                                  <TextField
                                                    disabled={true}
                                                    size="small"
                                                    style={{ maxWidth: 300, marginTop: 20, minWidth: 100, marginRight: 9 }}
                                                    label="Month"
                                                    name="allowancestartmonth"
                                                    value={allowanceForm.allowancestartmonth}
                                                    onChange={handleFormChangeAllowance}
                                                  />
                                                </>)}

                                              {/* Save and Cancel Buttons */}
                                              <div
                                                style={{ marginTop: 10, marginBottom: 5 }}
                                              >
                                                <Button style={{ marginRight: 10 }}
                                                  variant="contained"
                                                  color="primary"
                                                  type="submit"
                                                  size="small" onClick={() => {
                                                    handleEditAllowanceSubmit(index)
                                                    setIsEditingSingleAllowance(false)
                                                  }}>
                                                  Save
                                                </Button>
                                                <Button style={{ marginRight: 10 }}
                                                  startIcon={<DeleteIcon />}
                                                  variant="contained"
                                                  color="warning"
                                                  size="small" onClick={() => {
                                                    setIsEditingSingleAllowance(false)
                                                    resetFormAllowance()
                                                  }}>
                                                  Cancel
                                                </Button>
                                              </div>
                                            </form>
                                          </ListItem>
                                        );
                                      } else {
                                        return (
                                          <div>
                                            <ListItem key={index} style={{ borderBottom: '1px solid #ccc', paddingBottom: '8px', paddingTop: '8px' }}>
                                              <ListItemText
                                                primary={
                                                  <>
                                                    <Typography component="span" variant="body1">
                                                      {allowance.allowancestartmonth === allowance.allowanceendmonth ?
                                                        "One-time Allowance" :
                                                        "Recurring Allowance"}
                                                    </Typography>
                                                    <Typography component="span" variant="body2" display="block">
                                                      {allowanceTypes?.find(dt => dt.id === allowance.allowancetype)?.allowancetype ||
                                                        'Unknown Allowance Type'}
                                                      {' - '}
                                                      {school.clientname}
                                                    </Typography>
                                                  </>
                                                }
                                                secondary={
                                                  <>
                                                    <Typography component="span" variant="body2">
                                                      Amount: K{allowance.allowanceamount}
                                                    </Typography>
                                                    <Typography component="span" variant="body2" display="block">
                                                      {allowance.allowancestartmonth === allowance.allowanceendmonth ?
                                                        `Month: ${allowance.allowancestartmonth}` :
                                                        `Start Month: ${allowance.allowancestartmonth}, End Month: ${allowance.allowanceendmonth}`}
                                                    </Typography>
                                                  </>
                                                }
                                              />
                                              <ListItemSecondaryAction>
                                                {isConfirmingDeleteAllowance && index === isConfirmingDeleteIndexAllowance ? (
                                                  <div>
                                                    <Button style={{ marginRight: 10 }}
                                                      startIcon={<DeleteIcon />}
                                                      variant="contained"
                                                      color="warning"
                                                      size="small" onClick={() => {
                                                        deleteAllowance(index)
                                                        setIsConfirmingDeleteAllowance(false)
                                                        setIsConfirmingDeleteIndexAllowance(null)
                                                      }}>
                                                      Confirm Deletion
                                                    </Button>
                                                    <Button style={{ marginRight: 10 }}
                                                      startIcon={<DeleteIcon />}
                                                      variant="contained"
                                                      color="primary"
                                                      size="small" onClick={() => {
                                                        setIsConfirmingDeleteAllowance(false)
                                                        setIsConfirmingDeleteIndexAllowance(null)
                                                        resetFormAllowance()
                                                      }}>
                                                      Cancel
                                                    </Button>
                                                  </div>
                                                ) : (
                                                  <div>
                                                    {allowance.allowancestartmonth !== allowance.allowanceendmonth ? (
                                                      <>
                                                        <PermissionsGate scopes={[SCOPES.cancreaterecurringallowance]}>
                                                          <div>
                                                            <IconButton disabled={isEditingSingleAllowance || allowanceType} style={{ marginRight: 10 }} color="warning" edge="end" aria-label="delete" onClick={() => {
                                                              setIsConfirmingDeleteAllowance(true)
                                                              setIsConfirmingDeleteIndexAllowance(index)
                                                            }}>
                                                              <DeleteIcon />
                                                            </IconButton>
                                                            <IconButton disabled={isEditingSingleAllowance || allowanceType} color="primary" edge="end" aria-label="edit" onClick={() => startEditAllowance(index)}>
                                                              <EditIcon />
                                                            </IconButton>

                                                          </div>
                                                        </PermissionsGate>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <PermissionsGate scopes={[SCOPES.cancreateonetimeallowance]}>
                                                          <div>
                                                            <IconButton disabled={isEditingSingleAllowance || allowanceType} style={{ marginRight: 10 }} color="warning" edge="end" aria-label="delete" onClick={() => {
                                                              setIsConfirmingDeleteAllowance(true)
                                                              setIsConfirmingDeleteIndexAllowance(index)
                                                            }}>
                                                              <DeleteIcon />
                                                            </IconButton>
                                                            <IconButton disabled={isEditingSingleAllowance || allowanceType} color="primary" edge="end" aria-label="edit" onClick={() => startEditAllowance(index)}>
                                                              <EditIcon />
                                                            </IconButton>

                                                          </div>
                                                        </PermissionsGate>
                                                      </>

                                                    )}

                                                  </div>
                                                )}
                                              </ListItemSecondaryAction>
                                            </ListItem>
                                            <Divider component="li" />
                                          </div>
                                        )
                                      }
                                    }
                                  })}
                                </List>

                              </Grid>
                            </Grid>
                          </Grid>


                        </div>)}
                    </DialogContent>
                  </div>
                </>
              </Dialog>
              <Dialog
                open={verifyContractField}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleVerifyContractFields}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle>
                  {"Fill all required fields to generate a contract"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    Required fields are: Date of Hire, Length of Contract, First
                    Name, Last Name, NRC, Pension No., Report to, Salary,
                    Gratuity and Title.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleVerifyContractFields}>Ok</Button>
                </DialogActions>
              </Dialog>
              <Dialog open={openLoadDialog} keepMounted>
                <DialogContent>
                  <CircularProgress></CircularProgress>
                </DialogContent>
              </Dialog>
              <Dialog
                open={CancelEditPopUp}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCancelEdit}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle>
                  {"Do you want to cancel the changes?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    If you click Yes, all your changes will be lost. Click No to
                    continue with your modifications.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCancelEdit}>No</Button>
                  <Button onClick={editEmployee}>Yes</Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={CancelAddPopUp}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCancelAdd}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle>
                  {"Do you want to cancel the changes?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    If you click Yes, all your changes will be lost. Click No to
                    continue with your modifications.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCancelAdd}>No</Button>
                  <Button onClick={addEmployee}>Yes</Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={CancelBulkPopUp}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCancelBulk}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle>
                  {"Do you want to cancel the changes?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    If you click Yes, all your changes will be lost. Click No to
                    continue with your modifications.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCancelBulk}>No</Button>
                  <Button onClick={bulkEdit}>Yes</Button>
                </DialogActions>
              </Dialog>

              <Dialog
                open={deletePopUp}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseDelete}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle>{"Are you sure you want to delete?"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    If you click Yes, the record will be removed. Click No to
                    cancel.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseDelete}>No</Button>
                  <Button onClick={() => deleteHandler(selectedEmployee.id)}>
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={requiredFieldsPopUp}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseRequiredFieldsPopUp}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle>{"Fill all required fields"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    Required fields will appear with a *.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseRequiredFieldsPopUp}>Ok</Button>
                </DialogActions>
              </Dialog>
              <Grid container item xs={6} alignItems="left" mb={2}>
                {isEditing || isAdding || isBulkEditing ? (
                  <div
                    style={{
                      height: 25,
                    }}
                  ></div>
                ) : (
                  <>
                    {AddEmployeeButton(addEmployeeClicked)}
                    <Button
                      style={{
                        marginLeft: 10,
                        marginRight: 10,
                      }}
                      startIcon={<TableRowsIcon />}
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => tableView()}
                    >
                      Table View
                    </Button>
                    <Button
                      style={{
                        marginRight: 10,
                      }}
                      startIcon={<EditIcon />}
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => bulkEdit()}
                    >
                      Bulk Edit
                    </Button>
                  </>
                )}
                {isBulkEditing ? (
                  <>
                    {isViewingTable ? (
                      <>
                        <Button
                          startIcon={<ChevronLeftIcon />}
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => bulkEdit()}
                        >
                          Back
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          style={{
                            marginRight: 10,
                          }}
                          startIcon={<SaveAsIcon />}
                          variant="contained"
                          color="success"
                          form="bulk-edit-employee"
                          size="small"
                          onClick={() => saveBulkEdit()}
                        >
                          Save Changes
                        </Button>
                        <Button
                          startIcon={<CancelIcon />}
                          variant="contained"
                          color="warning"
                          size="small"
                          onClick={() => handleOpenBulk()}
                        >
                          Cancel
                        </Button>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid className="row" alignItems="left">
                {isBulkEditing ? (
                  <div>
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="start"
                      alignItems="center"
                      columnSpacing={0}
                    >
                      <Grid
                        item
                        style={{
                          width: "calc(30% - 60px)",
                        }}
                        paddingLeft={0}
                      >
                        {SearchBar(setSearch, search)}
                      </Grid>
                      <Grid
                        item
                        style={{
                          width: "60px",
                        }}
                        className="filterbutton"
                      >
                        {EmployeeFilter(
                          handleClickListItem,
                          selectedFilterIndex,
                          anchorEl,
                          open,
                          handleCloseFilterMenu,
                          handleMenuItemClick
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{
                          width: "70%",
                        }}
                      >
                        <div></div>
                      </Grid>
                    </Grid>
                    {BulkEmployeeTable(
                      selectEmployeeFromBulkEdit,
                      setBulkList,
                      deleteHandler,
                      list,
                      isViewingTable,
                      loadingGrid,
                      userName
                    )}
                  </div>
                ) : (
                  <></>
                )}
                <Grid item className="col" xs={3}>
                  {isEditing || isAdding || isBulkEditing ? (
                    <div></div>
                  ) : (
                    <div>
                      <Grid
                        item
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        columnSpacing={1}
                      >
                        <Grid
                          item
                          paddingLeft={0}
                          style={{
                            width: "calc(100% - 67px)",
                          }}
                        >
                          {SearchBar(setSearch, search)}
                        </Grid>
                        <Grid
                          item
                          style={{
                            width: "60px",
                          }}
                          className="filterbutton"
                        >
                          <>
                            {EmployeeFilter(
                              handleClickListItem,
                              selectedFilterIndex,
                              anchorEl,
                              open,
                              handleCloseFilterMenu,
                              handleMenuItemClick
                            )}
                          </>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Paper>
                          {EmployeeTable(
                            navigate,
                            deleteHandler,
                            list,
                            setEmployee,
                            loadingGrid
                          )}
                        </Paper>
                      </Grid>
                    </div>
                  )}
                </Grid>
                <Grid className="col pagebottom" item>
                  {/* <pre>{JSON.stringify(selectedEmployee.length)}</pre> */}
                  {selectedEmployee?.id || isAdding || isBulkEditing ? (
                    <section className="left  view-contact">
                      <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={4000}
                        onClose={handleCloseSnackbar}
                        message="Employee saved"
                        action={actionSnackbar}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                      >
                        <Alert
                          onClose={handleCloseSnackbar}
                          severity="success"
                          sx={{ width: "100%" }}
                        >
                          Employee saved
                        </Alert>
                      </Snackbar>
                      {isBulkEditing ? (
                        <div></div>
                      ) : (
                        <>
                          {isAdding ? (
                            <div>{AddEmployeeForm()}</div>
                          ) : (
                            <div>
                              {isEditing ? (
                                <div>{EditEmployeeForm()}</div>
                              ) : (
                                <div>{ViewEmployeeForm()}</div>
                              )}

                            </div>
                          )}
                        </>
                      )}
                    </section>
                  ) : (
                    <div></div>
                  )}
                </Grid>
                {!isLoadingproviders && !loadingGrid && !isBulkEditing && !isViewingTable && (
                  <>
                    <Grid className="col" xs={3} style={{ marginTop: 137, marginLeft: "-15%" }} item>
                      <Grid item xs={12} alignItems="start">
                        <Button
                          style={{width: 200}}
                          variant="contained"
                          size="large"
                          startIcon={<SummarizeIcon />}
                          onClick={setopenPayroll} // Adjust onClick handler as needed
                        >
                          View Payslip
                        </Button>
                      </Grid>
                      <Grid item xs={12} alignItems="start">
                        <Button
                          style={{ marginTop: 10, width: 200 }}
                          variant="contained"
                          size="large"
                          startIcon={<SummarizeIcon />}
                          onClick={setopenDeductions} // Adjust onClick handler as needed
                        >
                          Deductions
                        </Button>
                      </Grid>
                      <Grid item xs={12} alignItems="start">
                        <Button
                          style={{ marginTop: 10, width: 200 }}
                          variant="contained"
                          size="large"
                          startIcon={<SummarizeIcon />}
                          onClick={setopenAllowances} // Adjust onClick handler as needed
                        >
                          Allowances
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}

              </Grid>
            </Grid>

          </div>
        </section>
      </React.Fragment >
    </PermissionsGate >
  );

  function ViewEmployeeForm() {
    return (
      <>
        <CardWrapper className="row  viewcardmain">
          <div className="row">
            <div className="col">
              <Button
                className="skipbtn"
                style={{
                  objectFit: "cover",
                  objectPosition: "100%",
                  width: 45,
                  minWidth: 45,
                  height: 45,
                  minHeight: 45,
                  marginLeft: 0,
                  marginTop: 15,
                  marginBottom: -10,
                  boxShadow: "2px 4px 4px 2px rgba(0, 0, 0, 0.2)",
                  zIndex: 15,
                  backgroundColor: "#4526a0",
                  color: "#fff",
                  borderRadius: "50%",
                }}
                onClick={() => {
                  handleBack();
                }}
              >
                <ArrowBackIosNewIcon />
              </Button>
            </div>
            <div className="col">
              <Avatar
                id="avatar"
                style={{
                  float: "left",
                  objectFit: "cover",
                  objectPosition: "100%",
                  width: 90,
                  height: 90,
                  marginLeft: 0,
                  marginTop: 0,
                  marginBottom: -20,
                  boxShadow: "2px 4px 4px 2px rgba(0, 0, 0, 0.2)",
                  zIndex: 15,
                }}
                src={selectedEmployee.img}
                {...stringAvatar(
                  selectedEmployee.firstname + " " + selectedEmployee.lastname
                )}
              />
            </div>
            <div className="col">
              <Typography
                variant="h5"
                style={{
                  fontSize: 17,
                  display: "flex",
                  minWidth: 180,
                  maxWidth: 180,
                  flex: 1,
                  marginTop: 12,
                  marginLeft: 10,
                }}
              >
                {selectedEmployee.firstname + " " + selectedEmployee.lastname}
              </Typography>
              <div className="row">
                <BadgeTwoToneIcon
                  style={{
                    marginTop: 0,
                    padding: 0,
                    height: 17,
                    width: 17,
                    maxWidth: 17,
                    flex: 1,
                    marginLeft: 20,
                    color: "#fff",
                  }}
                />
                <Typography
                  style={{
                    fontSize: 13,
                    padding: 2,
                    minWidth: 135,
                    maxWidth: 135,
                    flex: 1,
                    marginLeft: 0,
                    textAlign: "left",
                  }}
                >
                  {selectedEmployee.title}
                </Typography>
              </div>
              <div className="row">
                <NumbersIcon
                  style={{
                    marginTop: -3,
                    padding: 0,
                    height: 17,
                    width: 17,
                    maxWidth: 17,
                    flex: 1,
                    marginLeft: 20,
                    color: "#fff",
                  }}
                />
                <Typography
                  style={{
                    marginTop: -5,
                    fontSize: 13,
                    padding: 2,
                    minWidth: 135,
                    maxWidth: 135,
                    flex: 1,
                    marginLeft: 0,
                    textAlign: "left",
                  }}
                >
                  {selectedEmployee.employeeid}
                </Typography>
              </div>
            </div>
            <div className="col">
              <div
                className="mb-2"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "right",
                  justifyContent: "right",
                  marginTop: 19,
                }}
              >
                <Button
                  startIcon={<EditIcon />}
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    editEmployee();
                  }}
                >
                  Edit
                </Button>
              </div>
              <div
                className="mb-2"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "right",
                  justifyContent: "right",
                }}
              >
                <PermissionsGate scopes={[SCOPES.canDelete]}>
                  <Button
                    startIcon={<DeleteIcon />}
                    variant="contained"
                    color="warning"
                    size="small"
                    onClick={handleOpenDelete}
                  >
                    Delete
                  </Button>
                </PermissionsGate>
              </div>
            </div>
            <div className="col">
              <Button
                className="skipbtn"
                style={{
                  float: "right",
                  objectFit: "cover",
                  objectPosition: "100%",
                  width: 45,
                  minWidth: 45,
                  height: 45,
                  minHeight: 45,
                  marginLeft: 0,
                  marginRight: 0,
                  marginTop: 15,
                  marginBottom: -10,
                  boxShadow: "2px 4px 4px 2px rgba(0, 0, 0, 0.2)",
                  zIndex: 15,
                  backgroundColor: "#4526a0",
                  color: "#fff",
                  borderRadius: "50%",
                }}
                onClick={() => {
                  handleNext();
                }}
              >
                <ArrowForwardIosIcon />
              </Button>
            </div>
          </div>
        </CardWrapper>
        <div className="viewcardbody">
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            style={{ marginBottom: "5px" }}
          >
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              return (
                <Step key={label} {...stepProps}>
                  <Button onClick={(event) => handleProgress(event, index)}>
                    <StepLabel
                      key={label}
                      StepIconComponent={ColorlibStepIcon}
                      {...labelProps}
                    >
                      <div className="steplabel">{label}</div>
                    </StepLabel>
                  </Button>
                </Step>
              );
            })}
          </Stepper>

          {activeStep === 0 && (
            <div>
              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Personal Information
                </Typography>
              </div>

              <div className="row">
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    First Name
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.firstname}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Middle Name
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.middlename}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Last Name
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.lastname}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Maiden Name
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.maiden}
                  </Typography>
                </div>
              </div>

              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Gender
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.gender}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Date of Birth
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.dob}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Phone Number
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.number}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Email
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.email}
                  </Typography>
                </div>
              </div>

              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    NRC
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.nrc}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Address
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.address}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Town
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.town}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Province
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.province}
                  </Typography>
                </div>
              </div>

              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Marital Status
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.maritalstatus}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Nationality
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.nationality || "Zambia"}
                  </Typography>
                </div>
                <div className="col">
                  {selectedEmployee.languagesList?.map((language, index) => (
                    <div>
                      <Typography
                        style={{
                          paddingLeft: 0,
                          fontSize: 10,
                          color: "#878787",
                        }}
                      >
                        Languages
                      </Typography>
                      <Typography
                        style={{
                          paddingLeft: 0,
                          fontSize: 13,
                        }}
                      >
                        {language}
                      </Typography>
                    </div>
                  ))}
                </div>
                <div className="col"></div>
              </div>

              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Spouse Information
                </Typography>
              </div>

              <div className="row">
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    First Name
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.firstnamespouse}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Last Name
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.lastnamespouse}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Address
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.addressspouse}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Number
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.numberspouse}
                  </Typography>
                </div>
              </div>

              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Workplace
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.workspouse}
                  </Typography>
                </div>
                <div className="col"></div>
                <div className="col"></div>
                <div className="col"></div>
              </div>

              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Children Information
                </Typography>
              </div>

              {selectedEmployee.children.map((child: Child, i: any) => (
                <div>
                  <div className="row">
                    <div className="col">
                      <Typography
                        style={{
                          paddingLeft: 0,
                          fontSize: 10,
                          color: "#878787",
                        }}
                      >
                        First Name
                      </Typography>
                      <Typography
                        style={{
                          paddingLeft: 0,
                          fontSize: 13,
                        }}
                      >
                        {child.childFirstName}
                      </Typography>
                    </div>
                    <div className="col">
                      <Typography
                        style={{
                          paddingLeft: 0,
                          fontSize: 10,
                          color: "#878787",
                        }}
                      >
                        Middle Name
                      </Typography>
                      <Typography
                        style={{
                          paddingLeft: 0,
                          fontSize: 13,
                        }}
                      >
                        {child.childLastName}
                      </Typography>
                    </div>
                    <div className="col">
                      <Typography
                        style={{
                          paddingLeft: 0,
                          fontSize: 10,
                          color: "#878787",
                        }}
                      >
                        Date of Birth
                      </Typography>
                      <Typography
                        style={{
                          paddingLeft: 0,
                          fontSize: 13,
                        }}
                      >
                        {child.childDob}
                      </Typography>
                    </div>
                    <div className="col">
                      <Typography
                        style={{
                          paddingLeft: 0,
                          fontSize: 10,
                          color: "#878787",
                        }}
                      >
                        Gender
                      </Typography>
                      <Typography
                        style={{
                          paddingLeft: 0,
                          fontSize: 13,
                        }}
                      >
                        {child.childGender}
                      </Typography>
                    </div>
                  </div>

                  <div className="row" style={{ marginTop: "10px" }}>
                    <div className="col">
                      <Typography
                        style={{
                          paddingLeft: 0,
                          fontSize: 10,
                          color: "#878787",
                        }}
                      >
                        Student Number
                      </Typography>
                      <Typography
                        style={{
                          paddingLeft: 0,
                          fontSize: 13,
                        }}
                      >
                        {child.childStudentNum}
                      </Typography>
                    </div>
                    <div className="col"></div>
                    <div className="col"></div>
                    <div className="col"></div>
                  </div>
                </div>
              ))}

              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Family Information
                </Typography>
              </div>

              <div className="row">
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Father First Name
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.fatherfirstname}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Father Last Name
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.fatherlastname}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Mother First Name
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.motherfirstname}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Mother Last Name
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.motherlastname}
                  </Typography>
                </div>
              </div>

              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Family Address
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.familyaddress}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Family Number
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.familynumber}
                  </Typography>
                </div>
                <div className="col"></div>
                <div className="col"></div>
              </div>

              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Emergency Information
                </Typography>
              </div>

              <div className="row">
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    First Name
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.emergencyfirstname}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Last Name
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.emergencylastname}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Relationship
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee[`emergencyrelationship`]}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Address
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.emergencyaddress}
                  </Typography>
                </div>
              </div>

              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Town
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.emergencytown}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Number
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.emergencynumber}
                  </Typography>
                </div>
                <div className="col"></div>
                <div className="col"></div>
              </div>

              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Medical Information
                </Typography>
              </div>

              <div className="row">
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Do you have any medical aliments that the organization needs
                    to be aware?
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.medicalquestion}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Comment
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.medicalcomment}
                  </Typography>
                </div>
              </div>
            </div>
          )}

          {activeStep === 1 && (
            <div>


              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Compensation & Benefits
                </Typography>
              </div>

              <div className="row">
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Job Title
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.title}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Reports to
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.reportsto}
                  </Typography>
                </div>

                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Campus
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.campus}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Length of Contract
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.contractlength || 0} months
                  </Typography>
                </div>
              </div>

              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Monthly Salary
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    K{selectedEmployee.salary}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Gratuity
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.gratuity}%
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Job Grade
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.jobgrade}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Area
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.area}
                  </Typography>
                </div>
              </div>

              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Bonus
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.bonus}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Pension
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.pension}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    ZRA TPIN
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.zratpin}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    NAPSA No.
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.napsa}
                  </Typography>
                </div>
              </div>

              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Pension No. (SSN)
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.pensionno}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    NHIS ID
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.nhisid}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Date of Hire
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.datehire}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Bank Account No.
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.bankaccount}
                  </Typography>
                </div>
              </div>

              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Branch
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.bankbranch}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Bank Name
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.bankname}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    Original Date of Hire
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.datehireoriginal}
                  </Typography>
                </div>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    TCZ No.
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.tcz}
                  </Typography>
                </div>
              </div>

              <div className="row" style={{ marginTop: "10px" }}>
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                    }}
                  >
                    NHA ID
                  </Typography>
                  <Typography style={{ paddingLeft: 0, fontSize: 13 }}>
                    {selectedEmployee.nhaid}
                  </Typography>
                </div>
                <div className="col">
                  
                </div>
                <div className="col">
                  
                </div>
                <div className="col">
                 
                </div>
              </div>

              

              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Employee Status
                </Typography>
              </div>

              {selectedEmployee.status
                ?.slice(0)
                .reverse()
                .map((stat: Status, i: any) => (
                  <div>
                    <Grid>
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="stretch"
                        spacing={2}
                      >
                        <Grid container item xs={11} spacing={2}>
                          <Grid item xs={3}>
                            <Typography
                              style={{
                                paddingLeft: 0,
                                fontSize: 10,
                                color: "#878787",
                              }}
                            >
                              Status
                            </Typography>
                            <Typography
                              style={{
                                paddingLeft: 0,
                                fontSize: 13,
                              }}
                            >
                              {stat.status}
                            </Typography>
                          </Grid>

                          <Grid item xs={3}>
                            <Typography
                              style={{
                                paddingLeft: 0,
                                fontSize: 10,
                                color: "#878787",
                              }}
                            >
                              Date
                            </Typography>
                            <Typography
                              style={{
                                paddingLeft: 0,
                                fontSize: 13,
                              }}
                            >
                              {stat.statusdate}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}></Grid>
                        </Grid>
                      </Grid>
                      <Divider
                        style={{
                          marginTop: 5,
                          marginBottom: 5,
                        }}
                      ></Divider>
                    </Grid>
                  </div>
                ))}
              {/* <Typography
            style={{ paddingLeft: 0, fontSize: 13 }}
          >
            {selectedEmployee.status}
          </Typography> */}

              {/* <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Allowances
                </Typography>
              </div>
              {selectedEmployee.allowances?.map(
                (allowance: Allowance, i: any) => (
                  <div>
                    <div className="row">
                      <div className="col">
                        <Typography
                          style={{
                            paddingLeft: 0,
                            fontSize: 10,
                            color: "#878787",
                          }}
                        >
                          Allowance Type
                        </Typography>
                        <Typography
                          style={{
                            paddingLeft: 0,
                            fontSize: 13,
                          }}
                        >
                          {allowance.allowancetype}
                        </Typography>
                      </div>
                      <div className="col">
                        <Typography
                          style={{
                            paddingLeft: 0,
                            fontSize: 10,
                            color: "#878787",
                          }}
                        >
                          Amount
                        </Typography>
                        <Typography
                          style={{
                            paddingLeft: 0,
                            fontSize: 13,
                          }}
                        >
                          {allowance.allowanceamount}
                        </Typography>
                      </div>
                      <div className="col">
                        <Typography
                          style={{
                            paddingLeft: 0,
                            fontSize: 10,
                            color: "#878787",
                          }}
                        >
                          Allowance Frequency
                        </Typography>
                        <Typography
                          style={{
                            paddingLeft: 0,
                            fontSize: 13,
                          }}
                        >
                          {allowance.allowancetime}
                        </Typography>
                      </div>
                      <div className="col">
                        <Typography
                          style={{
                            paddingLeft: 0,
                            fontSize: 10,
                            color: "#878787",
                          }}
                        >
                          Repayment Details
                        </Typography>
                        <Typography
                          style={{
                            paddingLeft: 0,
                            fontSize: 13,
                          }}
                        >
                          {allowance.allowancerepayment}
                        </Typography>
                      </div>
                    </div>
                  </div>
                )
              )} */}
              <PermissionsGate scopes={[SCOPES.canGenerateContract]}>
                <div className="row input-heading-row">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      marginTop: 10,
                      fontSize: 16,
                    }}
                  >
                    Employee Contract
                  </Typography>
                </div>
                {selectedEmployee.contractfile ? (
                  <>
                    <a href={selectedEmployee.contractfile}>
                      <PictureAsPdf />
                      View contract uploaded on {getContractDate()}
                    </a>
                  </>
                ) : (
                  <></>
                )}
              </PermissionsGate>
            </div>
          )}

          {activeStep === 2 && (
            <div>
              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: 10,
                    fontSize: 16,
                  }}
                >
                  Leave Management
                </Typography>
              </div>

              {selectedEmployee.leaves?.map((leave: EmployeeLeave, i: any) => (
                <div>
                  <div className="row">
                    <div className="col">
                      <Typography
                        style={{
                          paddingLeft: 0,
                          fontSize: 10,
                          color: "#878787",
                        }}
                      >
                        Leave Type
                      </Typography>
                      <Typography
                        style={{
                          paddingLeft: 0,
                          fontSize: 13,
                        }}
                      >
                        {leave.leavetype}
                      </Typography>
                    </div>
                    <div className="col">
                      <Typography
                        style={{
                          paddingLeft: 0,
                          fontSize: 10,
                          color: "#878787",
                        }}
                      >
                        Leave Start Date
                      </Typography>
                      <Typography
                        style={{
                          paddingLeft: 0,
                          fontSize: 13,
                        }}
                      >
                        {leave.leavestart}
                      </Typography>
                    </div>
                    <div className="col">
                      <Typography
                        style={{
                          paddingLeft: 0,
                          fontSize: 10,
                          color: "#878787",
                        }}
                      >
                        Leave End Date
                      </Typography>
                      <Typography
                        style={{
                          paddingLeft: 0,
                          fontSize: 13,
                        }}
                      >
                        {leave.leaveend}
                      </Typography>
                    </div>
                    <div className="col">
                      <Typography
                        style={{
                          paddingLeft: 0,
                          fontSize: 10,
                          color: "#878787",
                        }}
                      >
                        Days of leave
                      </Typography>
                      <Typography
                        style={{
                          paddingLeft: 0,
                          fontSize: 13,
                        }}
                      >
                        {leave.leavedays}
                      </Typography>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {activeStep === 3 && (
            <div>
              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Performance Management
                </Typography>
              </div>

              <div className="row">
                <div className="col">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      fontSize: 10,
                      color: "#878787",
                      maxWidth: 300,
                    }}
                  >
                    Performance Notes
                  </Typography>
                  <Typography
                    style={{ maxWidth: 300, paddingLeft: 0, fontSize: 13 }}
                  >
                    {selectedEmployee.performance}
                  </Typography>
                </div>
              </div>

              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Performance Documents
                </Typography>
              </div>
              {selectedEmployee.performancedocument ? (
                <>
                  <a href={selectedEmployee.performancedocument}>
                    <PictureAsPdf />
                    View Document
                  </a>
                </>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>

      </>
    );
  }

  function EditEmployeeForm() {
    return (
      <form id="edit-employee" onSubmit={submitForm}>
        <CardWrapper className="row  viewcardmain">
          <div className="row">
            <div className="col">
              <Button
                className="skipbtn"
                style={{
                  display: "none",
                  objectFit: "cover",
                  objectPosition: "100%",
                  width: 15,
                  minWidth: 15,
                  height: 45,
                  minHeight: 45,
                  marginLeft: 0,
                  marginTop: 15,
                  marginBottom: -10,
                  boxShadow: "2px 4px 4px 2px rgba(0, 0, 0, 0.2)",
                  zIndex: 15,
                  backgroundColor: "#4526a0",
                  color: "#fff",
                  borderRadius: "50%",
                }}
              >
                <ArrowBackIosNewIcon />
              </Button>
            </div>
            <div className="col">
              <Avatar
                id="avatar"
                style={{
                  float: "left",
                  objectFit: "cover",
                  objectPosition: "100%",
                  width: 90,
                  height: 90,
                  marginLeft: 0,
                  marginTop: 0,
                  marginBottom: 0,
                  boxShadow: "2px 4px 4px 2px rgba(0, 0, 0, 0.2)",
                  zIndex: 15,
                }}
                src={editingEmployee.img}
                {...stringAvatar(
                  editingEmployee.firstname + " " + editingEmployee.lastname
                )}
              />
            </div>
            <div className="col">
              <input
                type="file"
                accept="image/*"
                name="image-upload"
                id="input"
                onChange={imageHandler}
              />
              <div
                style={{
                  display: "flex",
                  marginTop: -40,
                  marginLeft: -30,
                  minWidth: 100,
                  maxWidth: 100,
                  flex: 1,
                }}
                className="label"
              >
                <label className="image-upload" htmlFor="input">
                  <i className="material-icons"></i>
                  Upload image
                </label>
              </div>
            </div>
            <div className="col">
              <Typography
                variant="h5"
                style={{
                  fontSize: 17,
                  display: "flex",
                  minWidth: 180,
                  maxWidth: 180,
                  flex: 1,
                  marginTop: 12,
                  marginLeft: 10,
                }}
              >
                {editingEmployee.firstname + " " + editingEmployee.lastname}
              </Typography>
              <div className="row">
                <BadgeTwoToneIcon
                  style={{
                    marginTop: 0,
                    padding: 0,
                    height: 17,
                    width: 17,
                    maxWidth: 17,
                    flex: 1,
                    marginLeft: 20,
                    color: "#fff",
                  }}
                />
                <Typography
                  style={{
                    fontSize: 13,
                    padding: 2,
                    minWidth: 135,
                    maxWidth: 135,
                    flex: 1,
                    marginLeft: 0,
                    textAlign: "left",
                  }}
                >
                  {editingEmployee.title}
                </Typography>
              </div>
              <div className="row">
                <NumbersIcon
                  style={{
                    marginTop: -3,
                    padding: 0,
                    height: 17,
                    width: 17,
                    maxWidth: 17,
                    flex: 1,
                    marginLeft: 20,
                    color: "#fff",
                  }}
                />
                <Typography
                  style={{
                    marginTop: -5,
                    fontSize: 13,
                    padding: 2,
                    minWidth: 135,
                    maxWidth: 135,
                    flex: 1,
                    marginLeft: 0,
                    textAlign: "left",
                  }}
                >
                  {editingEmployee.employeeid}
                </Typography>
              </div>
            </div>
            <div className="col">
              <div
                className="mb-2"
                style={{
                  width: 140,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "right",
                  justifyContent: "right",
                  marginTop: 19,
                }}
              >
                <Button
                  startIcon={<SaveAsIcon />}
                  variant="contained"
                  color="success"
                  form="edit-employee"
                  size="small"
                  type="submit"
                >
                  Save Changes
                </Button>
              </div>
              <div
                className="mb-2"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "right",
                  justifyContent: "right",
                }}
              >
                <Button
                  startIcon={<CancelIcon />}
                  variant="contained"
                  color="warning"
                  size="small"
                  onClick={() => handleOpenEdit()}
                >
                  Cancel
                </Button>
              </div>
            </div>
            <div className="col">
              <Button
                className="skipbtn"
                style={{
                  display: "none",
                  float: "right",
                  objectFit: "cover",
                  objectPosition: "100%",
                  width: 15,
                  minWidth: 15,
                  height: 45,
                  minHeight: 45,
                  marginLeft: 0,
                  marginRight: 0,
                  marginTop: 15,
                  marginBottom: -10,
                  boxShadow: "2px 4px 4px 2px rgba(0, 0, 0, 0.2)",
                  zIndex: 15,
                  backgroundColor: "#1565C0",
                  color: "#fff",
                  borderRadius: "50%",
                }}
              >
                <ArrowForwardIosIcon />
              </Button>
            </div>
          </div>
        </CardWrapper>

        <div className="viewcardbody">
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            style={{ marginBottom: "5px" }}
          >
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              return (
                <Step key={label} {...stepProps}>
                  <Button onClick={(event) => handleProgress(event, index)}>
                    <StepLabel
                      key={label}
                      StepIconComponent={ColorlibStepIcon}
                      {...labelProps}
                    >
                      <div className="steplabel">{label}</div>
                    </StepLabel>
                  </Button>
                </Step>
              );
            })}
          </Stepper>

          {activeStep === 0 && (
            <div>
              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Personal Information
                </Typography>
              </div>

              <div className="row" style={{ marginTop: "18px" }}>
                <div className="col">
                  <TextField
                    className="required0"
                    size="small"
                    required
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    label="First Name"
                    name="firstname"
                    onChange={updateInput}
                    defaultValue={editingEmployee.firstname}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    label="Middle Name"
                    name="middlename"
                    onChange={updateInput}
                    defaultValue={editingEmployee.middlename}
                  />
                </div>
                <div className="col">
                  <TextField
                    className="required0"
                    required
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    label="Last Name"
                    name="lastname"
                    onChange={updateInput}
                    defaultValue={editingEmployee.lastname}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    label="Maiden Name"
                    name="maiden"
                    defaultValue={editingEmployee.maiden}
                    onChange={updateInput}
                  />
                </div>
              </div>

              <div className="row" style={{ marginTop: "16px" }}>
                <div className="col">
                  <FormControl
                    required
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                  >
                    <InputLabel id="gender-label">Gender</InputLabel>
                    <Select
                      name="gender"
                      labelId="gender-label"
                      id="gender"
                      defaultValue={editingEmployee.gender}
                      onChange={updateInput}
                      label="Gender"
                    >
                      <MenuItem value={"Male"}>Male</MenuItem>
                      <MenuItem value={"Female"}>Female</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col">
                  <TextField
                    required
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    inputProps={{
                      min: "1920-01-24",
                      max: today(),
                    }}
                    type="date"
                    label="Date of Birth"
                    name="dob"
                    onChange={updateInput}
                    defaultValue={editingEmployee.dob}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className="col">
                  <NumberFormat
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    label="Phone Number"
                    className="form-control "
                    value={editingEmployee.number}
                    name="number"
                    onChange={updateInput}
                    customInput={TextField}
                    format="+260 ## ####-###"
                    required={true}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="email"
                    label="Email"
                    name="email"
                    defaultValue={editingEmployee.email}
                    onChange={updateInput}
                  />
                </div>
              </div>

              <div className="row" style={{ marginTop: "16px" }}>
                <div className="col">
                  <NumberFormat
                    required
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    label="NRC"
                    className="form-control "
                    value={editingEmployee.nrc}
                    name="nrc"
                    onChange={updateInput}
                    customInput={TextField}
                    format="######/##/#"
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Address"
                    name="address"
                    defaultValue={editingEmployee.address}
                    onChange={updateInput}
                    required={true}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Town"
                    name="town"
                    defaultValue={editingEmployee.town}
                    onChange={updateInput}
                    required={true}
                  />
                </div>
                <div className="col">
                  <FormControl
                    required
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                  >
                    <InputLabel id="gender-label">Province</InputLabel>
                    <Select
                      name="province"
                      labelId="province-label"
                      id="province"
                      defaultValue={editingEmployee.province}
                      onChange={updateInput}
                      label="Province"
                    >
                      <MenuItem value={"Central"}>Central</MenuItem>
                      <MenuItem value={"Copperbelt"}>Copperbelt</MenuItem>
                      <MenuItem value={"Eastern"}>Eastern</MenuItem>
                      <MenuItem value={"Luapula"}>Luapula</MenuItem>
                      <MenuItem value={"Lusaka"}>Lusaka</MenuItem>
                      <MenuItem value={"Muchinga"}>Muchinga</MenuItem>
                      <MenuItem value={"Northern"}>Northern</MenuItem>
                      <MenuItem value={"North Western"}>North Western</MenuItem>
                      <MenuItem value={"Southern"}>Southern</MenuItem>
                      <MenuItem value={"Western"}>Western</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="row" style={{ marginTop: "16px" }}>
                <div className="col">
                  <FormControl
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 142,
                      flex: 1,
                      marginRight: 9,
                    }}
                  >
                    <InputLabel id="gender-label">Marital Status</InputLabel>
                    <Select
                      name="maritalstatus"
                      labelId="maritalstatus-label"
                      id="maritalstatus"
                      defaultValue={editingEmployee.maritalstatus}
                      onChange={updateInput}
                      label="Marital Status"
                    >
                      <MenuItem value={"Married"}>Married</MenuItem>
                      <MenuItem value={"Single"}>Single</MenuItem>
                      <MenuItem value={"Divorced"}>Divorced</MenuItem>
                      <MenuItem value={"Widowed"}>Widowed</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col">
                  <FormControl
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 142,
                      flex: 1,
                      marginRight: 9,
                    }}
                  >
                    <InputLabel id="nationality-label">Nationality</InputLabel>
                    <Select
                      name="nationality"
                      labelId="nationality-label"
                      id="nationality"
                      defaultValue={editingEmployee.nationality || "Zambia"}
                      onChange={updateInput}
                      label="Nationality"
                    >
                      {!!countriesArr?.length &&
                        countriesArr.map((countryObj) => (
                          <MenuItem
                            key={countryObj.value}
                            value={countryObj.value}
                          >
                            {countryObj.label}{" "}
                            <ReactCountryFlag
                              style={{ marginLeft: 4 }}
                              countryCode={countryObj.id}
                              svg
                            />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col">
                  <FormControl
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 320,
                      maxWidth: 320,
                      flex: 1,
                      marginRight: 9,
                    }}
                  >
                    <InputLabel shrink={true} id="demo-multiple-name-label">
                      Languages Spoken
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      multiple={true}
                      defaultValue={editingEmployee.languagesList || []}
                      onChange={handleLanguagesChange}
                      input={<OutlinedInput label="Languages Spoken" />}
                      MenuProps={MenuProps}
                    >
                      {languageList.map((language) => (
                        <MenuItem key={language} value={language}>
                          {language}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col"></div>
              </div>

              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Spouse Information
                </Typography>
              </div>

              <div className="row" style={{ marginTop: "16px" }}>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    className="form-control "
                    label="First Name"
                    name="firstnamespouse"
                    defaultValue={editingEmployee.firstnamespouse}
                    onChange={updateInput}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    className="form-control "
                    label="Last Name"
                    name="lastnamespouse"
                    defaultValue={editingEmployee.lastnamespouse}
                    onChange={updateInput}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    className="form-control "
                    label="Address"
                    name="addressspouse"
                    defaultValue={editingEmployee.addressspouse}
                    onChange={updateInput}
                  />
                </div>
                <div className="col">
                  <NumberFormat
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    label="Phone Number"
                    className="form-control "
                    value={editingEmployee.numberspouse}
                    name="numberspouse"
                    onChange={updateInput}
                    customInput={TextField}
                    format="+260 ## ####-###"
                  />
                </div>
              </div>

              <div className="row" style={{ marginTop: "16px" }}>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    className="form-control "
                    label="Workplace"
                    name="workspouse"
                    defaultValue={editingEmployee.workspouse}
                    onChange={updateInput}
                  />
                </div>
                <div className="col"></div>
                <div className="col"></div>
                <div className="col"></div>
              </div>

              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Children Information
                </Typography>
              </div>

              <EditChildren
                onChildrenUpdate={handleChildrenChange}
                children={editingEmployee.children ?? []}
              />

              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Family Information
                </Typography>
              </div>

              <div className="row">
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Father First Name"
                    name="fatherfirstname"
                    defaultValue={editingEmployee.fatherfirstname}
                    onChange={updateInput}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Father Last Name"
                    name="fatherlastname"
                    defaultValue={editingEmployee.fatherlastname}
                    onChange={updateInput}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Mother First Name"
                    name="motherfirstname"
                    defaultValue={editingEmployee.motherfirstname}
                    onChange={updateInput}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Mother Last Name"
                    name="motherlastname"
                    defaultValue={editingEmployee.motherlastname}
                    onChange={updateInput}
                  />
                </div>
              </div>

              <div className="row" style={{ marginTop: "16px" }}>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Family Address"
                    name="familyaddress"
                    defaultValue={editingEmployee.familyaddress}
                    onChange={updateInput}
                  />
                </div>
                <div className="col">
                  <NumberFormat
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    label="Family Number"
                    className="form-control "
                    defaultValue={editingEmployee.familynumber}
                    name="familynumber"
                    onChange={updateInput}
                    customInput={TextField}
                    format="+260 ## ####-###"
                  />
                </div>
                <div className="col"></div>
                <div className="col"></div>
              </div>

              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Emergency Information
                </Typography>
              </div>

              <div className="row" style={{ marginTop: "16px" }}>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    className="form-control "
                    label="First Name"
                    name="emergencyfirstname"
                    defaultValue={editingEmployee[`emergencyfirstname`]}
                    onChange={updateInput}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    className="form-control "
                    label="Last Name"
                    name="emergencylastname"
                    defaultValue={editingEmployee.emergencylastname}
                    onChange={updateInput}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Relationship"
                    name="emergencyrelationship"
                    defaultValue={editingEmployee[`emergencyrelationship`]}
                    onChange={updateInput}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Address"
                    name="emergencyaddress"
                    defaultValue={editingEmployee.emergencyaddress}
                    onChange={updateInput}
                  />
                </div>
              </div>

              <div className="row" style={{ marginTop: "16px" }}>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Town"
                    name="emergencytown"
                    defaultValue={editingEmployee.emergencytown}
                    onChange={updateInput}
                  />
                </div>
                <div className="col">
                  <NumberFormat
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    label="Number"
                    className="form-control "
                    defaultValue={editingEmployee.emergencynumber}
                    name="emergencynumber"
                    onChange={updateInput}
                    customInput={TextField}
                    format="+260 ## ####-###"
                  />
                </div>
                <div className="col"></div>
                <div className="col"></div>
              </div>

              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Medical Information
                </Typography>
              </div>

              <div className="row" style={{ marginTop: "16px" }}>
                <div className="col">
                  <FormControl
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                  >
                    <FormLabel
                      id="health-radio-label"
                      style={{
                        fontSize: 10,
                      }}
                    >
                      Do you have any medical aliments that the organization
                      needs to be aware of or could hinder your ability to
                      complete your job?
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="health-radio-label"
                      name="medicalquestion"
                      defaultValue={editingEmployee.medicalquestion}
                      onChange={updateInput}
                    >
                      <FormControlLabel
                        style={{
                          fontSize: 14,
                        }}
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        style={{
                          fontSize: 14,
                        }}
                        value="No"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    className="form-control "
                    label="Comment"
                    name="medicalcomment"
                    defaultValue={editingEmployee.medicalcomment}
                    onChange={updateInput}
                  />
                </div>
              </div>
            </div>
          )}

          {activeStep === 1 && (
            <div>
              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Compensation & Benefits
                </Typography>
              </div>

              <div className="row" style={{ marginTop: "16px" }}>
                <div className="col">
                  <FormControl
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                  >
                    <InputLabel id="jobtitle-label">Job Title</InputLabel>
                    <Select
                      labelId="jobtitle-label"
                      id="title"
                      name="title"
                      defaultValue={editingEmployee.title}
                      onChange={updateInput}
                      label="Job Title"
                    >
                      <MenuItem value={"Admin"}>Admin</MenuItem>
                      <MenuItem value={"Teacher"}>Teacher</MenuItem>
                      <MenuItem value={"Other"}>Other</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col">
                  <FormControl
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                  >
                    <InputLabel id="reportsto-label">Reports to</InputLabel>
                    <Select
                      name="reportsto"
                      labelId="reportsto-label"
                      id="reportsto"
                      defaultValue={editingEmployee.reportsto}
                      onChange={updateInput}
                      label="Reports to"
                    >
                      <MenuItem value={"Executive Director"}>
                        Executive Director
                      </MenuItem>
                      <MenuItem value={"Academic Director"}>
                        Academic Director
                      </MenuItem>
                      <MenuItem value={"Head Teacher"}>Head Teacher</MenuItem>
                      <MenuItem value={"Deputy Head Teacher"}>
                        Deputy Head Teacher
                      </MenuItem>
                      <MenuItem value={"Section Head"}>Section Head</MenuItem>
                      <MenuItem value={"Administration Manager"}>
                        Administration Manager
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div className="col">
                  <FormControl
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                  >
                    <InputLabel id="jobtitle-label">Campus</InputLabel>
                    <Select
                      labelId="campus-label"
                      id="campus"
                      name="campus"
                      defaultValue={editingEmployee.campus}
                      onChange={updateInput}
                      label="Campus"
                    >
                      <MenuItem value={"Main Campus"}>Main Campus</MenuItem>
                      <MenuItem value={"Lubombo"}>Lubombo</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="number"
                    label="Length of Contract"
                    name="contractlength"
                    defaultValue={editingEmployee.contractlength}
                    onChange={updateInput}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputProps: { min: 0 },
                      endAdornment: (
                        <InputAdornment position="start">
                          {" "}
                          months
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>

              <div className="row" style={{ marginTop: "16px" }}>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="number"
                    label="Salary"
                    name="salary"
                    defaultValue={editingEmployee.salary}
                    onChange={updateInput}
                    required={true}
                    InputProps={{
                      inputProps: { min: 0 },
                      startAdornment: (
                        <InputAdornment position="start">K</InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="number"
                    label="Gratuity"
                    name="gratuity"
                    defaultValue={editingEmployee.gratuity}
                    onChange={updateInput}
                    InputProps={{
                      inputProps: { min: 0, max: 100 },
                      endAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    className="form-control "
                    label="Job Grade"
                    name="jobgrade"
                    defaultValue={editingEmployee.jobgrade}
                    onChange={updateInput}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Area"
                    name="area"
                    defaultValue={editingEmployee.area}
                    onChange={updateInput}
                  />
                </div>
              </div>

              <div className="row" style={{ marginTop: "16px" }}>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Bonus"
                    name="bonus"
                    defaultValue={editingEmployee.bonus}
                    onChange={updateInput}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Pension"
                    name="pension"
                    defaultValue={editingEmployee.pension}
                    onChange={updateInput}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="ZRA TPIN"
                    name="zratpin"
                    defaultValue={editingEmployee.zratpin}
                    onChange={updateInput}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="NAPSA No."
                    name="napsa"
                    defaultValue={editingEmployee.napsa}
                    onChange={updateInput}
                  />
                </div>
              </div>

              <div className="row" style={{ marginTop: "16px" }}>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Pension No. (SSN)"
                    name="pensionno"
                    defaultValue={editingEmployee.pensionno}
                    onChange={updateInput}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="NHIS ID"
                    name="nhisid"
                    defaultValue={editingEmployee.nhisid}
                    onChange={updateInput}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="date"
                    label="Date of Hire"
                    name="datehire"
                    defaultValue={editingEmployee.datehire}
                    onChange={updateInput}
                    required={true}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Bank Account No."
                    name="bankaccount"
                    defaultValue={editingEmployee.bankaccount}
                    onChange={updateInput}
                  />
                </div>
              </div>

              <div className="row" style={{ marginTop: "16px" }}>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Branch"
                    name="bankbranch"
                    defaultValue={editingEmployee.bankbranch}
                    onChange={updateInput}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Bank Name"
                    name="bankname"
                    defaultValue={editingEmployee.bankname}
                    onChange={updateInput}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="date"
                    label="Original Date of Hire"
                    name="datehireoriginal"
                    defaultValue={editingEmployee.datehireoriginal}
                    onChange={updateInput}
                    required={true}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="TCZ No."
                    name="tcz"
                    defaultValue={editingEmployee.tcz}
                    onChange={updateInput}
                  />
                </div>
              </div>

              <div className="row" style={{ marginTop: "16px" }}>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="NHA ID"
                    name="nhaid"
                    defaultValue={editingEmployee.nhaid}
                    onChange={updateInput}
                  />
                </div>
                <div className="col">
                  
                </div>
                <div className="col">
                  
                </div>
                <div className="col">
                 
                </div>
              </div>

              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Employee Status
                </Typography>
              </div>

              <EditStatus
                onStatusUpdate={handleStatusChange}
                status={editingEmployee.status ?? []}
              />

              {/* <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Allowances
                </Typography>
              </div>

              <EditAllowance
                onAllowanceUpdate={handleAllowanceChange}
                allowances={editingEmployee.allowances ?? []}
              /> */}

              <PermissionsGate scopes={[SCOPES.canGenerateContract]}>
                <div className="row input-heading-row">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      marginTop: 10,
                      fontSize: 16,
                    }}
                  >
                    Employee Contract
                  </Typography>
                </div>
                <div
                  className="mb-2"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 19,
                  }}
                >
                  <Button
                    startIcon={<ArticleIcon />}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                      verifyContractFields();
                    }}
                  >
                    Generate Employee Contract
                  </Button>
                  <GenerateEmployeeContract
                    open={isModalOpen}
                    onClose={() => handleCloseContract()}
                    contractData={editingEmployee}
                  />
                </div>
                <EditContract
                  typeOfContract={"contract"}
                  onContractUpdate={handleContractUpdate}
                  contract={editingEmployee.contractfile ?? ""}
                />
              </PermissionsGate>
            </div>
          )}

          {activeStep === 2 && (
            <div>
              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: 10,
                    fontSize: 16,
                  }}
                >
                  Leave Management
                </Typography>
              </div>

              <EditLeave
                onLeaveUpdate={handleLeaveChange}
                leaves={editingEmployee.leaves ?? []}
              />
            </div>
          )}

          {activeStep === 3 && (
            <div>
              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Performance Management
                </Typography>
              </div>

              <div className="row">
                <div className="col">
                  <TextField
                    className="multilineAField"
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    type="text"
                    label="Performance Notes"
                    name="performance"
                    defaultValue={editingEmployee.performance}
                    onChange={updateInput}
                  />
                </div>
              </div>

              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Performance Documents
                </Typography>
              </div>
              <EditContract
                typeOfContract={"performance"}
                onContractUpdate={handlePerformanceDocumentUpdate}
                contract={editingEmployee[`performancedocument`] ?? ""}
              />
            </div>
          )}
        </div>
      </form>
    );
  }

  function AddEmployeeForm() {
    return (
      <form id="add-employee" onSubmit={addSubmitForm}>
        <CardWrapper className="row  viewcardmain">
          <div className="row">
            <div className="col">
              <Button
                className="skipbtn"
                style={{
                  display: "none",
                  objectFit: "cover",
                  objectPosition: "100%",
                  width: 15,
                  minWidth: 15,
                  height: 45,
                  minHeight: 45,
                  marginLeft: 0,
                  marginTop: 15,
                  marginBottom: -10,
                  boxShadow: "2px 4px 4px 2px rgba(0, 0, 0, 0.2)",
                  zIndex: 15,
                  backgroundColor: "#4526a0",
                  color: "#fff",
                  borderRadius: "50%",
                }}
              >
                <ArrowBackIosNewIcon />
              </Button>
            </div>
            <div className="col">
              <Avatar
                id="avatar"
                style={{
                  float: "left",
                  objectFit: "cover",
                  objectPosition: "100%",
                  width: 90,
                  height: 90,
                  marginLeft: 0,
                  marginTop: 0,
                  marginBottom: 0,
                  boxShadow: "2px 4px 4px 2px rgba(0, 0, 0, 0.2)",
                  zIndex: 15,
                }}
                src={addingEmployee.img}
              />
            </div>
            <div className="col">
              <input
                type="file"
                accept="image/*"
                name="image-upload"
                id="input"
                onChange={imageHandler}
              />
              <div
                style={{
                  display: "flex",
                  marginTop: -40,
                  marginLeft: -30,
                  minWidth: 100,
                  maxWidth: 100,
                  flex: 1,
                }}
                className="label"
              >
                <label className="image-upload" htmlFor="input">
                  <i className="material-icons"></i>
                  Upload image
                </label>
              </div>
            </div>
            <div className="col">
              <div className="row"></div>
              <div className="row"></div>
            </div>
            <div className="col">
              <div
                className="mb-2"
                style={{
                  width: 140,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "right",
                  justifyContent: "right",
                  marginTop: 19,
                }}
              >
                <Button
                  startIcon={<SaveAsIcon />}
                  variant="contained"
                  color="success"
                  form="add-employee"
                  size="small"
                  type="submit"
                >
                  Save Changes
                </Button>
              </div>
              <div
                className="mb-2"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "right",
                  justifyContent: "right",
                }}
              >
                <Button
                  startIcon={<CancelIcon />}
                  variant="contained"
                  color="warning"
                  size="small"
                  onClick={() => handleOpenAdd()}
                >
                  Cancel
                </Button>
              </div>
            </div>
            <div className="col">
              <Button
                className="skipbtn"
                style={{
                  display: "none",
                  float: "right",
                  objectFit: "cover",
                  objectPosition: "100%",
                  width: 15,
                  minWidth: 15,
                  height: 45,
                  minHeight: 45,
                  marginLeft: 0,
                  marginRight: 0,
                  marginTop: 15,
                  marginBottom: -10,
                  boxShadow: "2px 4px 4px 2px rgba(0, 0, 0, 0.2)",
                  zIndex: 15,
                  backgroundColor: "#1565C0",
                  color: "#fff",
                  borderRadius: "50%",
                }}
              >
                <ArrowForwardIosIcon />
              </Button>
            </div>
          </div>
        </CardWrapper>

        <div className="viewcardbody">
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            style={{ marginBottom: "5px" }}
          >
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              return (
                <Step key={label} {...stepProps}>
                  <Button onClick={(event) => handleProgress(event, index)}>
                    <StepLabel
                      key={label}
                      StepIconComponent={ColorlibStepIcon}
                      {...labelProps}
                    >
                      <div className="steplabel">{label}</div>
                    </StepLabel>
                  </Button>
                </Step>
              );
            })}
          </Stepper>

          {activeStep === 0 && (
            <div>
              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Personal Information
                </Typography>
              </div>

              <div className="row" style={{ marginTop: "18px" }}>
                <div className="col">
                  <TextField
                    className="required0"
                    size="small"
                    required
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    label="First Name"
                    name="firstname"
                    onChange={updateAddInput}
                    defaultValue={addingEmployee.firstname}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    label="Middle Name"
                    name="middlename"
                    onChange={updateAddInput}
                    defaultValue={addingEmployee.middlename}
                  />
                </div>
                <div className="col">
                  <TextField
                    className="required0"
                    required
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    label="Last Name"
                    name="lastname"
                    onChange={updateAddInput}
                    defaultValue={addingEmployee.lastname}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    label="Maiden Name"
                    name="maiden"
                    defaultValue={addingEmployee.maiden}
                    onChange={updateAddInput}
                  />
                </div>
              </div>

              <div className="row" style={{ marginTop: "16px" }}>
                <div className="col">
                  <FormControl
                    required
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                  >
                    <InputLabel id="gender-label">Gender</InputLabel>
                    <Select
                      name="gender"
                      labelId="gender-label"
                      id="gender"
                      defaultValue={addingEmployee.gender}
                      onChange={updateAddInput}
                      label="Gender"
                    >
                      <MenuItem value={"Male"}>Male</MenuItem>
                      <MenuItem value={"Female"}>Female</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col">
                  <TextField
                    required
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    inputProps={{
                      min: "1920-01-24",
                      max: today(),
                    }}
                    type="date"
                    label="Date of Birth"
                    name="dob"
                    onChange={updateAddInput}
                    defaultValue={addingEmployee.dob}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className="col">
                  <NumberFormat
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    label="Phone Number"
                    className="form-control "
                    value={addingEmployee.number}
                    name="number"
                    onChange={updateAddInput}
                    customInput={TextField}
                    format="+260 ## ####-###"
                    required={true}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="email"
                    label="Email"
                    name="email"
                    defaultValue={addingEmployee.email}
                    onChange={updateAddInput}
                  />
                </div>
              </div>

              <div className="row" style={{ marginTop: "16px" }}>
                <div className="col">
                  <NumberFormat
                    required
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    label="NRC"
                    className="form-control "
                    value={addingEmployee.nrc}
                    name="nrc"
                    onChange={updateAddInput}
                    customInput={TextField}
                    format="######/##/#"
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Address"
                    name="address"
                    defaultValue={addingEmployee.address}
                    onChange={updateAddInput}
                    required={true}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Town"
                    name="town"
                    defaultValue={addingEmployee.town}
                    onChange={updateAddInput}
                    required={true}
                  />
                </div>
                <div className="col">
                  <FormControl
                    required
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                  >
                    <InputLabel id="gender-label">Province</InputLabel>
                    <Select
                      name="province"
                      labelId="province-label"
                      id="province"
                      defaultValue={addingEmployee.province}
                      onChange={updateAddInput}
                      label="Province"
                    >
                      <MenuItem value={"Central"}>Central</MenuItem>
                      <MenuItem value={"Copperbelt"}>Copperbelt</MenuItem>
                      <MenuItem value={"Eastern"}>Eastern</MenuItem>
                      <MenuItem value={"Luapula"}>Luapula</MenuItem>
                      <MenuItem value={"Lusaka"}>Lusaka</MenuItem>
                      <MenuItem value={"Muchinga"}>Muchinga</MenuItem>
                      <MenuItem value={"Northern"}>Northern</MenuItem>
                      <MenuItem value={"North Western"}>North Western</MenuItem>
                      <MenuItem value={"Southern"}>Southern</MenuItem>
                      <MenuItem value={"Western"}>Western</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="row" style={{ marginTop: "16px" }}>
                <div className="col">
                  <FormControl
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 142,
                      flex: 1,
                      marginRight: 9,
                    }}
                  >
                    <InputLabel id="gender-label">Marital Status</InputLabel>
                    <Select
                      name="maritalstatus"
                      labelId="maritalstatus-label"
                      id="maritalstatus"
                      defaultValue={addingEmployee.maritalstatus}
                      onChange={updateAddInput}
                      label="Marital Status"
                    >
                      <MenuItem value={"Married"}>Married</MenuItem>
                      <MenuItem value={"Single"}>Single</MenuItem>
                      <MenuItem value={"Divorced"}>Divorced</MenuItem>
                      <MenuItem value={"Widowed"}>Widowed</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col">
                  <FormControl
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 142,
                      flex: 1,
                      marginRight: 9,
                    }}
                  >
                    <InputLabel id="nationality-label">Nationality</InputLabel>
                    <Select
                      name="nationality"
                      labelId="nationality-label"
                      id="nationality"
                      defaultValue={addingEmployee.nationality || "Zambia"}
                      onChange={updateAddInput}
                      label="Nationality"
                    >
                      {!!countriesArr?.length &&
                        countriesArr.map((countryObj) => (
                          <MenuItem
                            key={countryObj.value}
                            value={countryObj.value}
                          >
                            {countryObj.label}{" "}
                            <ReactCountryFlag
                              style={{ marginLeft: 4 }}
                              countryCode={countryObj.id}
                              svg
                            />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col">
                  <FormControl
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 320,
                      maxWidth: 320,
                      flex: 1,
                      marginRight: 9,
                    }}
                  >
                    <InputLabel id="demo-multiple-name-label">
                      Languages Spoken
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      multiple={true}
                      defaultValue={addingEmployee.languagesList || []}
                      onChange={handleLanguagesChange}
                      input={<OutlinedInput label="Languages Spoken" />}
                      MenuProps={MenuProps}
                    >
                      {languageList.map((language) => (
                        <MenuItem key={language} value={language}>
                          {language}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col"></div>
              </div>

              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Spouse Information
                </Typography>
              </div>

              <div className="row" style={{ marginTop: "16px" }}>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    className="form-control "
                    label="First Name"
                    name="firstnamespouse"
                    defaultValue={addingEmployee.firstnamespouse}
                    onChange={updateAddInput}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    className="form-control "
                    label="Last Name"
                    name="lastnamespouse"
                    defaultValue={addingEmployee.lastnamespouse}
                    onChange={updateAddInput}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    className="form-control "
                    label="Address"
                    name="addressspouse"
                    defaultValue={addingEmployee.addressspouse}
                    onChange={updateAddInput}
                  />
                </div>
                <div className="col">
                  <NumberFormat
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    label="Phone Number"
                    className="form-control "
                    value={addingEmployee.numberspouse}
                    name="numberspouse"
                    onChange={updateAddInput}
                    customInput={TextField}
                    format="+260 ## ####-###"
                  />
                </div>
              </div>

              <div className="row" style={{ marginTop: "16px" }}>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    className="form-control "
                    label="Workplace"
                    name="workspouse"
                    defaultValue={addingEmployee.workspouse}
                    onChange={updateAddInput}
                  />
                </div>
                <div className="col"></div>
                <div className="col"></div>
                <div className="col"></div>
              </div>

              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Children Information
                </Typography>
              </div>

              <EditChildren
                onChildrenUpdate={handleChildrenChange}
                children={addingEmployee.children ?? []}
              />

              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Family Information
                </Typography>
              </div>

              <div className="row">
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Father First Name"
                    name="fatherfirstname"
                    defaultValue={addingEmployee.fatherfirstname}
                    onChange={updateAddInput}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Father Last Name"
                    name="fatherlastname"
                    defaultValue={addingEmployee.fatherlastname}
                    onChange={updateAddInput}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Mother First Name"
                    name="motherfirstname"
                    defaultValue={addingEmployee.motherfirstname}
                    onChange={updateAddInput}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Mother Last Name"
                    name="motherlastname"
                    defaultValue={addingEmployee.motherlastname}
                    onChange={updateAddInput}
                  />
                </div>
              </div>

              <div className="row" style={{ marginTop: "16px" }}>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Family Address"
                    name="familyaddress"
                    defaultValue={addingEmployee.familyaddress}
                    onChange={updateAddInput}
                  />
                </div>
                <div className="col">
                  <NumberFormat
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    label="Family Number"
                    className="form-control "
                    defaultValue={addingEmployee.familynumber}
                    name="familynumber"
                    onChange={updateAddInput}
                    customInput={TextField}
                    format="+260 ## ####-###"
                  />
                </div>
                <div className="col"></div>
                <div className="col"></div>
              </div>

              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Emergency Information
                </Typography>
              </div>

              <div className="row" style={{ marginTop: "16px" }}>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    className="form-control "
                    label="First Name"
                    name="emergencyfirstname"
                    defaultValue={addingEmployee.emergencyfirstname}
                    onChange={updateAddInput}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    className="form-control "
                    label="Last Name"
                    name="emergencylastname"
                    defaultValue={addingEmployee.emergencylastname}
                    onChange={updateAddInput}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Relationship"
                    name="emergencyrelationship"
                    defaultValue={addingEmployee.emergencyrelationship}
                    onChange={updateAddInput}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Address"
                    name="emergencyaddress"
                    defaultValue={addingEmployee.emergencyaddress}
                    onChange={updateAddInput}
                  />
                </div>
              </div>

              <div className="row" style={{ marginTop: "16px" }}>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Town"
                    name="emergencytown"
                    defaultValue={addingEmployee.emergencytown}
                    onChange={updateAddInput}
                  />
                </div>
                <div className="col">
                  <NumberFormat
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    label="Number"
                    className="form-control "
                    defaultValue={addingEmployee.emergencynumber}
                    name="emergencynumber"
                    onChange={updateAddInput}
                    customInput={TextField}
                    format="+260 ## ####-###"
                  />
                </div>
                <div className="col"></div>
                <div className="col"></div>
              </div>

              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Medical Information
                </Typography>
              </div>

              <div className="row" style={{ marginTop: "16px" }}>
                <div className="col">
                  <FormControl
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                  >
                    <FormLabel
                      id="health-radio-label"
                      style={{
                        fontSize: 10,
                      }}
                    >
                      Do you have any medical aliments that the organization
                      needs to be aware of or could hinder your ability to
                      complete your job?
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="health-radio-label"
                      name="medicalquestion"
                      defaultValue={addingEmployee.medicalquestion}
                      onChange={updateAddInput}
                    >
                      <FormControlLabel
                        style={{
                          fontSize: 14,
                        }}
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        style={{
                          fontSize: 14,
                        }}
                        value="No"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    className="form-control "
                    label="Comment"
                    name="medicalcomment"
                    defaultValue={addingEmployee.medicalcomment}
                    onChange={updateAddInput}
                  />
                </div>
              </div>
            </div>
          )}

          {activeStep === 1 && (
            <div>
              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Compensation & Benefits
                </Typography>
              </div>

              <div className="row" style={{ marginTop: "16px" }}>
                <div className="col">
                  <FormControl
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                  >
                    <InputLabel id="jobtitle-label">Job Title</InputLabel>
                    <Select
                      labelId="jobtitle-label"
                      id="title"
                      name="title"
                      defaultValue={addingEmployee.title}
                      onChange={updateAddInput}
                      label="Job Title"
                    >
                      <MenuItem value={"Admin"}>Admin</MenuItem>
                      <MenuItem value={"Teacher"}>Teacher</MenuItem>
                      <MenuItem value={"Other"}>Other</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col">
                  <FormControl
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                  >
                    <InputLabel id="reportsto-label">Reports to</InputLabel>
                    <Select
                      name="reportsto"
                      labelId="reportsto-label"
                      id="reportsto"
                      defaultValue={addingEmployee.reportsto}
                      onChange={updateInput}
                      label="Reports to"
                    >
                      <MenuItem value={"Executive Director"}>
                        Executive Director
                      </MenuItem>
                      <MenuItem value={"Academic Director"}>
                        Academic Director
                      </MenuItem>
                      <MenuItem value={"Head Teacher"}>Head Teacher</MenuItem>
                      <MenuItem value={"Deputy Head Teacher"}>
                        Deputy Head Teacher
                      </MenuItem>
                      <MenuItem value={"Section Head"}>Section Head</MenuItem>
                      <MenuItem value={"Administration Manager"}>
                        Administration Manager
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div className="col">
                  <FormControl
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                  >
                    <InputLabel id="jobtitle-label">Campus</InputLabel>
                    <Select
                      labelId="campus-label"
                      id="campus"
                      name="campus"
                      defaultValue={addingEmployee.campus}
                      onChange={updateAddInput}
                      label="Campus"
                    >
                      <MenuItem value={"Main Campus"}>Main Campus</MenuItem>
                      <MenuItem value={"Lubombo"}>Lubombo</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="number"
                    label="Length of Contract"
                    name="contractlength"
                    defaultValue={addingEmployee.contractlength}
                    onChange={updateAddInput}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputProps: { min: 0 },
                      endAdornment: (
                        <InputAdornment position="start">
                          {" "}
                          months
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>

              <div className="row" style={{ marginTop: "16px" }}>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="number"
                    label="Salary"
                    name="salary"
                    defaultValue={addingEmployee.salary}
                    onChange={updateAddInput}
                    required={true}
                    InputProps={{
                      inputProps: { min: 0 },
                      startAdornment: (
                        <InputAdornment position="start">K</InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="number"
                    label="Gratuity"
                    name="gratuity"
                    defaultValue={addingEmployee.gratuity}
                    onChange={updateAddInput}
                    InputProps={{
                      inputProps: { min: 0, max: 100 },
                      endAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    className="form-control "
                    label="Job Grade"
                    name="jobgrade"
                    defaultValue={addingEmployee.jobgrade}
                    onChange={updateAddInput}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Area"
                    name="area"
                    defaultValue={addingEmployee.area}
                    onChange={updateAddInput}
                  />
                </div>
              </div>

              <div className="row" style={{ marginTop: "16px" }}>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Bonus"
                    name="bonus"
                    defaultValue={addingEmployee.bonus}
                    onChange={updateAddInput}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Pension"
                    name="pension"
                    defaultValue={addingEmployee.pension}
                    onChange={updateAddInput}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="ZRA TPIN"
                    name="zratpin"
                    defaultValue={addingEmployee.zratpin}
                    onChange={updateAddInput}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="NAPSA No."
                    name="napsa"
                    defaultValue={addingEmployee.napsa}
                    onChange={updateAddInput}
                  />
                </div>
              </div>

              <div className="row" style={{ marginTop: "16px" }}>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Pension No. (SSN)"
                    name="pensionno"
                    defaultValue={addingEmployee.pensionno}
                    onChange={updateAddInput}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="NHIS ID"
                    name="nhisid"
                    defaultValue={addingEmployee.nhisid}
                    onChange={updateAddInput}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="date"
                    label="Date of Hire"
                    name="datehire"
                    defaultValue={addingEmployee.datehire}
                    onChange={updateAddInput}
                    required={true}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Bank Account No."
                    name="bankaccount"
                    defaultValue={addingEmployee.bankaccount}
                    onChange={updateAddInput}
                  />
                </div>
              </div>

              <div className="row" style={{ marginTop: "16px" }}>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Branch"
                    name="bankbranch"
                    defaultValue={addingEmployee.bankbranch}
                    onChange={updateAddInput}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="Bank Name"
                    name="bankname"
                    defaultValue={addingEmployee.bankname}
                    onChange={updateAddInput}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="date"
                    label="Original Date of Hire"
                    name="datehireoriginal"
                    defaultValue={addingEmployee.datehireoriginal}
                    onChange={updateAddInput}
                    required={true}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="TCZ No."
                    name="tcz"
                    defaultValue={addingEmployee.tcz}
                    onChange={updateInput}
                  />
                </div>
              </div>

              <div className="row" style={{ marginTop: "16px" }}>
                <div className="col">
                  <TextField
                    size="small"
                    style={{
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    type="text"
                    label="NHA ID"
                    name="nhaid"
                    defaultValue={addingEmployee.nhaid}
                    onChange={updateAddInput}
                  />
                </div>
                <div className="col">
                </div>
                <div className="col">
                </div>
                <div className="col">
                </div>
              </div>

              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Employee Status
                </Typography>
              </div>

              <EditStatus
                onStatusUpdate={handleStatusChange}
                status={addingEmployee.status ?? []}
              />

              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Allowances
                </Typography>
              </div>

              <EditAllowance
                onAllowanceUpdate={handleAllowanceChange}
                allowances={addingEmployee.allowances ?? []}
              />

              {/* <PermissionsGate scopes={[SCOPES.canDelete]}>
                <div className="row input-heading-row">
                  <Typography
                    style={{
                      paddingLeft: 0,
                      marginTop: 10,
                      fontSize: 16,
                    }}
                  >
                    Employee Contract
                  </Typography>
                </div>
                <EditContract
                  onContractUpdate={handleContractUpdate}
                  contract={addingEmployee.contractfile ?? ""}
                />
              </PermissionsGate> */}
            </div>
          )}

          {activeStep === 2 && (
            <div>
              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: 10,
                    fontSize: 16,
                  }}
                >
                  Leave Management
                </Typography>
              </div>

              <EditLeave
                onLeaveUpdate={handleLeaveChange}
                leaves={addingEmployee.leaves ?? []}
              />
            </div>
          )}

          {activeStep === 3 && (
            <div>
              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Performance Management
                </Typography>
              </div>

              <div className="row">
                <div className="col">
                  <TextField
                    className="multilineAField"
                    size="small"
                    style={{
                      maxWidth: 300,
                      marginTop: 5,
                      display: "flex",
                      minWidth: 100,
                      flex: 1,
                      marginRight: 9,
                    }}
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    type="text"
                    label="Performance Notes"
                    name="performance"
                    defaultValue={addingEmployee.performance}
                    onChange={updateAddInput}
                  />
                </div>
              </div>

              <div className="row input-heading-row">
                <Typography
                  style={{
                    paddingLeft: 0,
                    marginTop: "10px",
                    fontSize: 16,
                  }}
                >
                  Performance Documents
                </Typography>
              </div>
              <EditContract
                typeOfContract={"performance"}
                onContractUpdate={handlePerformanceDocumentUpdate}
                contract={addingEmployee.performancedocument ?? ""}
              />
            </div>
          )}
        </div>
      </form>
    );
  }
}

export default EmployeeList;

function EmployeeTable(
  navigate: NavigateFunction,
  clickDelete: Function,
  employees: Employee[],
  setEmployee: Dispatch<SetStateAction<Employee>>,
  loadingGrid: boolean
) {
  const cols: GridColDef[] = [
    // {
    //   field: "id",
    //   headerName: "ID",
    //   minWidth: 30,
    //   width: 30,
    //   flex: 1,
    //   renderHeader: (_params: GridColumnHeaderParams) => (
    //     <strong>{"# "}</strong>
    //   ),
    // },
    {
      field: "fullName",

      headerName: "Full name",
      width: 140,
      minWidth: 140,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"Full name"}</strong>
      ),
    },
    {
      field: "title",
      type: "singleSelect",
      valueOptions: Object.values(JobTitle),
      headerName: "Title",
      width: 60,
      maxWidth: 100,
      minWidth: 60,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"Title"}</strong>
      ),
    },
  ];

  const rows: any[] = employees?.map((employee) => ({
    id: employee.id,
    fullName: employee.firstname + " " + employee.lastname,
    title: employee.title,
    salary: "K" + (employee.salary || 0),
    dateOfHire: employee.datehire,
    employee: employee,
  }));

  const onRowClickEvent: GridEventListener<"rowClick"> = (
    params: GridRowParams, // GridRowParams
    _event: MuiEvent<React.MouseEvent<HTMLElement>>,
    _details: GridCallbackDetails
  ) => {
    setEmployee(params.row.employee);
  };
  const dataTable = (
    <div
      style={{
        height: 615,
        width: "100%",
        backgroundColor: "white",
        fontSize: 3,
      }}
    >
      <DataGrid
        headerHeight={40}
        rowHeight={40}
        rows={rows}
        columns={cols}
        pageSize={13}
        rowsPerPageOptions={[13]}
        checkboxSelection={false}
        onRowClick={onRowClickEvent}
        loading={loadingGrid}
      />
    </div>
  );
  return dataTable;
}

function SearchBar(
  setSearch: React.Dispatch<SetStateAction<string>>,
  search: string
) {
  return (
    <form>
      <div style={{ fontSize: 3, height: 50 }}>
        <TextField
          className="searchbarbackground regularTextField"
          id="outlined-basic"
          variant="outlined"
          fullWidth
          label="Search"
          defaultValue={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
    </form>
  );
}

function EmployeeFilter(
  handleClickListItem: MouseEventHandler<HTMLButtonElement>,
  selectedFilterIndex: number,
  anchorEl: HTMLElement | null,
  open: boolean,
  handleCloseFilterMenu:
    | ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void)
    | undefined,
  handleMenuItemClick: (
    arg0: React.MouseEvent<HTMLLIElement, MouseEvent>,
    arg1: number
  ) => void
) {
  return (
    <>
      <List component="nav" disablePadding>
        <Button
          id="filter-button"
          variant="outlined"
          color="primary"
          size="small"
          onClick={handleClickListItem}
        >
          <ListItemText
            primary="Viewing"
            primaryTypographyProps={{ fontSize: 8 }}
            secondary={options[selectedFilterIndex]}
            secondaryTypographyProps={{ fontSize: 10 }}
          />
        </Button>
      </List>
      <Menu
        id="filter-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseFilterMenu}
        MenuListProps={{
          role: "listbox",
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            style={{
              justifyContent: "center",
              alignItems: "center",
              fontSize: 14,
            }}
            key={option}
            selected={index === selectedFilterIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

function BulkEmployeeTable(
  selectEmployeeFromBulkEdit: Function,
  setBulkList: Function,
  _clickDelete: Function,
  employees: Employee[],
  isViewingTable: boolean,
  loadingGrid: boolean,
  userName
) {
  function UpdateObject(e) {
    Object.keys(employees).forEach((el) => {
      if (employees[el].id === e.id) {
        employees[el][`${[e.field]}`] = e.value;
        employees[el].updatedAt = new Date(Date.now()).toISOString();
        employees[el].updatedBy = userName;
      }
    });
    setBulkList(employees);
  }

  const cols: GridColDef[] = [
    {
      field: "firstname",
      headerName: "First Name",
      width: 100,
      minWidth: 100,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"First Name"}</strong>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <div
          style={{
            backgroundColor: "#e8e8e8",
            width: "200%",
            height: "100%",
            paddingTop: 11.5,
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "lastname",
      headerName: "Last Name",
      width: 100,
      minWidth: 100,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"Last Name"}</strong>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <div
          style={{
            backgroundColor: "#e8e8e8",
            width: "200%",
            height: "100%",
            paddingTop: 11.5,
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "address",
      headerName: "Address",
      width: 120,
      minWidth: 120,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"Address"}</strong>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <div
          style={{
            backgroundColor: "#e8e8e8",
            width: "200%",
            height: "100%",
            paddingTop: 11.5,
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "number",
      headerName: "Phone Number",
      width: 120,
      minWidth: 120,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"Phone Number"}</strong>
      ),
      editable: !isViewingTable,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {isViewingTable ? (
            <>
              <div
                style={{
                  backgroundColor: "#e8e8e8",
                  width: "100%",
                  height: "100%",
                  paddingTop: 11.5,
                }}
              >
                {params.value}
              </div>
            </>
          ) : (
            <div
              style={{
                marginLeft: 3,
              }}
            >
              {params.value}{" "}
            </div>
          )}
        </>
      ),
    },
    {
      field: "dob",
      headerName: "Date of Birth",
      type: "date",
      width: 100,
      maxWidth: 100,
      minWidth: 100,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"Date of Birth"}</strong>
      ),
      editable: !isViewingTable,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {isViewingTable ? (
            <>
              <div
                style={{
                  backgroundColor: "#e8e8e8",
                  width: "100%",
                  height: "100%",
                  paddingTop: 11.5,
                }}
              >
                {params.value}
              </div>
            </>
          ) : (
            <div
              style={{
                marginLeft: 3,
              }}
            >
              {params.value}{" "}
            </div>
          )}
        </>
      ),
    },
    {
      field: "salary",
      headerName: "Salary",
      width: 90,
      minWidth: 90,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"Salary"}</strong>
      ),
      renderCell: (params: GridRenderCellParams) => <div>K{params.value}</div>,
      editable: !isViewingTable,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {isViewingTable ? (
            <>
              <div
                style={{
                  backgroundColor: "#e8e8e8",
                  width: "100%",
                  height: "100%",
                  paddingTop: 11.5,
                }}
              >
                {params.value}
              </div>
            </>
          ) : (
            <div
              style={{
                marginLeft: 3,
              }}
            >
              {params.value}{" "}
            </div>
          )}
        </>
      ),
    },
    {
      field: "gratuity",
      headerName: "Gratuity",
      width: 120,
      minWidth: 120,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"Gratuity"}</strong>
      ),
      editable: !isViewingTable,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {isViewingTable ? (
            <>
              <div
                style={{
                  backgroundColor: "#e8e8e8",
                  width: "100%",
                  height: "100%",
                  paddingTop: 11.5,
                }}
              >
                {params.value}
              </div>
            </>
          ) : (
            <div
              style={{
                marginLeft: 3,
              }}
            >
              {params.value}{" "}
            </div>
          )}
        </>
      ),
    },
    {
      field: "yearsofservice",
      // filterable: false,
      sortable: false,
      headerName: "Years of Service",
      width: 100,
      minWidth: 100,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"Years of Service"}</strong>
      ),
      editable: !isViewingTable,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <div
            style={{
              backgroundColor: "#e8e8e8",
              width: "100%",
              height: "100%",
              paddingTop: 11.5,
            }}
          >
            {params.value}
          </div>
        </>
      ),
    },
    {
      field: "title",
      headerName: "Job Title",
      type: "singleSelect",
      valueOptions: Object.values(JobTitle),
      width: 140,
      minWidth: 140,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"Job Title"}</strong>
      ),
      editable: !isViewingTable,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {isViewingTable ? (
            <>
              <div
                style={{
                  backgroundColor: "#e8e8e8",
                  width: "100%",
                  height: "100%",
                  paddingTop: 11.5,
                }}
              >
                {params.value}
              </div>
            </>
          ) : (
            <div
              style={{
                marginLeft: 3,
              }}
            >
              {params.value}{" "}
            </div>
          )}
        </>
      ),
    },
    {
      field: "nrc",
      headerName: "NRC",
      width: 130,
      minWidth: 130,
      flex: 1,
      renderHeader: (_params: GridColumnHeaderParams) => (
        <strong>{"NRC"}</strong>
      ),
      editable: !isViewingTable,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {isViewingTable ? (
            <>
              <div
                style={{
                  backgroundColor: "#e8e8e8",
                  width: "100%",
                  height: "100%",
                  paddingTop: 11.5,
                }}
              >
                {params.value}
              </div>
            </>
          ) : (
            <div
              style={{
                marginLeft: 3,
              }}
            >
              {params.value}{" "}
            </div>
          )}
        </>
      ),
    },
  ];
  // 54c9a70c-7804-4bcd-b246-38a831290564

  function calculateYearsOfService(s: Employee) {
    let today = new Date();
    let hireDate = new Date(s.datehire);
    let months;
    months = (today.getFullYear() - hireDate.getFullYear()) * 12;
    months -= hireDate.getMonth();
    months += today.getMonth();
    months = months <= 0 ? 0 : months;
    let years = months / 12;
    let remainder = months % 12;
    if (!years) {
      years = 0;
    }
    let result = "";

    if (years === 1) {
      result = years.toFixed(0) + " yr.";
    } else if (years > 1) {
      result = years.toFixed(0) + " yrs.";
    }
    if (remainder != 0) {
      if (remainder === 1) {
        result += " " + remainder.toFixed(0) + " mo.";
      } else if (remainder > 1) {
        result += " " + remainder.toFixed(0) + " mos.";
      }
    }
    return result;
  }

  const rows: any[] = employees.map((s) => ({
    id: s.id,
    firstname: s.firstname,
    lastname: s.lastname,
    number: s.number,
    email: s.email,
    address: s.address,
    town: s.town,
    province: s.province,
    salary: parseInt(s.salary) || 0,
    gratuity: s.gratuity,
    yearsofservice: calculateYearsOfService(s) || 0,
    title: s.title,
    dob: s.dob,
    nrc: s.nrc,
  }));

  const onRowClickEvent: GridEventListener<"rowClick"> = (
    params: GridRowParams, // GridRowParams
    _event: MuiEvent<React.MouseEvent<HTMLElement>>,
    _details: GridCallbackDetails
  ) => {
    if (isViewingTable) {
      let index = employees.findIndex((object) => {
        return object.id === params.row.id;
      });
      selectEmployeeFromBulkEdit(index);
    }
  };
  const dataTable = (
    <div
      style={{
        height: 615,
        width: "100%",
        backgroundColor: "white",
        fontSize: 3,
      }}
    >
      <DataGrid
        // apiRef={apiRef}
        headerHeight={40}
        rowHeight={40}
        rows={rows}
        columns={cols}
        pageSize={13}
        rowsPerPageOptions={[13]}
        checkboxSelection={false}
        onRowClick={onRowClickEvent}
        onCellEditCommit={UpdateObject}
        loading={loadingGrid}
      />
    </div>
  );
  return dataTable;
}

function AddEmployeeButton(
  addEmployeeClicked: MouseEventHandler<HTMLButtonElement>
) {
  return (
    <Button
      startIcon={<PersonAddIcon />}
      variant="contained"
      color="success"
      size="small"
      onClick={addEmployeeClicked}
    >
      Add Employee
    </Button>
  );
}
